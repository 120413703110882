import { action } from "mobx";

import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListAdminResponse,
  ListClinicManagerResponse,
  ListSuperadminResponse,
  ListReceptionistResponse,
  ListDoctorResponse,
  ListOwnerDoctorResponse,
  MeService,
} from "services/me-service";

export const actionGetMyData = action(
  async (): Promise<
    | ListSuperadminResponse
    | ListClinicManagerResponse
    | ListAdminResponse
    | ListDoctorResponse
    | ListOwnerDoctorResponse
    | ListReceptionistResponse
  > => {
    SetIsLoadingState(true);
    try {
      return await MeService.getMyData();
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
