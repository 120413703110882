import { Button, Row } from "reactstrap";
import { observer } from "mobx-react";
import { useState } from "react";

import { AnamnesisForm } from "./anamnesis/anamnesis-form";
import { CustomTab } from "components/tab/custom-tab";
import { AttachmentsForm } from "./attachments/attachments-form";
import { ProcedureForm } from "./procedure/procedure-form";
import { ComplementaryExamsForm } from "./complementary-exams/complementary-exams-form";
import medicalRecordAnamnesisStore from "state/medical-record/medical-record-anamnesis-store";
import medicalRecordAttachmentsStore from "state/medical-record/medical-record-attachments-store";
import medicalRecordComplementaryExamsStore from "state/medical-record/medical-record-complementary-exams-store";
import medicalRecordProceduresStore from "state/medical-record/medical-record-procedures-store";
import { actionCreateMedicalRecord } from "actions/medical-record/action-create-medical-record";
import { CreateMedicalRecordProps } from "services/medical-record-service";
import { MedicalRecordHistory } from "./medical-record-history/medical-record-history";
import { ModalFinishAppointment } from "./modal-finish-appointment";

interface StartAppointmentProps {
  patient: {
    name: string;
    id: number;
  };
  onSubmit: () => void;
  onCancelAppointment: () => void;
}

export const StartAppointment: React.FC<StartAppointmentProps> = observer(
  ({ onCancelAppointment, patient: { name, id } }) => {
    const [activeTab, setActiveTab] = useState("1");
    const [toggle, setToggle] = useState(false);

    const onConfirmationAppointment = () => {
      setToggle(true);
    };

    const onSubmit = async (estimatedReturnDate: Date | null) => {
      const data: Record<string, any> = { patient_id: id };

      if (!medicalRecordAnamnesisStore.isEmpty()) {
        data["anamnesis"] = medicalRecordAnamnesisStore.getFormData();
      }
      if (!medicalRecordComplementaryExamsStore.isEmpty()) {
        data["exams"] = medicalRecordComplementaryExamsStore.getFormData();
      }
      if (!medicalRecordProceduresStore.isEmpty()) {
        data["procedures"] = medicalRecordProceduresStore.getFormData();
      }
      if (!medicalRecordAttachmentsStore.isEmpty()) {
        data["attachments"] =
          medicalRecordAttachmentsStore.getFormData().attachments;
      }
      data["estimated_return_date"] = estimatedReturnDate;

      try {
        await actionCreateMedicalRecord(data as CreateMedicalRecordProps);

        medicalRecordAnamnesisStore.cleanData();
        medicalRecordComplementaryExamsStore.cleanData();
        medicalRecordProceduresStore.cleanData();
        medicalRecordAttachmentsStore.cleanData();
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    };

    const [tabs, setTabs] = useState([
      {
        label: "Histórico de atendimento",
        disabled: false,
        element: (
          <div className="mt-4">
            <MedicalRecordHistory id={id} />
          </div>
        ),
      },
      {
        label: "Anamnese",
        disabled: false,
        element: (
          <div className="mt-4">
            <AnamnesisForm />
          </div>
        ),
      },
      {
        label: "Exames Complementares",
        disabled: false,
        element: (
          <div className="mt-4">
            <ComplementaryExamsForm />
          </div>
        ),
      },
      {
        label: "Procedimentos",
        disabled: false,
        element: (
          <div className="mt-4">
            <ProcedureForm />
          </div>
        ),
      },
      {
        label: "Anexos",
        disabled: false,
        element: (
          <div className="mt-4">
            <AttachmentsForm />
          </div>
        ),
      },
    ]);
    return (
      <>
        <div className="wrapper-start-appointment">
          <Row>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="col-12 col-md-6 ">
                <h4>Iniciar atendimento</h4>
              </div>
              <div className="col-12 col-md-6 text-end d-flex flex-wrap justify-content-between">
                <div className="col-12 col-md-5">
                  <Button
                    color="secondary"
                    className="font-weight-500 form-control"
                    onClick={onCancelAppointment}
                  >
                    Cancelar
                  </Button>
                </div>

                <div className="col-12 col-md-6">
                  <Button
                    color="danger"
                    className="font-weight-500 form-control"
                    onClick={onConfirmationAppointment}
                  >
                    Finalizar Atendimento
                  </Button>
                </div>
              </div>
            </div>
          </Row>
          <CustomTab
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>

        <ModalFinishAppointment
          isOpen={toggle}
          toggle={() => setToggle((prev) => !prev)}
          onSubmit={onSubmit}
        />
      </>
    );
  }
);
