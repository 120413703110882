import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import {
  EletronicPrescriptionService,
  StartPrescriptionRequest,
  StartPrescriptionResponse,
} from "services/eletronic-prescription-service";

export const actionStartPrescription = action(
  async (
    input: StartPrescriptionRequest
  ): Promise<StartPrescriptionResponse> => {
    SetIsLoadingState(true);
    try {
      return await EletronicPrescriptionService.startPrescription(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
