interface PrescriptionBadgeComponentProps {
  data: {
    name: string;
    bgColor: string;
  };
}
export const PrescriptionBadgeComponent: React.FC<
  PrescriptionBadgeComponentProps
> = ({ data }: PrescriptionBadgeComponentProps) => {
  const { name, bgColor } = data;
  return (
    <>
      <span className={`badge py-2 fs-10 ${bgColor}`}>{name}</span>
    </>
  );
};
