import "react-datepicker/dist/react-datepicker.css";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Button, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { actionFetchAddress } from "actions/address/action-fetch-address";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { getCityByUf } from "helpers/cities-list";
import { successMessage } from "helpers/toast";
import { actionUpdateTenantData } from "actions/tenant/action-update-tenant-data";
import { SelectUfForm } from "components/form/input/select-uf-form";

export interface FormTenantDataProps {
  data: {
    name: string;
    logo: string;
    phone: string;
    email: string;
    zip_code: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    state: string;
    city: string;
  };
}

interface StateFormValue {
  value: string;
  label: string;
}

export interface DataFormValues {
  name: string;
  // logo: string;
  phone: string;
  email: string;
  zip_code: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  state: StateFormValue | string;
  city: string;
}

export const FormTenantData: React.FC<FormTenantDataProps> = ({
  data,
}: FormTenantDataProps) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    phone: Yup.string().required(),
    email: Yup.string().email("E-mail inválido").required("E-mail obrigatório"),
    zip_code: Yup.string().test("zip_code", "CEP inválido", (value) => {
      return value?.replace(/\D/g, "").length === 8;
    }),
    street: Yup.string().required("Logradouro é obrigatório"),
    state: Yup.object().test(
      "custom-validation-uf",
      "Estado inválido",
      function (value: any) {
        return value?.value !== null && value?.value !== undefined;
      }
    ),
    city: Yup.string().required("Cidade é obrigatório"),
  });

  const initialValues: DataFormValues = {
    name: data.name,
    phone: data.phone,
    email: data.email,
    zip_code: data.zip_code,
    street: data.street,
    number: data.number,
    complement: data.complement,
    neighborhood: data.neighborhood,
    state: data.state ? getCityByUf(data.state) ?? "" : "",
    city: data.city,
  };

  const handleSubmit = async (values: DataFormValues) => {
    const input = {
      name: values.name,
      phone: values.phone,
      email: values.email,
      address: {
        zip_code: values.zip_code,
        street: values.street,
        number: values.number,
        complement: values.complement,
        neighborhood: values.neighborhood,
        state: values.state ? (values.state as any)?.value : "",
        city: values.city,
      },
    };
    try {
      await actionUpdateTenantData(input);
      successMessage("Dados atualizados com sucesso");
    } catch {}
  };

  const ZipCodeForm: React.FC = () => {
    const { setFieldValue } = useFormikContext();

    const handleZipCodeChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const zipCode = e.target.value.replace(/\D/g, "");

      setFieldValue("zip_code", zipCode);

      if (zipCode.length === 8) {
        try {
          const result = await actionFetchAddress(zipCode);
          const state = getCityByUf(result.state);

          setFieldValue("street", result.street);
          setFieldValue("neighborhood", result.neighborhood);
          setFieldValue("city", result.city);
          setFieldValue("state", state);
        } catch {}
      }
    };

    return (
      <Field
        id="zip_code"
        name="zip_code"
        type="text"
        placeholder="00000-000"
        mask="00000-000"
        onChange={handleZipCodeChange}
        component={CustomMaskInput}
      />
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                <Label htmlFor="name">Nome da clínica*</Label>
                <Field
                  name="name"
                  id="name"
                  placeholder="Nome "
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 px-0 ps-md-2 pe-md-2">
                <Label htmlFor="phone">Telefone*</Label>
                <Field
                  name="phone"
                  id="phone"
                  placeholder="(DD) 0000-0000"
                  type="text"
                  mask="(00) 00000-0000"
                  component={CustomMaskInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 px-0 ps-md-2">
                <Label htmlFor="email">Email da clínica*</Label>
                <Field
                  name="email"
                  id="email"
                  placeholder="E-mail"
                  type="email"
                  component={CustomFormInput}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-3 px-0 pe-md-2">
                <Label htmlFor="name">CEP</Label>
                <ZipCodeForm />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 px-0 ps-md-2 pe-md-2">
                <Label htmlFor="street">Logradouro</Label>
                <Field
                  name="street"
                  id="street"
                  placeholder="Endereço do consultório"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 px-0 ps-md-2 ">
                <Label htmlFor="complement">Complemento</Label>
                <Field
                  name="complement"
                  id="complement"
                  placeholder="Complemento"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-3 px-0 pe-md-2">
                <Label htmlFor="number">Número</Label>
                <Field
                  name="number"
                  id="number"
                  placeholder="Número"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 px-0 ps-md-2 pe-md-2">
                <Label htmlFor="neighborhood">Bairro</Label>
                <Field
                  name="neighborhood"
                  id="neighborhood"
                  placeholder="Endereço do consultório"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 px-0 ps-md-2 pe-md-2">
                <SelectUfForm keyName="state" />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 px-0 ps-md-2">
                <Label htmlFor="city">Cidade</Label>
                <Field
                  name="city"
                  id="city"
                  placeholder="Endereço do consultório"
                  type="text"
                  component={CustomFormInput}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <div className="col-12 col-md-3 px-0 ps-md-2">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
