// formStore.ts
import { makeAutoObservable } from "mobx";

interface FormData {
  main_complaint: string;
  current_illness_history: string;
  medical_history: string;
  physical_examination: string;
  diagnosis: string;
  treatment_plan: string;
}

class MedicalRecordAnamnesisStore {
  formData: FormData = {
    main_complaint: "",
    current_illness_history: "",
    medical_history: "",
    physical_examination: "",
    diagnosis: "",
    treatment_plan: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  setFormData(field: keyof FormData, value: string) {
    this.formData[field] = value;
  }

  getFormData(): FormData {
    return this.formData;
  }

  isEmpty(): boolean {
    return Object.values(this.formData).every((value) => value === "");
  }

  cleanData(): void {
    this.formData = {
      main_complaint: "",
      current_illness_history: "",
      medical_history: "",
      physical_examination: "",
      diagnosis: "",
      treatment_plan: "",
    };
  }
}

const medicalRecordAnamnesisStore = new MedicalRecordAnamnesisStore();
export default medicalRecordAnamnesisStore;
