import * as Yup from "yup";
import React from "react";
import { Button, Row, Col, FormGroup, Label } from "reactstrap";
import { Formik, Form, Field } from "formik";

import { TokenTypeEnum } from "domain/auth/entities/enums/token-type.enum";
import { actionLoginUser } from "actions/identity/action-login-user";
import { CustomPasswordInput } from "components/form/input/custom-password-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { validateCPF } from "validators/validate-cpf";

import "./login.style.css";

interface LoginFormValues {
  login: string;
  password: string;
}
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Senha deve ter no mínimo 6 caracteres")
    .required("Senha é obrigatória"),
  login: Yup.string().test(
    "custom-validation",
    "CPF inválido",
    function (value) {
      return validateCPF(value ?? "");
    }
  ),
});

const Login: React.FC = () => {
  const initialValues: LoginFormValues = {
    login: "",
    password: "",
  };

  const onSubmit = async (values: LoginFormValues) => {
    const tokenType = await actionLoginUser({
      ...values,
      login: values.login.replace(/\D/g, ""),
    });

    if (tokenType == TokenTypeEnum.PERMANENT) {
      window.location.href = "/painel";
      return;
    } else if (tokenType == TokenTypeEnum.TEMPORARY) {
      window.location.href = "/auth/aplicacoes";
      return;
    }
  };

  return (
    <>
      <Row className="d-flex px-0 col-12 col-md-7 d-flex align-items-center row flex-column">
        <img
          className="mx-auto mb-5 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          md="8"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <h3 className="text-center text-primary">Login</h3>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {() => (
              <Form className="px-0">
                <FormGroup className="col-12 px-0">
                  <Label htmlFor="login">CPF</Label>
                  <Field
                    name="login"
                    id="login"
                    placeholder="Digite seu CPF"
                    type="text"
                    mask="000.000.000-00"
                    leftIconClass="fas fa-user text-primary"
                    component={CustomMaskInput}
                  />
                </FormGroup>

                <FormGroup className="col-12">
                  <Label htmlFor="password">Senha</Label>
                  <Field
                    name="password"
                    id="password"
                    label="Senha"
                    placeholder="Digite sua senha"
                    leftIconClass="fas fa-lock text-primary"
                    component={CustomPasswordInput}
                  />
                </FormGroup>

                <Row className="text-center fs-15">
                  <Col className="d-flex justify-content-end">
                    <a href="/auth/esqueci-senha" className="text-primary">
                      <i className="fas fa-user-doctor"></i> Esqueceu a senha?
                    </a>
                  </Col>
                </Row>
                <Button
                  className="my-3 form-control font-weight-600 h-3"
                  color="primary"
                  type="submit"
                >
                  Entrar
                </Button>
              </Form>
            )}
          </Formik>
          {/* <Button
            className="my-3 form-control h-3"
            color="secondary"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="18"
              height="18"
              viewBox="0 0 48 48"
            >
              <path
                fill="#FFC107"
                d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
              <path
                fill="#FF3D00"
                d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
              ></path>
              <path
                fill="#4CAF50"
                d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
              ></path>
              <path
                fill="#1976D2"
                d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
              ></path>
            </svg>
            &nbsp;&nbsp;Entrar com o Google
          </Button> */}

          <Row className="mt-4 text-center fw-400">
            <Col className="d-flex justify-content-center">
              <a href="/auth/cadastrar" className="text-primary">
                <i className="fas fa-user-doctor"></i> Cadastrar conta?
              </a>
            </Col>
          </Row>

          <hr className="my-4" />

          <Row className="text-center fs-15">
            <strong className="text-center">
              Tem alguma dúvida ou sugestão?
            </strong>
            <span>
              <i className="fas fa-envelope"></i> contato@g3med.com.br
            </span>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Login;
