import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { enumIterator } from "helpers/typescript/enum-iterator";

import {
  UserStatusEnum,
  UserStatusEnumLabels,
} from "domain/user/entities/enums/user-status.enum";
import { customStyles } from "./helpers/react-select-styles";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectUserStatusFormProps {
  keyName: string;
  isReadOnly?: boolean;
}

export const SelectUserStatusForm: React.FC<SelectUserStatusFormProps> = ({
  keyName,
  isReadOnly,
}: SelectUserStatusFormProps) => {
  const { setFieldValue, values, errors} = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue(keyName, undefined);
    setFieldValue(keyName, selectedOption);
  };

  const optionsUserStatus = enumIterator(UserStatusEnum).map((key) => ({
    value: UserStatusEnum[key],
    label: UserStatusEnumLabels(
      UserStatusEnum[key] as unknown as UserStatusEnum
    ),
  }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor={keyName}>Status</Label>
      <Field
        name={keyName}
        id={keyName}
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.[keyName]}
        placeholder="Status"
        options={optionsUserStatus}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
        styles={customStyles}
      />
      {(errors as any)?.[keyName] && (
        <div className="custom-invalid-feedback">
          Status inválido
        </div>
      )}
    </FormGroup>
  );
};
