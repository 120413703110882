import React from "react";
import { Row, Col } from "reactstrap";

const Term: React.FC = () => {
  return (
    <>
      <Row className="d-flex px-0 col-12  d-flex align-items-center row flex-column">
        <img
          className="mx-auto mb-5 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          md="10"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <div>
            <div>
              <h2 className="text-primary text-center mb-5">
                Termo e condições de uso
              </h2>
              <p>
                <strong>CLÁUSULA 1ª – DAS DEFINIÇÕES</strong>
              </p>
              <p>
                1.1 Para exata compreensão e interpretação dos direitos e
                obrigações previstos no presente Termo, serão adotadas as
                seguintes definições em adição às definições do Termo Geral:
              </p>
              <ul>
                <li>
                  <strong>G3med</strong>: Plataforma de Gestão Clínica;
                </li>
                <li>
                  <strong>G3med</strong>: Plataforma de serviços de Prescrição
                  Eletrônica;
                </li>
                <li>
                  Dados do Paciente: Todos os dados pessoais do Paciente, sejam
                  sensíveis ou não, incluindo, mas sem limitação, nome, cpf,
                  endereço, dado de saúde, prescrição de medicamentos, foto;
                </li>
                <li>
                  TELECONSULTA: modalidade de consulta médica realizada à
                  distância, com o auxílio da tecnologia da{" "}
                  <strong>G3med</strong>;
                </li>
                <li>
                  Controlador de Dados: significa a pessoa física ou jurídica ou
                  entidade que determina os objetivos e meios do processamento
                  de Dados Pessoais;
                </li>
                <li>
                  Operador de Dados: Significa uma entidade que processa os
                  dados pessoais de acordo com as instruções do Controlador de
                  Dados;
                </li>
                <li>
                  Sub Operador de Dados: Significa uma entidade envolvida por um
                  Processador que concorda em receber do processador de dados
                  pessoais que se destinam exclusivamente a atividade de
                  processamento a serem feitas como parte dos Serviços;
                </li>
                <li>
                  USUÁRIO: toda pessoa que acessar ou interagir com a{" "}
                  <strong>G3med</strong> ou alguma de suas funcionalidades;
                </li>
                <li>
                  PRESCRIÇÃO DE MEDICAMENTOS: Ato de cuidados com a saúde,
                  implementadas por médico ou outro profissional de saúde;
                </li>
                <li>
                  PRONTUÁRIO: é a soma de informações registradas a respeito de
                  um paciente.
                </li>
              </ul>
              <p>
                <strong>CLÁUSULA 2ª – DO OBJETO</strong>
              </p>
              <p>
                2.1. Este Termo é aplicável aos USUÁRIOS que utilizarem ou
                acessarem os serviços disponibilizados pela PLATAFORMA{" "}
                <strong>G3med</strong>, serviço esse que tem por objetivo
                facilitar a interação entre os profissionais da área da saúde e
                seus pacientes por meio da realização de consultas à distância,
                em ambiente seguro virtual (online) provido pela{" "}
                <strong>G3med</strong>, bem como gerir os prontuários de seus
                pacientes e realizar a prescrição medicamentosa destes.
              </p>
              <p>
                2.2. O ambiente virtual oferecido pela <strong>G3med</strong> é
                completo para o profissional de saúde, integrando serviços de
                teleconsulta, prontuários eletrônicos, prescrição medicamentosa
                e comparativo de medicamentos.
              </p>
              <p>
                2.3. A <strong>G3med</strong> oferece diferentes funcionalidades
                para cada um desses serviços de forma a criar uma cadeia
                integrada e harmônica entre o Médico e os seus pacientes, do
                momento do cadastro até a prescrição de medicamentos.
              </p>
              <p>
                <strong>
                  CLÁUSULA 3ª – DO AGENDAMENTO E UTILIZAÇÃO DO SERVIÇO DE
                  TELECONSULTA
                </strong>
              </p>
              <p>
                3.1. O USUÁRIO (paciente) poderá agendar uma TELECONSULTA: (i)
                presencialmente no consultório do profissional de saúde, (ii)
                por meio de contato com o profissional ou pessoa que o
                assessora, ou (iii) online, na seção de Agendamento
                disponibilizada na PLATAFORMA <strong>G3med</strong>. Neste
                último caso, ao clicar no horário para agendar a consulta, o
                USUÁRIO deverá indicar que se trata de TELECONSULTA, e não
                consulta presencial.
              </p>
              <p>
                3.2. Durante a TELECONSUNTA, a <strong>G3med</strong>{" "}
                disponibiliza um chat para conversas por texto entre o USUÁRIO
                paciente e o USUÁRIO profissional da saúde, e uma aba para
                Anotações para este último, que poderão, ao final da
                TELECONSULTA, ser anexadas ao prontuário do paciente junto com o
                vídeo da TELECONSULTA. A conversa do chat não será gravada nem
                armazenada.
              </p>
              <p>
                3.3. Antes de encerrar a TELECONSULTA, a PLATAFORMA{" "}
                <strong>G3med</strong> pergunta ao profissional da saúde se
                deseja enviar orientações ao paciente e, caso seja feita essa
                opção, as orientações serão enviadas tanto para o email do
                paciente como do profissional da saúde, e a TELECONSULTA será
                encerrada e automaticamente anexada ao prontuário do respectivo
                paciente.
              </p>
              <p>
                3.4. O profissional de saúde poderá enviar receita com
                assinatura digital por meio de SMS ao seu paciente, quando o
                serviço estiver disponível na PLATAFORMA <strong>G3med</strong>.
              </p>
              <p>
                <strong>CLÁUSULA 4ª – DA GESTÃO DE ATIVIDADES DA G3med</strong>
              </p>
              <p>
                4.1. O USUÁRIO (MÉDICO e PROFISSIONAL DE SAÚDE) poderá
                gerenciar:&nbsp;
                <em>
                  (i) sua agenda e suas funcionalidades, (ii) finanças, ou (iii)
                  prontuários dos pacientes seus e da clínica ao qual estiver
                  atrelado; (v) marketing; (iv) Prescrição de Medicamentos.
                </em>
              </p>
              <p>
                4.2. As funcionalidades e serviços estarão dispostos de acordo
                com o plano selecionado pelo Médico e profissional de saúde e
                estão disponíveis nos tipos de Planos e Assinatura da{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                <strong>CLÁUSULA 5ª – DA PRESCRIÇÃO ELETRÔNICA</strong>
              </p>
              <p>
                5.1. O USUÁRIO poderá utilizar a ferramenta de prescrição
                eletrônica ofertada pela <strong>G3med</strong>, através de seu
                produto <strong>G3med</strong>, onde poderá gerar e acompanhar
                as prescrições médicas, através de meio eletrônico nos termos da
                Lei vigente.
              </p>
              <p>
                5.2. O USUÁRIO poderá enviar para o seu paciente através de App
                de comunicação a prescrição para o seu paciente, bem como também
                poderá imprimir a prescrição.
              </p>
              <p>
                5.3. A funcionalidade de prescrição medicamentosa atende a todas
                as disposições legais, não havendo qualquer tipo de ação ilegal
                ou de interpretação duvidosa das leis que regulamentam a ação.
              </p>
              <p>
                5.4. A <strong>G3med</strong> poderá desenvolver uma ferramenta
                de impulsionamento da ordem do catálogo, a seu exclusivo
                critério e/ou de parceiros que lhe convierem no Banco de
                Industrializados. Neste caso, se o Usuário não estiver de acordo
                com as novas regras de exibição de medicamentos,{" "}
                <strong>G3med</strong> indica que o USUÁRIO utilize do Texto
                Livre, os quais são livres de qualquer impulsionamento.
              </p>
              <p>
                <strong>CLÁUSULA 6ª – DO PAGAMENTO</strong>
              </p>
              <p>
                6.1. Os preços dos serviços oferecidos pela{" "}
                <strong>G3med</strong>, bem como demais condições comerciais,
                poderão ser consultados na PLATAFORMA <strong>G3med</strong>, de
                acordo com cada agendamento.
              </p>
              <p>
                6.2. A <strong>G3med</strong> reserva a si o direito de alterar
                em qualquer momento os valores praticados, devendo a referida
                alteração ser previamente comunicada ao USUÁRIO.
              </p>
              <p>
                6.3. Em caso de não pagamento por parte do USUÁRIO (médico ou
                profissional de saúde) de quaisquer valores devidos pelo USUÁRIO
                em razão dos serviços contratados na PLATAFORMA{" "}
                <strong>G3med</strong>, a <strong>G3med</strong> poderá bloquear
                ou suspender total ou parcialmente as respectivas
                funcionalidades da PLATAFORMA<strong>G3med</strong> até a
                regularização dos pagamentos.
              </p>
              <p>
                <strong>
                  CLÁUSULA 7ª – DAS MODIFICAÇÕES NO PRESENTE TERMO
                </strong>
              </p>
              <p>
                7.1. O presente Termo está sujeito a constantes melhorias e
                aprimoramentos. Assim, a <strong>G3med</strong> reserva a si o
                direito de modificá-los a qualquer tempo, conforme sua
                finalidade ou conveniência, tal qual para adequação e
                conformidade legal de disposição de lei ou norma que tenha força
                jurídica equivalente, cabendo aos USUÁRIOS verificá-lo sempre
                que efetuarem o acesso na PLATAFORMA <strong>G3med</strong>.
              </p>
              <p>
                7.2. Ao navegar pela PLATAFORMA <strong>G3med</strong> e
                utilizar suas funcionalidades, o USUÁRIO aceita guiar-se pelo
                presente Termo que se encontra vigente na data de seu acesso.
                Por isso, o USUÁRIO deve se manter atualizado.
              </p>
              <p>
                7.3. As novas condições entrarão em vigor 10 (dez) dias após sua
                publicação no SITE. Sendo assim, caso o USUÁRIO não concorde com
                as modificações publicadas, este deverá imediatamente cessar o
                uso do SOFTWARE, proceder com o cancelamento de suas
                informações, conforme procedimentos descritos no presente Termo.
                Neste caso, o vínculo contratual do USUÁRIO com a{" "}
                <strong>G3med</strong> deixará de existir, desde que não haja
                contas ou dívidas em aberto. No entanto, não havendo
                manifestação no prazo acima estipulado, entender-se-á que o
                USUÁRIO aceitou tacitamente as novas condições cujo Termo
                continuará vinculando as partes.
              </p>
              <p>
                <strong>
                  CLÁUSULA 8ª – DOS DADOS PESSOAIS TRATADOS PELA PLATAFORMA
                  G3med
                </strong>
              </p>
              <p>
                8.1. A <strong>G3med</strong> possui documento próprio,
                denominado Política de Privacidade, que regula o tratamento dos
                dados coletados na PLATAFORMA <strong>G3med</strong>, sendo
                parte integrante e inseparável do presente Termo, e pode ser
                acessada pelo link{" "}
                <a href="https://g3med.com/politicadeprivacidade/">
                  https://g3med.com/politicadeprivacidade/
                </a>
              </p>
              <p>
                8.2. Caso alguma disposição sobre tratamento de dados pessoais
                da Política de Privacidade conflitar com qualquer outra do
                presente documento, deverá prevalecer o descrito no documento
                mais específico.
              </p>
              <p>
                <strong>
                  CLÁUSULA 9ª – DAS OBRIGAÇÕES E RESPONSABILIDADES DA G3med
                </strong>
              </p>
              <p>
                9.1. A PLATAFORMA <strong>G3med</strong> e suas funcionalidades
                são apresentadas ao USUÁRIO na maneira como estão disponíveis,
                podendo passar por constantes aprimoramentos e atualizações,
                obrigando-se a <strong>G3med</strong> a:
              </p>
              <p>
                9.1.1. Preservar a sua funcionalidade, com links não quebrados e
                utilizando layout que respeita a usabilidade e navegabilidade,
                sempre que possível;
              </p>
              <p>
                9.1.2. Exibir as funcionalidades e serviços de maneira clara,
                completa, precisa e suficiente de modo que exista a exata
                percepção das operações realizadas, e
              </p>
              <p>
                9.1.3. Proteger os dados coletados pela PLATAFORMA{" "}
                <strong>G3med</strong> da maneira que julgar mais eficiente.
              </p>
              <p>
                9.2. A <strong>G3med</strong> envidará seus melhores esforços
                para a manutenção da disponibilidade da PLATAFORMA{" "}
                <strong>G3med</strong>. No entanto, poderá ocorrer,
                eventualmente, alguma indisponibilidade temporária decorrente de
                manutenção necessária ou mesmo gerada por motivo de força maior,
                como desastres naturais, caso fortuito, como falhas nos sistemas
                de comunicação e acesso à Internet ou fatos de terceiro que
                fogem de sua esfera de vigilância e responsabilidade, não sendo
                a <strong>G3med</strong> por qualquer desses eventos
                responsável, nem pela indisponibilidade da PLATAFORMA{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                9.2.1. Se isso ocorrer, a <strong>G3med</strong> fará o que
                estiver ao seu alcance para restabelecer o acesso o mais breve
                possível, dentro das limitações técnicas de seus serviços e
                serviços de terceiros, dos quais a <strong>G3med</strong>{" "}
                depende para ficar disponível.
              </p>
              <p>
                9.3. Em nenhuma hipótese a <strong>G3med</strong> se
                responsabilizará:
              </p>
              <p>
                9.3.1. Por serviços prestados por parceiros ou terceiros
                disponíveis na <strong>G3med</strong>, sendo o USUÁRIO
                responsável por verificar e estar ciente de contratos, termos e
                condições do parceiro/terceiro para a contratação e uso do
                respectivo serviço, sendo a <strong>G3med</strong> mera
                intermediadora de sua disponibilização na PLATAFORMA{" "}
                <strong>G3med</strong>;
              </p>
              <p>
                9.3.2. Pelas falhas de acesso à PLATAFORMA{" "}
                <strong>G3med</strong> decorrentes de circunstâncias
                relacionadas à indisponibilidade da Internet em geral, quedas de
                energia, mau funcionamento digital ou físico de qualquer rede de
                telecomunicações, interrupções ou suspensões de conexão e falhas
                nos softwares e/ou hardwares utilizados pelos USUÁRIOS;
              </p>
              <p>
                9.3.3. Por quaisquer problemas,&nbsp;<em>bugs</em>,&nbsp;
                <em>glitches</em>&nbsp;ou funcionamentos indevidos que ocorrerem
                nos dispositivos e equipamentos dos USUÁRIOS e sejam resultantes
                do uso regular;
              </p>
              <p>
                9.3.4. Por erros de sistemas que alterem dados ou informações de
                serviços, inclusive preços informados na PLATAFORMA{" "}
                <strong>G3med</strong>, e por compras e pagamentos reprovados
                pelas instituições financeiras;
              </p>
              <p>
                9.3.5. Por casos em que determinado(s) Serviço(s) for(em)
                oferecido(s) a preços que claramente não condigam com a
                realidade praticada no mercado – erro crasso –, sendo que a{" "}
                <strong>G3med</strong>, bem como o(s) parceiro(s) que o(s)
                anunciou(aram), se eximem da responsabilidade de cumprir com a
                oferta;
              </p>
              <p>
                9.3.6. Por qualquer dano direto ou indireto ocasionado por
                eventos de terceiros, como ataque de hackers, falhas no sistema,
                no servidor ou na conexão à Internet, inclusive por ações de
                softwares maliciosos, como vírus, cavalos de Tróia e outros que
                possam, de algum modo, danificar o equipamento ou a conexão dos
                USUÁRIOS em decorrência do acesso, utilização ou navegação na
                PLATAFORMA <strong>G3med</strong>, bem como a transferência de
                dados e arquivos nela contidos, e
              </p>
              <p>
                9.3.7.Pelas informações contidas nos cadastros feitos por meio
                da PLATAFORMA <strong>G3med</strong>, sendo certo que os dados
                constantes dos cadastros são de total responsabilidade dos
                respectivos USUÁRIOS, inclusive o de pessoas por ele
                adicionadas, os quais serão os únicos responsáveis pelas sanções
                civis e penais que eventualmente resultarem de incorreção nesses
                dados.
              </p>
              <p>
                9.4. A <strong>G3med </strong>somente solicita informações
                pessoais através de e-mails cujos domínios sejam @g3med.com.{" "}
                <strong>G3med</strong> não se responsabiliza por quaisquer
                comunicações eletrônicas fraudulentas que coletem dados pessoais
                de seus USUÁRIOS (<em>phishing</em>).
              </p>
              <p>
                9.5. A i<strong>G3med </strong>pode, de acordo com seus
                objetivos de negócio, modificar, suspender ou descontinuar
                temporariamente as funcionalidades, Serviços e/ou conteúdo da
                PLATAFORMA <strong>G3med</strong>, segundo sua exclusiva
                conveniência, sempre que entender necessário para oferecer os
                melhores serviços ao USUÁRIO.
              </p>
              <p>
                9.5.1. A <strong>G3med</strong> poderá, a qualquer tempo,
                alterar, adequar ou remodelar o conteúdo, layout,
                funcionalidades e/ou ferramentas relacionadas à PLATAFORMA{" "}
                <strong>G3med</strong>, desde que esteja em conformidade com a
                legislação brasileira, em especial a Lei Federal nº 12.965 de
                2014 (Marco Civil da Internet) e a Lei nº 13.709 de 2018 (Lei
                Geral de Proteção de Dados) e diretrizes administrativas
                elaboradas pelo Conselho Federal de Medicina.
              </p>
              <p>
                9.6. A <strong>G3med</strong> pode, ainda, efetuar paralisações
                programadas para realizar a manutenção, atualização e ajustes de
                configuração da PLATAFORMA <strong>G3med</strong>, visando a
                melhorias na qualidade dos serviços prestados aos seus USUÁRIOS,
                envidando seus melhores esforços para que os horários de
                manutenção não coincidam com horários comerciais nem de pico de
                acesso.
              </p>
              <p>
                9.6.1. No entanto, caso haja manutenção emergencial a ser feita,
                esta poderá ocorrer dentro do horário comercial ou de pico, e
                sem aviso prévio, sendo que, nestes casos, a{" "}
                <strong>G3med</strong> não será responsabilizada pela
                indisponibilidade por haver fundada justificativa para que a
                manutenção ocorresse dessa forma.
              </p>
              <p>
                9.7. A <strong>G3med </strong>não será responsável, sob
                quaisquer circunstâncias, por eventuais perdas e danos,
                incluindo lucros cessantes, relacionados à suspensão do acesso à
                PLATAFORMA <strong>G3med</strong>. Os USUÁRIOS não possuem
                qualquer direito para exigir em relação à disponibilidade da
                PLATAFORMA <strong>G3med</strong>, tampouco poderão pleitear
                indenização ou reparação de danos em caso de a PLATAFORMA{" "}
                <strong>G3med</strong> permanecer fora do ar, independentemente
                da motivação.
              </p>
              <p>
                9.8. A <strong>G3med</strong> poderá, a seu exclusivo critério e
                sem qualquer obrigação, a qualquer tempo, com o intuito de
                minimizar riscos de falhas humanas e/ou uso indevido da{" "}
                <strong>G3med</strong>, indisponibilizar, temporariamente,
                determinadas funcionalidades até que eventuais inconformidades
                sejam readequadas.
              </p>
              <p>
                9.9. A <strong>G3med</strong> poderá restringir, limitar ou
                impedir, por qualquer meio ou forma, o acesso de um ou mais
                USUÁRIOS à PLATAFORMA <strong>G3med</strong>, conforme seu
                exclusivo critério, especialmente nos casos repetidos de
                suspeitas de fraude ou quaisquer outras ações que prejudiquem ou
                possam prejudicar o funcionamento da PLATAFORMA{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                9.10. A <strong>G3med</strong> reserva a si o direito de, a
                qualquer momento e sem aviso prévio, suspender os serviços do
                USUÁRIO que infringir qualquer das disposições aqui previstas,
                praticar, auxiliar ou instigar atos que venham a prejudicar o
                bom funcionamento da PLATAFORMA <strong>G3med</strong>, ou vier
                a utilizar-se de atos ilícitos para ações na PLATAFORMA{" "}
                <strong>G3med</strong>, incluindo mas não se limitando a,
                inserção de dados falsos e/ou incorretos, a utilização de
                cartões fraudulentos ou inexistentes, dentre outros atos que
                venham a infringir qualquer disposição legal prevista no
                ordenamento jurídico.
              </p>
              <p>
                <strong>
                  CLÁUSULA 10ª – DAS OBRIGAÇÕES E RESPONSABILIDADES DO USUÁRIO
                </strong>
              </p>
              <p>10.1. Os USUÁRIOS são responsáveis por e se obrigam a:</p>
              <p>
                10.1.1. Utilizar com retidão e de forma ética a PLATAFORMA{" "}
                <strong>G3med</strong>, respeitando as condições que regem a sua
                utilização e finalidade;
              </p>
              <p>
                10.1.2. Fornecer informações e dados cadastrais precisos,
                verdadeiros, completos e atualizados, completos e atualizados,
                além de informar canal de contato apto a ser acionado pela{" "}
                <strong>G3med</strong> para o melhor cumprimento dos serviços,
                responsabilizando-se civil e criminalmente por todas as
                informações que fornecer à <strong>G3med</strong>,
                comprometendo-se a atualizá-las sempre que houver alguma
                alteração por e-mail ou telefone;
              </p>
              <p>
                10.1.3. Dispor de dispositivos e equipamentos compatíveis,
                serviço de conexão à Internet com antivírus e&nbsp;
                <em>firewall</em>&nbsp;habilitados, e softwares devidamente
                atualizados;
              </p>
              <p>
                10.1.4. Deixar seus sistemas de&nbsp;<em>antispam</em>, filtros
                similares ou configurações de redirecionamento de mensagens
                ajustados de modo que não interfiram no recebimento dos
                comunicados e materiais da PLATAFORMA <strong>G3med</strong>,
                não sendo aceitável nenhuma escusa caso não tenha tido acesso a
                algum e-mail ou mensagem eletrônica em virtude dos recursos
                mencionados;
              </p>
              <p>
                10.1.5. Respeitar todos os direitos de propriedade intelectual
                de titularidade da <strong>G3med</strong>, bem como todos os
                direitos referentes a terceiros que porventura estejam ou
                estiveram, de alguma forma, disponíveis na PLATAFORMA{" "}
                <strong>G3med</strong>;
              </p>
              <p>
                10.1.6. Não acessar as áreas de programação da PLATAFORMA{" "}
                <strong>G3med</strong>, seu banco de dados ou qualquer outro
                conjunto de informações que faça parte da atividade de
                administração;
              </p>
              <p>
                10.1.7. Não realizar ou permitir que se realize engenharia
                reversa, nem traduzir, decompilar, copiar, modificar,
                reproduzir, alugar, sublicenciar, publicar, divulgar,
                transmitir, emprestar, distribuir ou, de outra maneira, dispor
                das ferramentas da PLATAFORMA <strong>G3med</strong> e de suas
                funcionalidades;
              </p>
              <p>
                10.1.8. Não utilizar softwares&nbsp;<em>spider</em>, ou de
                mineração de dados, de qualquer tipo ou espécie, além de outro
                aqui não tipificado, mas que atue de modo automatizado, tanto
                para realizar operações massificadas como para quaisquer outras
                finalidades;
              </p>
              <p>
                10.1.9. Não transmitir materiais contendo vírus de computador ou
                outros códigos, arquivos,&nbsp;<em>scripts</em>, agentes ou
                programas de computador maliciosos;
              </p>
              <p>
                10.1.10. Não explorar maliciosamente a segurança da PLATAFORMA{" "}
                <strong>G3med</strong> para a prática de atos ilícitos,
                proibidos pela lei e pelo presente Termo, lesivos aos direitos e
                interesses da <strong>G3med</strong> e de terceiros, ou que, de
                qualquer forma, possa danificar, inutilizar, sobrecarregar ou
                deteriorar a PLATAFORMA <strong>G3med</strong>;
              </p>
              <p>
                10.1.11. Responsabilizar-se exclusivamente por qualquer dano por
                si causado à <strong>G3med</strong> ou a terceiros em virtude do
                não cumprimento das obrigações aqui dispostas ou da não
                veracidade das informações prestadas, não havendo que se falar
                em subsidiariedade da obrigação, tampouco em solidariedade da{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                10.2. O USUÁRIO é o único e total responsável pelas informações
                inseridas por si na PLATAFORMA <strong>G3med</strong>, sendo
                certo que será o único responsável pelas sanções civis e penais
                que eventualmente resultarem de incorreção desses dados.
              </p>
              <p>
                10.3. As funcionalidades que compõem a PLATAFORMA{" "}
                <strong>G3med</strong> (inclusive TELECONSULTA) são oferecidas
                na forma de prestação de serviço, não conferindo ao USUÁRIO
                nenhum direito sobre o software utilizado pela PLATAFORMA{" "}
                <strong>G3med</strong>, seu conteúdo ou sobre as estruturas de
                informática que o sustentam.
              </p>
              <p>
                10.4. A eventual remoção, bloqueio ou suspensão de qualquer
                conteúdo ou funcionalidade da PLATAFORMA <strong>G3med</strong>{" "}
                em decorrência de alguma reclamação, a remoção, bloqueio ou
                suspensão deverá ser sempre compreendida como demonstração de
                intenção de evitar danos e prejuízos com boa-fé e intenção de
                solução amigável de conflitos, jamais como reconhecimento de
                culpa ou de qualquer infração pela PLATAFORMA{" "}
                <strong>G3med</strong> a direito de terceiro.
              </p>
              <p>
                10.5. O USUÁRIO reconhece como de propriedade da{" "}
                <strong>G3med</strong> o banco de dados em que serão armazenados
                e tratados os dados inseridos dentro da PLATAFORMA{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                10.6. O USUÁRIO se compromete a utilizar a TELECONSULTA, bem
                como os demais serviços disponibilizados pela{" "}
                <strong>G3med</strong>, de boa-fé e em conformidade com todos os
                preceitos legais, regulamentares e contratuais porventura
                aplicáveis, com a ordem pública, as normas e padrões de conduta
                sociais e profissionais aceitas ou tidas como boas práticas.
              </p>
              <p>
                10.7. O USUÁRIO autoriza a <strong>G3med</strong> a entrar em
                contato por telefone ou e-mail para verificar suas informações
                de contato pessoal. A <strong>G3med</strong> reserva a si o
                direito de solicitar maiores informações ao USUÁRIO, que
                concorda em fornecer todas as informações adicionais solicitadas
                para fins de combate à fraude. Caso essas informações não sejam
                enviadas dentro do prazo estipulado pela <strong>G3med</strong>,
                a <strong>G3med</strong> poderá bloquear o acesso do USUÁRIO à
                PLATAFORMA <strong>G3med</strong> até que o pedido de
                informações seja devidamente atendido.
              </p>
              <p>
                <strong>
                  CLÁUSULA 11ª – DA PROPRIEDADE INTELECTUAL DA G3med, CONTEÚDO
                  DO USUÁRIO E CONTEÚDO DE TERCEIROS
                </strong>
              </p>
              <p>
                11.1. Sujeito a este Termo, a i<strong>G3med</strong> concede ao
                USUÁRIO uma licença limitada, temporária, não exclusiva e não
                transferível para usar os Serviços contratados da PLATAFORMA{" "}
                <strong>G3med </strong>somente naquilo que seja estritamente
                necessário para o cumprimento das obrigações e fruição dos
                direitos dispostos neste Termo.
              </p>
              <p>
                11.2. Todos os direitos relativos à PLATAFORMA i
                <strong>G3med</strong>, bem como as suas funcionalidades, são de
                titularidade da <strong>G3med</strong>, inclusive no que diz
                respeito a todos os direitos de propriedade intelectual
                relacionados aos seus textos, imagens, gráficos, marcas,
                layouts, códigos, bases de dados e demais conteúdos
                desenvolvidos direta ou indiretamente pela{" "}
                <strong>G3med</strong> (“Direitos de Propriedade Intelectual”).
                É expressamente proibida a utilização sem autorização prévia de
                qualquer conteúdo ou marcas apresentadas na PLATAFORMA{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                11.3. Qualquer utilização dos Direitos de Propriedade
                Intelectual de titularidade da <strong>G3med</strong> só poderá
                ser feita mediante prévia e expressa autorização da{" "}
                <strong>G3med</strong>. O USUÁRIO assume toda e qualquer
                responsabilidade, de caráter civil e criminal, pela utilização
                indevida e não autorizada dos Direitos de Propriedade
                Intelectual, como informações, textos, gráficos, marcas,
                patentes, nomes de domínio, obras, imagens, logotipos, enfim, de
                todo e qualquer direito de propriedade intelectual ou industrial
                existentes na PLATAFORMA <strong>G3med</strong> ou de
                titularidade da <strong>G3med</strong>.
              </p>
              <p>
                11.4. O uso comercial da expressão “<strong>G3med</strong>” e de
                palavras que se assemelham a esta, porém com grafia
                diferenciada, como marcas, nomes empresariais ou nomes de
                domínio, além dos conteúdos das telas da PLATAFORMA{" "}
                <strong>G3med</strong>, assim como os programas de computador,
                bancos de dados, redes e seus arquivos, são de propriedade da{" "}
                <strong>G3med</strong> e estão protegidos pelas leis e tratados
                internacionais de proteção à propriedade intelectual, incluindo,
                mas não se limitando, às Leis Federais 9.609/98, 9.610/98,
                9.279/96 e 10.406/2002.
              </p>
              <p>
                11.5. Ao acessar a PLATAFORMA <strong>G3med</strong>, os
                USUÁRIOS declaram que irão respeitar todos os direitos de
                propriedade intelectual da <strong>G3med</strong>, incluindo,
                mas sem limitação, os programas de computador, direitos autorais
                e direitos de propriedade industrial sobre as marcas, patentes,
                nomes de domínio, denominação social e desenhos industriais,
                depositados ou registrados em nome da <strong>G3med</strong>,
                bem como de todos os direitos referentes a terceiros que
                porventura estejam, ou estiveram, de alguma forma, disponíveis
                na PLATAFORMA <strong>G3med</strong>.
              </p>
              <p>
                11.6. O acesso ou uso do conteúdo e dos Serviços da PLATAFORMA{" "}
                <strong>G3med</strong> não confere aos USUÁRIOS quaisquer
                direitos ao uso dos nomes, títulos, palavras, frases, marcas,
                patentes, nomes de domínio, denominação social, imagens,
                ilustrações, dados e informações, dentre outras, que estejam ou
                estiveram disponíveis.
              </p>
              <p>
                11.7. É vedada a utilização da PLATAFORMA <strong>G3med</strong>{" "}
                para quaisquer finalidades que contrariem este Termo, o Termo
                Geral e a Política de Privacidade, bem como a reprodução,
                distribuição e divulgação, total ou parcial, dos textos, figuras
                e gráficos que a compõem, sem prévia e expressa autorização da{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                11.8. Qualquer outro tipo de utilização de material autorizado,
                inclusive para fins editoriais, comerciais ou publicitários, só
                poderá ser feito na forma e mediante prévio e expresso
                consentimento da <strong>G3med</strong>.
              </p>
              <p>
                11.9. Qualquer reutilização de material autorizado deverá ser
                objeto de uma nova autorização da <strong>G3med</strong>.
              </p>
              <p>
                11.10. A autorização conferida para utilização do material
                solicitado não poderá ser transferida a terceiros, mesmo que
                vinculados ao sujeito autorizado. Será necessário obter
                autorização prévia, expressa e específica da{" "}
                <strong>G3med</strong> para cada terceiro.
              </p>
              <p>
                11.11. Ao publicar Conteúdo na PLATAFORMA <strong>G3med</strong>
                , o USUÁRIO concede o direito e a licença para a{" "}
                <strong>G3med</strong> usar, processar, armazenar ou de qualquer
                outra forma processar o Conteúdo com a finalidade exclusiva de
                prestar os Serviços da <strong>G3med</strong>. O usuário retém
                todo e qualquer direito sobre qualquer Conteúdo que enviar,
                publicar, vincular, compartilhar ou de qualquer outra forma
                disponibilizar através da PLATAFORMA <strong>G3med</strong> e é
                responsável por proteger esses direitos.
              </p>
              <p>
                11.12. O envio por parte do USUÁRIO para a{" "}
                <strong>G3med</strong>, por meio da PLATAFORMA{" "}
                <strong>G3med</strong> ou pelos canais divulgados, de ideias
                para novos produtos ou melhorias daqueles já existentes, para
                campanhas publicitárias ou qualquer outra ação de marketing,
                implicará automaticamente na autorização do USUÁRIO para uso do
                conteúdo pela <strong>G3med</strong>, ficando a critério
                exclusivo da <strong>G3med</strong> oferecer qualquer tipo de
                recompensa pecuniária ou compensação não financeira pelo que foi
                enviado, nada podendo o USUÁRIO reclamar neste sentido.
              </p>
              <p>
                11.13. A PLATAFORMA <strong>G3med</strong> poderá disponibilizar
                conteúdo de propriedade intelectual de terceiros (“Conteúdo de
                Terceiros”).&nbsp;
              </p>
              <p>
                <strong>CLÁUSULA 12ª – DA LIMITAÇÃO DE RESPONSABILIDADE</strong>
              </p>
              <p>
                12.1. Em nenhum caso a <strong>G3med</strong>, seus
                representantes legais, diretores, funcionários, parceiros,
                agentes, fornecedores, ou licenciados, serão responsabilizados
                por quaisquer danos diretos ou indiretos, incidentais,
                especiais, consequências ou punitivos, incluindo, sem limitação,
                danos materiais ou lucros cessantes, ou outras perdas monetária
                e intangíveis, relacionadas a qualquer reclamação, ação judicial
                ou outro procedimento tomado em relação à utilização do serviço,
                conteúdo, funcionalidades ou ferramentas da plataforma{" "}
                <strong>G3med</strong>, bem como pela correção ou inexatidão
                resultantes de quaisquer destes elementos: (i) danos e prejuízos
                que o usuário possa experimentar pela indisponibilidade ou
                funcionamento parcial do aplicativo e serviços; (ii) danos e
                prejuízos que os pacientes ou terceiros possam experimentar em
                razão das informações inseridas pelo usuário no aplicativo;
                (iii) danos e prejuízos que o usuário ou terceiros possam
                experimentar em decorrência da aplicação de qualquer conduta
                sugerida pela análise do caso clínico; (iv) danos e prejuízos
                que o usuário ou terceiros possam experimentar em decorrência do
                uso da plataforma em desconformidade com este termo, o termo
                geral ou com a política de privacidade; ou incapacidade de
                acessar ou usar o serviço; (v) danos e prejuízos que o usuário
                ou terceiros possam experimentar em decorrência do uso da
                plataforma em desconformidade com a lei, os costumes ou a ordem
                pública; (vi) danos e prejuízos que o usuário ou terceiros
                possam experimentar em decorrência de falhas no aplicativo,
                inclusive decorrentes de falhas no sistema, no servidor ou na
                conexão de rede, ou ainda de interações maliciosas como vírus
                que possam danificar o equipamento ou acessar informações do
                equipamento do usuário; (vii) qualquer conteúdo ou conduta de
                qualquer terceiro na plataforma i<strong>G3med</strong>; (viii)
                danos e prejuízos que o usuário ou terceiros possam experimentar
                em decorrência da indisponibilidade do profissional de saúde ou
                por não conseguir se comunicar pela teleconsulta, dentro dos
                períodos demarcados no agendamento.
              </p>
              <p>
                12.2. O usuário profissional da saúde declara e garante que é o
                único e exclusivo responsável pelo seu paciente e pelos dados do
                prontuário de saúde inseridos na plataforma{" "}
                <strong>G3med</strong>, bem como as prescrições medicamentosas
                elaboradas para os seus pacientes.
              </p>
              <p>
                12.3. Na medida do permitido pelas leis aplicáveis, ou conforme
                definido de outra forma neste documento, a{" "}
                <strong>G3med</strong> e qualquer pessoa ou entidade associada à
                prestação dos serviços por parte da <strong>G3med</strong> e
                qualquer entidade associada à prestação dos serviços por parte
                da <strong>G3med</strong> não serão responsáveis perante você ou
                qualquer terceiro que: (a) qualquer dano indireto, incidental,
                especial, consequencial, punitivo ou exemplar; (b) seu conteúdo.
              </p>
              <p>
                12.4. Todo e qualquer conteúdo publicado, compartilhado ou de
                qualquer outra forma disponibilizado pelo USUÁRIO, como textos,
                imagens, áudios, vídeos ou outros materiais (o “Conteúdo”) será
                de responsabilidade exclusiva do USUÁRIO, que deverá assegurar
                sua legalidade, confiabilidade e adequação. A{" "}
                <strong>G3med</strong> declara que não tem controle sobre nem é
                responsável pelo Conteúdo de Terceiros, inclusive as políticas
                de privacidade ou práticas de quaisquer sites ou serviços de
                terceiros. O USUÁRIO também reconhece e concorda que a i
                <strong>G3med</strong> não será responsável, direta ou
                indiretamente, por qualquer dano ou perda eventual e
                comprovadamente causados por, ou em conexão, com o uso de
                qualquer Conteúdo de Terceiros, bens ou serviços
                disponibilizados através de sites e serviços de terceiros. A{" "}
                <strong>G3med </strong>recomenda fortemente que o USUÁRIO leia
                diretamente os termos de uso e as políticas de privacidade de
                quaisquer sites ou serviços de terceiros que tenha contato por
                meio da PLATAFORMA <strong>G3med</strong>. Por fim, os USUÁRIOS
                ficam cientes que a <strong>G3med</strong> só poderá ser
                responsabilizada por conteúdo de terceiros (também USUÁRIOS) se,
                após ordem judicial específica, não indisponibilizar o conteúdo
                apontado como infringente, conforme art. 19, caput e 1º, da Lei
                12.965 de 2014 (Marco Civil da Internet), dispondo-se, contudo,
                a remover, em atendimento à notificação extrajudicial conteúdo
                comprovadamente violador de direitos do autor e conexos, em
                observância ao disposto no § 2º do mesmo dispositivo legal.
              </p>
              <p>
                <strong>CLÁUSULA 13ª – DA EXCLUSÃO DE GARANTIAS</strong>
              </p>
              <p>
                13.1. O uso do serviço de teleconsulta, bem como dos demais
                serviços da plataforma <strong>G3med</strong>, é por conta e
                risco do próprio usuário. O serviço é fornecido no estado em que
                se encontra (“as is”), sem garantias de qualquer tipo, expressas
                ou implícitas, incluindo, mas não se limitando a, garantias
                implícitas de comercialidade, adequação a uma finalidade
                específica, não violação ou infalibilidade.
              </p>
              <p>
                13.2. A <strong>G3med</strong> não garante que a) o serviço
                funcionará ininterruptamente, seguro ou disponível em qualquer
                hora ou local específico; b) quaisquer erros ou defeitos serão
                corrigidos; c) o serviço está livre de vírus ou outros
                componentes nocivos; ou d) os resultados do uso do serviço
                atenderão aos requisitos dos usuários.
              </p>
              <p>
                <strong>CLÁUSULA 14ª – DO CANCELAMENTO DO SERVIÇO</strong>
              </p>
              <p>
                14.1. A não utilização pelo USUÁRIO, definida como a ausência de
                qualquer acesso ao mesmo, pelo prazo de 01 (hum) ano, não
                ensejará o encerramento do referido CADASTRO. Em caso de
                inadimplência pelo prazo ininterrupto de 90 dias a assinatura é
                cancelada automaticamente, no entanto, o seu direito de acesso
                aos dados imputados na plataforma permanecerão acessíveis para
                você.
              </p>
              <p>
                14.2. Para os USUÁRIOS que permanecem com um CADASTRO ativo e
                com as MENSALIDADES pagas em dia, a <strong>G3med</strong>{" "}
                manterá os dados e informações dos pacientes, atreladas ao
                CADASTRO do USUÁRIO, por prazo indeterminado, na forma prevista
                na legislação. Para os USUÁRIOS que realizem o cancelamento de
                seu CADASTRO, os procedimentos previstos na cláusula a seguir.
              </p>
              <p>
                14.3. Na hipótese de cancelamento pelo USUÁRIO ADMINISTRADOR DA
                CONTA, este poderá exportar os dados dos seus pacientes
                lançados, mesmo que a conta esteja cancelada, tendo ciência que,
                pelo código de ética médica, este possui uma obrigação de manter
                um backup destes arquivos pelos prazos exigidos em lei,
                independente dos serviços fornecidos pela Plataforma{" "}
                <strong>G3med</strong>.
              </p>
              <p>
                14.4. Caso o USUÁRIO faça a opção pelo pagamento semestral ou
                anual do plano para usufruir do desconto nas MENSALIDADES, em
                caso de cancelamento antes do aniversário do CADASTRO, ou seja,
                anteriormente ao período de 6 (seis) ou 12 (doze) meses,
                respectivamente, os valores já pagos não serão reembolsados,
                mesmo que de maneira pro rata.
              </p>
              <p>
                14.5. Caso o cancelamento seja motivado pelas novas condições do
                Termo não haverá incidência da multa prevista no item 14.4.
              </p>
              <p>
                14.6. Caso a <strong>G3med</strong> verifique ou suspeite que
                algum USUÁRIO esteja violando alguma condição do presente Termo
                ou que esteja praticando atividade ilícita, além das medidas de
                proteção já discriminadas no presente Termo, tal como o
                cancelamento do referido CADASTRO, os dados do USUÁRIO infrator
                serão encaminhados às autoridades correspondentes, exceto as
                informações cujo sigilo é previsto em lei, para que estas tomem
                as medidas judiciais cabíveis.
              </p>
              <p>
                <strong>CLÁUSULA 15ª – DAS DISPOSIÇÕES GERAIS</strong>
              </p>
              <p>
                15.1. A tolerância do eventual descumprimento de qualquer
                disposição no presente documento não constituirá novação das
                obrigações aqui estipuladas e tampouco impedirá ou inibirá a
                exigibilidade das mesmas a qualquer tempo.
              </p>
              <p>
                15.2. Caso alguma disposição do presente documento seja julgada
                inaplicável ou sem efeito, o restante do documento continua a
                viger, sem a necessidade de medida judicial que declare tal
                assertiva.
              </p>
              <p>
                15.3. Estes Termos não criam qualquer outra modalidade de
                vínculo entre os USUÁRIOS e a <strong>G3med</strong>, inclusive,
                e sem limitação, sociedade, joint-venture, mandato,
                representação, parceria, consórcio, associação, formação de
                grupo econômico, vínculo empregatício ou similar. A{" "}
                <strong>G3med</strong> permanecerá uma entidade independente e
                autônoma.
              </p>
              <p>
                15.4. A <strong>G3med</strong> comunicará previamente sobre
                alterações nos preços dos serviços quando houver majoração de
                impostos e reajuste através de correção monetária (IPC-A/I).
              </p>
              <p>
                15.5. Havendo qualquer conflito entre este Termo e o Termo
                Geral, prevalecerá o mais documento mais específico.
              </p>
              <p>
                15.6. A PLATAFORMA <strong>G3med</strong> tem como base a data e
                horários oficiais de Brasília.
              </p>
              <p>Última atualização: 20 de fevereiro de 2024.</p>
              <p>
                <strong>Termo e condições de uso da G3med</strong>
              </p>
              <p>Usuário: Médico</p>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <p>
                        <strong>Categoria</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>Dados</strong>
                      </p>
                    </td>
                    <td>
                      <p>
                        <strong>Finalidade</strong>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Dados obrigatórios</p>
                    </td>
                    <td>
                      <p>Nome, e-mail, Nº de celular</p>
                    </td>
                    <td>
                      <p>autenticação, identificação, prescrição</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Dados facultativos</p>
                    </td>
                    <td>
                      <p>cpf, endereço, gênero, foto</p>
                    </td>
                    <td>
                      <p>identificação e prescrição</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Dados de saúde</p>
                    </td>
                    <td>
                      <p>
                        prescrições de medicamentos, exames, vacinas,
                        diagnósticos, alergias, doenças e outras condições de
                        saúde relacionadas
                      </p>
                    </td>
                    <td>
                      <p>
                        Consulta de Receitas Médicas, publicidade e divulgação
                        para terceiros
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>{" "}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Term;
