import { useState } from "react";
import _ from "lodash";

import { CustomTab } from "components/tab/custom-tab";
import { MedicalCareTab } from "views/painel/configurations/tabs/medical-care/medical-care-tab";
import { TenantDataTab } from "./tabs/tenant-data/tenant-data-tab";

const Configurations: React.FC = () => {
  const [activeTab, setActiveTab] = useState("0");

  const listTabs = [
    {
      label: "Dados da clínica",
      disabled: false,
      element: (
        <div className="mt-4">
          <TenantDataTab />
        </div>
      ),
    },
    {
      label: "Atendimento",
      disabled: false,
      element: (
        <div className="mt-4">
          <MedicalCareTab />
        </div>
      ),
    },
  ];

  const [tabs, setTabs] = useState(listTabs);

  return (
    <>
      <div className="main-wrapper">
        <CustomTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
};
export default Configurations;
