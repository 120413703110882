import { UserRoleEnumLabels } from "domain/user/entities/enums/user-role.enum";
import { OwnerTypeEnum } from "domain/tenants/entities/enums/owner-type.enum";
import { TokenTypeEnum } from "domain/auth/entities/enums/token-type.enum";
import { httpClient, setLocalAccessToken } from "config/http-client";
import { errorMessage, successMessage } from "helpers/toast";
import { errorHandler } from "helpers/http-helper";

export interface LoginProps {
  login: string;
  password: string;
  token?: string;
}

export interface ListTenant {
  id: string;
  user_name: string;
  user_role: string;
  tenant_name: string;
}
export interface SwitchSessionProps {
  to: string;
}

export interface RegisterTenantProps {
  tenant_name: string;
  user_name: string;
  document_number: string;
  phone: string;
  email: string;
  password: string;
  owner_type: OwnerTypeEnum;
  token?: string;
}

export interface ForgotPasswordResponse {
  prefix: string;
  email: string;
}

export interface RecoverPasswordProps {
  login: string;
  otp: string;
  new_password: string;
}
export interface FirstLoginResetPasswordProps {
  token: string;
  new_password: string;
}
export class IdentityService {
  static async login(input: LoginProps): Promise<TokenTypeEnum | void> {
    try {
      const result = await httpClient.request({
        url: "/auth/login",
        method: "post",
        data: input,
      });
      setLocalAccessToken(result.data.token);
      successMessage("Login efetuado com sucesso");

      return result.data.token_type;
    } catch (err) {
      errorMessage("Usuário/senha inválidos");
    }
  }

  static async fetchTenants(): Promise<ListTenant[]> {
    try {
      const result = await httpClient.request({
        url: "/users/me/applications",
        method: "get",
      });

      const data = result.data;

      return data.map((item: any) => ({
        ...item,
        user_role: UserRoleEnumLabels(item.user_role),
      })) as ListTenant[];
    } catch (err) {
      errorMessage("Falha ao buscar lista de aplicações");
      return [];
    }
  }

  static async switchSession(input: SwitchSessionProps): Promise<void> {
    try {
      const result = await httpClient.request({
        url: "/auth/switch-session",
        method: "post",
        data: input,
      });
      setLocalAccessToken(result.data.token);
    } catch (err: any) {
      const msg =
        err?.response?.data?.message?.[0] ?? "Falha ao selecionar aplicação";
      errorMessage(msg);
    }
  }

  static async logout(): Promise<void> {
    try {
      await httpClient.request({
        url: "/auth/logout",
        method: "post",
      });
    } catch (err: any) {
      errorMessage("Falha ao efetuar logout");
    }
  }

  static async registerTenant(input: RegisterTenantProps): Promise<boolean> {
    try {
      await httpClient.request({
        url: "/tenants",
        method: "post",
        data: input,
      });
      successMessage("Aplicação cadastrada com sucesso");
      return true;
    } catch (err: any) {
      errorHandler(err, "Falha ao cadastrar aplicação");
      return false;
    }
  }

  static async forgotPassword(login: string): Promise<ForgotPasswordResponse> {
    try {
      const response = await httpClient.request({
        url: "/auth/forgot-password",
        method: "post",
        data: { login },
      });
      successMessage("Email enviado com sucesso");
      return response.data;
    } catch (err: any) {
      errorHandler(err, "Falha ao enviar email");
      throw err;
    }
  }

  static async validateOTP(login: string, otp: string): Promise<void> {
    try {
      await httpClient.request({
        url: "/auth/validate-otp",
        method: "post",
        data: { login, otp },
      });
    } catch (err: any) {
      errorHandler(err, "Falha ao validar código");
      throw err;
    }
  }

  static async recoverPassword(input: RecoverPasswordProps): Promise<void> {
    try {
      await httpClient.request({
        url: "/auth/recover-password",
        method: "post",
        data: input,
      });
      successMessage("Senha alterada com sucesso");
    } catch (err: any) {
      errorHandler(err, "Falha ao alterar senha");
      throw err;
    }
  }

  static async firstLoginResetPassword(
    input: FirstLoginResetPasswordProps
  ): Promise<void> {
    try {
      await httpClient.request({
        url: "/auth/first-login-reset-password",
        method: "post",
        data: input,
      });
      successMessage("Senha alterada com sucesso");
    } catch (err: any) {
      errorHandler(err, "Falha ao alterar senha");
      throw err;
    }
  }
}
