import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { EletronicPrescriptionService } from "services/eletronic-prescription-service";

export const actionGetLinkDownloadPrescription = action(
  async (idPrescription: string, idDocument: string): Promise<string> => {
    SetIsLoadingState(true);
    try {
      return await EletronicPrescriptionService.getLinkDownloadPrescription(
        idPrescription,
        idDocument
      );
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
