import { Exam } from "services/medical-record-service";

export interface MedicalRecordExamsItemProps {
  exams: Exam[] | null;
}
export const MedicalRecordExamsItem: React.FC<MedicalRecordExamsItemProps> = ({
  exams,
}) => {
  return (
    <>
      <div className="d-flex row">
        <h4 className="text-primary">Exames Complementares</h4>
        {exams?.map((exam, index) => (
          <div key={`exam-item-${index}`} className="table-card-row">
            <div className="medical-record-history-row ">
              <div className="medical-record-history-title">Nome do Exame</div>
              <div
                className="medical-record-history-value"
                dangerouslySetInnerHTML={{ __html: exam.title }}
              ></div>
            </div>

            <div className="medical-record-history-row ">
              <div className="medical-record-history-title">
                Descrição do Exame
              </div>
              <div
                className="medical-record-history-value"
                dangerouslySetInnerHTML={{ __html: exam.description }}
              ></div>
            </div>

            <div className="table-card-row ">
              {exam.attachments.length > 0 && (
                <>
                  <div className="medical-record-history-title">Anexos</div>

                  <div className="table-card-value">
                    {exam.attachments.map((attachment, index) => (
                      <div
                        className="d-flex"
                        key={`exam-${index}-attachment-${index}`}
                      >
                        <div
                          className="g3-preview-img"
                          style={{
                            backgroundImage: `url(${attachment.thumbnail})`,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {index !== exams.length - 1 && <div className="mb-4" />}
          </div>
        ))}
      </div>
    </>
  );
};
