import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";

export interface ListAllDashboardProps {
  start: Date;
  end: Date;
  doctor_id?: number;
}

export interface DownloadReportProps {
  start: Date;
  end: Date;
  doctor_id?: number;
}

interface PaymentTypeItem {
  count_of_payments: number;
  total_amount_paid: number;
}

export interface ListAllDashboardResponse {
  schedule: {
    total_amount_paid: number;
    total_appointments: number;
    total_type: Record<string, number>;
    total_status: Record<string, number>;
    total_origin_contact: Record<string, number>;
    payment_type: Record<string, PaymentTypeItem>;
  };
  patients: {
    total_patients: number;
    total_male: number;
    total_female: number;
    total_neutral: number;
  };
  evolution_revenue: Record<string, number>;
  evolution_schedule: Record<string, number>;
  prescriptions: {
		total_prescriptions: number
	}
}

export class DashboardService {
  static async listAll(
    input: ListAllDashboardProps
  ): Promise<ListAllDashboardResponse> {
    try {
      const result = await httpClient.request({
        url: "/dashboard",
        method: "get",
        params: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao buscar dashboard");
      throw new Error("Falha ao buscar dashboard");
    }
  }

  static async downloadReport(
    input: DownloadReportProps
  ): Promise<any> {
    try {
      const result = await httpClient.request({
        url: "/dashboard/report",
        method: "get",
        params: input,
        responseType: "blob",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao gerar relatório financeiro");
      throw new Error("Falha ao gerar relatório financeiro");
    }
  }
}
