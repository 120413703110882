import { useMemo } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

interface StartPrescriptionInput {
  urlWebview: string;
  onCancel: () => void;
}
export const StartPrescription: React.FC<StartPrescriptionInput> = ({
  urlWebview,
  onCancel,
}: StartPrescriptionInput) => {
  const isOpen = useMemo(() => urlWebview != "", [urlWebview]);
  return (
    <>
      <div className="main-wrapper" style={{ height: "100vh" }}>
        {isOpen && (
          <Modal isOpen={isOpen} size="xl" fullscreen={true}>
            <ModalHeader toggle={onCancel}></ModalHeader>
            <ModalBody>
              <iframe
                src={urlWebview}
                style={{ width: "100%", height: "100%", border: "none" }}
                title="WebView"
              />
            </ModalBody>
          </Modal>
        )}
      </div>
    </>
  );
};
