import { action } from "mobx";

import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { MedicalRecordService } from "services/medical-record-service";

export const actionDeleteFile = action(async (id: number): Promise<void> => {
  SetIsLoadingState(true);
  try {
    await MedicalRecordService.deleteFile(id);
  } catch (error) {
    throw error;
  } finally {
    SetIsLoadingState(false);
  }
});
