import _ from "lodash";

import { Patient } from "domain/patient/entities/patient";
import { CreatePatientProps } from "services/patient-service";

export class CreatePatientToRequestMapper {
  static map(input: Patient): CreatePatientProps {
    return {
      name: input.name,
      phone: `${input.phone?.replace(/\D/g, "")}`,
      birthdate: _.isEmpty(input.birthdate) ? null : input.birthdate,
      email: input.email,
      document_number: _.isEmpty(input.document_number)
        ? null
        : input.document_number,
      sex: input.sex,
      address: {
        street: input.address.street,
        number: input.address.number,
        complement: input.address.complement,
        neighborhood: input.address.neighborhood,
        city: input.address.city,
        state: input.address.state,
        zip_code: input.address.zip_code,
      },
    };
  }
}
