// 2.00 -> R$ 2,00
export const inputMaskFormatToReal = (value: string) => {
  const maskCurrency = (valor, locale = 'pt-BR', currency = 'BRL') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(valor);
  };

  const onlyDigits = value
    .split('')
    .filter((s) => /\d/.test(s))
    .join('')
    .padStart(3, '0');
  const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
  return maskCurrency(digitsFloat);
};


export const labelMaskFormatToReal = (value: string) =>  {
  const numero = parseFloat(value);
  
  // Formata o número como moeda em reais
  const partes = numero.toFixed(2).split('.');

  const inteiro = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const decimal = partes[1];

  return `R$ ${inteiro},${decimal}`;
}

// R$ 2,00 => 2.00
export const maskedToCurrency = (value: string) => {
  
  if (!value || typeof value !== 'string') {
    return 0;
  }

  return value.replaceAll('.', '').replaceAll(',', '.').replace('R$ ', '').replace('R$ ', '');
};
