import React, { useState } from "react";

import ForgotPasswordForm from "./forgot-password-form";
import "./forgot-password.style.css";
import { errorMessage } from "helpers/toast";
import { actionForgotPassword } from "actions/identity/action-forgot-password";
import { actionValidateOtp } from "actions/identity/action-validate-otp";
import ValidateOtpForm from "./validate-otp-form";
import RecoverPasswordForm, {
  OnResetPasswordProps,
} from "./recover-password-form";
import { actionRecoverPassword } from "actions/identity/action-recover-password";
import { useNavigate } from "react-router-dom";

enum ForgotPasswordEnum {
  FORGOT_PASSOWRD = "FORGOT_PASSOWRD",
  VALIDATE_OTP = "VALIDATE_OTP",
  RECOVER_PASSWORD = "RECOVER_PASSWORD",
}

const ForgetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(ForgotPasswordEnum.FORGOT_PASSOWRD);
  const [maskedEmail, setMaskedEmail] = useState("");
  const [login, setLogin] = useState("");
  const [prefix, setPrefix] = useState("");
  const [otp, setOtp] = useState("");

  const onSendCode = async (login: string) => {
    try {
      const result = await actionForgotPassword(login);
      setPrefix(result.prefix);
      setMaskedEmail(result.email);
      setLogin(login);
      setStep(ForgotPasswordEnum.VALIDATE_OTP);
    } catch {}
  };

  const onResendCode = async () => {
    if (!login) {
      errorMessage("Falha ao obter CPF");
      return;
    }
    try {
      const result = await actionForgotPassword(login);
      setPrefix(result.prefix);
      setMaskedEmail(result.email);
    } catch {}
  };

  const onValidateOtp = async (otpValue: string) => {
    try {
      await actionValidateOtp(login, otpValue);
      setOtp(otpValue);
      setStep(ForgotPasswordEnum.RECOVER_PASSWORD);
    } catch {}
  };

  const onResetPassword = async (input: OnResetPasswordProps) => {
    try {
      await actionRecoverPassword({
        login,
        otp,
        new_password: input.new_password,
      });
      navigate("/auth/login");
    } catch {}
  };
  return (
    <>
      {step === ForgotPasswordEnum.FORGOT_PASSOWRD && (
        <ForgotPasswordForm onSendCode={onSendCode} />
      )}
      {step === ForgotPasswordEnum.VALIDATE_OTP && (
        <ValidateOtpForm
          onResendCode={onResendCode}
          onValidateOtp={onValidateOtp}
          prefix={prefix}
          email={maskedEmail}
        />
      )}
      {step === ForgotPasswordEnum.RECOVER_PASSWORD && (
        <RecoverPasswordForm onResetPassword={onResetPassword} />
      )}
    </>
  );
};

export default ForgetPassword;
