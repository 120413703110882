import { CreateReceptionist } from "views/painel/employees/create/create-receptionist/create-receptionist";
import { UpdateReceptionist } from "views/painel/employees/update/update-receptionist/update-receptionist";
import { CreateDoctor } from "views/painel/employees/create/create-doctor/create-doctor";
import { UpdateDoctor } from "views/painel/employees/update/update-doctor/update-doctor";
import { ListDoctor } from "views/painel/employees/list/list-doctor/list-doctor";
import { MedicalRecord } from "views/painel/medical-record/index/medical-record";
import UpdatePatient from "views/painel/patients/update-patient/update-patient";
import ForgetPassword from "views/auth/forgot-password/forgot-password";
import CreatePatient from "views/painel/patients/create/create-patient";
import ListPatient from "views/painel/patients/list/list-patient";
import SelectTenant from "views/auth/select-tenant/select-tenant";
import Employees from "views/painel/employees/index/employees";
import Patients from "views/painel/patients/index/patients";
import Schedule from "views/painel/schedule/index/schedule";
import DashboardContainer from "views/painel/dashboard/dashboard.container";
import MyData from "views/painel/my-data/index/my-data";
import Register from "views/auth/register/register";
import Login from "views/auth/login/login";
import FirstLogin from "views/auth/first-login/first-login";
import Term from "views/term/term";
import Configurations from "views/painel/configurations/configurations";
import { PrescriptionContainer } from "views/painel/prescription/index/prescription.container";
import UserIdentity from "./domain/user/entities/user-identity";
import { UserRoleEnum } from "./domain/user/entities/enums/user-role.enum";
import Privacy from "views/term/privacy";
import { ListPrescriptionContainer } from 'views/painel/prescription/list/list-prescription.container';

export interface RouteProps {
  path: string;
  name: string;
  icon: string;
  component: React.FC;
  layout: string;
  isHidden: boolean;
  breadcrumb?: {
    path: string;
    name: string;
  };
}

export const routes: RouteProps[] = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-line",
    component: DashboardContainer,
    layout: "/painel",
    isHidden: false,
  },
  {
    path: "/agenda",
    name: "Agenda",
    icon: "fas fa-calendar-days",
    component: Schedule,
    layout: "/painel",
    isHidden: false,
  },
  {
    path: "/prescricoes",
    name: "Prescrições",
    icon: "fas fa-pen-clip",
    component: PrescriptionContainer,
    layout: "/painel",
    isHidden: false,
  },
  {
    path: "/prescricao/:id",
    name: "Prescrições",
    icon: "fas fa-pen-clip",
    component: ListPrescriptionContainer,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/prescricoes",
      name: "Lista de Prescrições",
    },
  },
  {
    path: "/prontuario/:id",
    name: "Prontuário",
    icon: "fas fa-clipboard-user",
    component: MedicalRecord,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de prontuários",
    },
  },
  {
    path: "/pacientes",
    name: "Prontuários",
    icon: "fas fa-user-injured",
    component: Patients,
    layout: "/painel",
    isHidden: !UserIdentity.hasRole([
      UserRoleEnum.DOCTOR,
      UserRoleEnum.OWNER_DOCTOR,
      UserRoleEnum.RECEPTIONIST,
    ]),
  },
  {
    path: "/colaboradores",
    name: "Colaboradores",
    icon: "fas fa-users",
    component: Employees,
    layout: "/painel",
    isHidden: !UserIdentity.hasRole([
      UserRoleEnum.SUPERADMIN,
      UserRoleEnum.ADMIN,
      UserRoleEnum.CLINIC_MANAGER,
      UserRoleEnum.OWNER_DOCTOR,
    ]),
  },
  {
    path: "/configuracoes",
    name: "Configurações",
    icon: "fas fa-gears",
    component: Configurations,
    layout: "/painel",
    isHidden: false,
  },
  {
    path: "/meus-dados",
    name: "Meus dados",
    icon: "fas fa-id-card",
    component: MyData,
    layout: "/painel",
    isHidden: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/cadastrar",
    name: "Register",
    icon: "ni ni-key-25 text-info",
    component: Register,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/esqueci-senha",
    name: "Forget Password",
    icon: "ni ni-key-25 text-info",
    component: ForgetPassword,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/aplicacoes",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: SelectTenant,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/primeiro-acesso",
    name: "Primeiro Acesso",
    icon: "ni ni-key-25 text-info",
    component: FirstLogin,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/pacientes/criar",
    name: "Pacientes",
    icon: "fas fa-user-injured",
    component: CreatePatient,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de Pacientes",
    },
  },
  {
    path: "/paciente/editar/:id",
    name: "Paciente",
    icon: "fas fa-user-injured",
    component: UpdatePatient,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de Pacientes",
    },
  },
  {
    path: "/paciente/:id",
    name: "Paciente",
    icon: "fas fa-user-injured",
    component: ListPatient,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/pacientes",
      name: "Lista de Pacientes",
    },
  },
  {
    path: "/medico/criar",
    name: "Médico",
    icon: "fas fa-user-injured",
    component: CreateDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores",
      name: "Lista de Médicos",
    },
  },
  {
    path: "/medico/editar/:id",
    name: "Médico",
    icon: "fas fa-user-injured",
    component: UpdateDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores",
      name: "Lista de médicos",
    },
  },
  {
    path: "/medico/:id",
    name: "Médico",
    icon: "fas fa-user-injured",
    component: ListDoctor,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores",
      name: "Lista de médicos",
    },
  },
  {
    path: "/recepcionista/criar",
    name: "Recepcionista",
    icon: "fas fa-user-injured",
    component: CreateReceptionist,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores",
      name: "Lista de Recepcionistas",
    },
  },
  {
    path: "/recepcionista/editar/:id",
    name: "Recepcionista",
    icon: "fas fa-user-injured",
    component: UpdateReceptionist,
    layout: "/painel",
    isHidden: true,
    breadcrumb: {
      path: "/painel/colaboradores",
      name: "Lista de Recepcionistas",
    },
  },
  {
    path: "/termos-uso",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Term,
    layout: "/auth",
    isHidden: true,
  },
  {
    path: "/politica-privacidade",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Privacy,
    layout: "/auth",
    isHidden: true,
  },
];
