import { action } from "mobx";

import { UpdateDoctorProps, UserService } from "services/user-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionUpdateDoctor = action(
  async (id: string, input: UpdateDoctorProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await UserService.updateDoctor(id, input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
