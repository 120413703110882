import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, FormGroup, Label } from "reactstrap";
import { ListAllDashboardResponse } from "services/dashboard-service";
import { actionListAllDashboard } from "actions/dashboard/action-list-all-dashboard";
import { ScheduleTypeDashboard } from "./schedule-type-dashboard";
import { ScheduleStatusDashboard } from "./schedule-status-dashboard";
import { CustomCard } from "components/card/custom-card";
import { labelMaskFormatToReal } from "helpers/format-money";
import { SchedulePaymentTypeDashboard } from "./schedule-payment-type-dashboard";
import { ScheduleOriginContactDashboard } from "./schedule-origin-contact-dashboard";
import { EvolutionRenevueDashboard } from "./evolution-revenue-dashboard";
import { EvolutionScheduleDashboard } from "./evolution-schedule-dashboard";
import {
  ScheduleSelectDoctor,
  ScheduleSelectDoctorProps,
} from "../schedule/index/schedule-select-doctor";
import UserIdentity from "domain/user/entities/user-identity";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import { actionDownloadReport } from "actions/dashboard/action-download-report";

const OFFSET_DAYS = 1;
const SEVEN_DAYS = 8;
const FIFTEEN_DAYS = 16;
const THIRTY_DAYS = 31;
const SIXTY_DAYS = 61;

interface FormValues {
  start: Date;
  end: Date;
  doctor_id?: number; // Optional number
}

const DashboardContainer: React.FC = (props: any) => {
  const [data, setData] = useState<ListAllDashboardResponse>();
  const [startDate, setStartDate] = useState(
    new Date(new Date(new Date().setDate(new Date().getDate() - THIRTY_DAYS)))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [formValues, setFormValues] = useState<FormValues>({
    start: new Date(
      new Date(new Date().setDate(new Date().getDate() - THIRTY_DAYS)).setHours(
        21,
        0,
        0,
        0
      )
    ),
    end: new Date(new Date().setHours(20, 59, 0, 0)),
    doctor_id: undefined,
  });

  const [selectedDoctor, setSelectedDoctor] =
    useState<ScheduleSelectDoctorProps | null>();

  const fetchData = async () => {
    try {
      const result = await actionListAllDashboard(formValues);
      setData(result);
    } catch (err) {
      console.error(err);
    }
  };
  const onExportReport = async () => {
    try {
      await actionDownloadReport(formValues, "relatorio-financeiro.csv");
    } catch (err) {
      console.error(err);
    }
  };

  const isDoctor = (): Boolean => UserIdentity.hasRole([UserRoleEnum.DOCTOR]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main-wrapper">
      <div className="d-flex col-12 flex-wrap">
        {!isDoctor() && (
          <div className="col-12 col-md-4 px-2">
            <ScheduleSelectDoctor
              label="Profissional"
              selectedDoctor={selectedDoctor}
              setSelectedDoctor={(value: ScheduleSelectDoctorProps) => {
                setSelectedDoctor(value);
                setFormValues({
                  ...formValues,
                  doctor_id: value?.value,
                });
              }}
            />
          </div>
        )}
        <FormGroup className="col-12 col-md-2 px-2">
          <Label htmlFor="name">Data Inicial</Label>
          <DatePicker
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            className="form-control"
            wrapperClassName="w-100"
            selected={startDate}
            onChange={(date: Date) => {
              const dateFormatted = new Date(
                new Date(
                  new Date().setDate(date.getDate() - OFFSET_DAYS)
                ).setHours(21, 0, 0, 0)
              );
              setStartDate(date);
              setFormValues({
                ...formValues,
                start: dateFormatted,
              });
            }}
          />
        </FormGroup>

        <FormGroup className="col-12 col-md-2 px-2">
          <Label htmlFor="name">Data Final</Label>
          <DatePicker
            locale="pt-BR"
            dateFormat="dd/MM/yyyy"
            className="form-control"
            wrapperClassName="w-100"
            selected={endDate}
            onChange={(date: Date) => {
              const dateFormatted = new Date(date.setHours(20, 59, 0, 0));
              setEndDate(date);
              setFormValues({
                ...formValues,
                end: dateFormatted,
              });
            }}
          />
        </FormGroup>
        <FormGroup className="col-12 col-md-2 px-2 d-flex align-items-end">
          <Button color="primary" className="form-control" onClick={fetchData}>
            Filtrar
          </Button>
        </FormGroup>
        <FormGroup className="col-12 col-md-2 px-2 d-flex align-items-end">
          <Button color="secondary" className="form-control" onClick={onExportReport}>
            Exportar Relatório
          </Button>
        </FormGroup>
      </div>
      <div className="d-flex col-12 flex-wrap">
        <div className="col-12 col-md-3 px-3 my-2">
          <CustomCard
            title={"FATURAMENTO"}
            value={labelMaskFormatToReal(
              String(data?.schedule.total_amount_paid ?? 0)
            )}
            icon="fa fa-line-chart"
          />
        </div>

        <div className="col-12 col-md-3 px-3 my-2">
          <CustomCard
            title={"AGENDAMENTOS"}
            value={String(data?.schedule.total_appointments)}
            icon="fa fa-calendar"
          />
        </div>

        <div className="col-12 col-md-3 px-3 my-2">
          <CustomCard
            title={"PACIENTES"}
            value={String(data?.patients.total_patients)}
            icon="fa fa-users"
          />
        </div>

        <div className="col-12 col-md-3 px-3 my-2">
          <CustomCard
            title={"PRESCRIÇÕES"}
            value={String(data?.prescriptions?.total_prescriptions)}
            icon="fa fa-line-chart"
          />
        </div>

        <div className="d-flex col-12 flex-wrap mt-5 ">
          {data?.evolution_revenue && (
            <div className="d-flex col-12 col-md-6 flex-wrap d-flex justify-content-center">
              <div className="medical-record-section-title fs-19">
                Faturamento
              </div>
              <EvolutionRenevueDashboard data={data?.evolution_revenue} />
            </div>
          )}
          {data?.evolution_schedule && (
            <div className="d-flex col-12 col-md-6 flex-wrap d-flex justify-content-center">
              <div className="medical-record-section-title fs-19">
                Agendamentos
              </div>
              <EvolutionScheduleDashboard data={data?.evolution_schedule} />
            </div>
          )}
        </div>

        <div className="mt-5 d-flex col-12 flex-wrap">
          {data?.schedule?.total_type && (
            <div className="col-12 col-md-3">
              <div className="medical-record-section-title fs-19 text-center">
                Tipos de Agendamentos
              </div>
              <ScheduleTypeDashboard data={data?.schedule?.total_type} />
            </div>
          )}
          {data?.schedule?.total_status && (
            <div className="col-12 col-md-3">
              <div className="medical-record-section-title fs-19 text-center">
                Status dos Agendamentos
              </div>
              <ScheduleStatusDashboard data={data?.schedule?.total_status} />
            </div>
          )}
          {data?.schedule?.payment_type && (
            <div className="col-12 col-md-3">
              <div className="medical-record-section-title fs-19 text-center">
                Métodos de pagamento
              </div>
              <SchedulePaymentTypeDashboard
                data={data?.schedule?.payment_type}
              />
            </div>
          )}
          {data?.schedule?.total_origin_contact && (
            <div className="col-12 col-md-3">
              <div className="medical-record-section-title fs-19 text-center">
                Origem de contato
              </div>
              <ScheduleOriginContactDashboard
                data={data?.schedule?.total_origin_contact}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardContainer;
