import { errorHandler } from "helpers/http-helper";
import { httpClient } from "config/http-client";

export interface UpdateTenantDataProps {
  name: string;
  phone: string;
  email: string;
  address: {
    zip_code: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
  };
}

export interface ListTenantDataResponse {
  id: number;
  name: string;
  logo: string;
  email: string;
  phone: string;
  address: {
    zip_code: string;
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
  };
}

export class TenantService {
  static async listTenantData(): Promise<ListTenantDataResponse> {
    try {
      const result = await httpClient.request({
        url: "/tenants",
        method: "get",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao buscar dados");
      throw err;
    }
  }

  static async updateTenantData(input: UpdateTenantDataProps): Promise<void> {
    try {
      await httpClient.request({
        url: `/tenants`,
        method: "patch",
        data: input,
      });
    } catch (err) {
      errorHandler(err, "Falha ao atualizar dados");
      throw new Error("Falha ao atualizar dados");
    }
  }
}
