import { makeAutoObservable } from "mobx";

interface FormData {
  attachments: [];
}

class MedicalRecordAttachmentsStore {
  formData: FormData = {
    attachments: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setExamFiles(attachments: []) {
    this.formData.attachments = attachments;
  }

  getFormData(): FormData {
    return this.formData;
  }

  isEmpty(): boolean {
    return this.formData.attachments.length === 0;
  }

  cleanData(): void {
    this.formData = {
      attachments: [],
    };
  }
}

const medicalRecordAttachmentsStore = new MedicalRecordAttachmentsStore();

export default medicalRecordAttachmentsStore;
