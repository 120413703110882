const config = () => {
  if (process.env.NODE_ENV === 'production')
    return {
      BACKEND_URL: process.env.BACKEND_URL ?? 'https://api.g3med.com',
      FRONTEND_URL: process.env.FRONTEND_URL ?? 'https://app.g3med.com',
      API_KEY: "g3med",
      RECAPTCHA_SITE_KEY: "6Lf9bTIqAAAAACr4cvmRf9e0_xiYjuWS0vseSbDB"
    };

  return {
    BACKEND_URL: 'http://localhost:3001',
    FRONTEND_URL: 'http://localhost:3000',
    API_KEY: "g3med",
    RECAPTCHA_SITE_KEY: "6LfPwdgZAAAAAAJ8q69L6C6JJn24b_kXQ-qLG0BJ"
  };
};
export default config;
