import { action } from "mobx";

import { ListPatientResponse, PatientService } from "services/patient-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionFetchPatient = action(
  async (id: string): Promise<ListPatientResponse> => {
    SetIsLoadingState(true);
    try {
      return await PatientService.fetchPatient(id);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
