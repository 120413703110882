export enum UserStatusEnum {
  ACTIVE = 1,
  INACTIVE = 0,
  WAITING_CONFIRMATION = 2,
}

export const UserStatusEnumLabels = (status: UserStatusEnum) => {
  const labels = {
    [UserStatusEnum.ACTIVE]: 'Ativo',
    [UserStatusEnum.INACTIVE]: 'Inativo',
    [UserStatusEnum.WAITING_CONFIRMATION]: 'Aguardando confirmação',
  };

  return labels[status];
};
