import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";
import { cities } from "helpers/cities-list";
import { customStyles } from "./helpers/react-select-styles";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectUfFormProps {
  keyName: string;
  isReadOnly?: boolean;
}

export const SelectUfForm: React.FC<SelectUfFormProps> = ({
  keyName,
  isReadOnly,
}: SelectUfFormProps) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue(keyName, undefined);
    setFieldValue(keyName, selectedOption);
  };

  return (
    <div>
      <FormGroup className="col-12 px-0">
        <Label htmlFor={keyName}>Estado</Label>
        <Field
          name={keyName}
          id={keyName}
          isClearable
          isSearchable
          onChange={onChange}
          defaultValue={(values as any)?.[keyName]}
          value={(values as any)?.[keyName]}
          placeholder="UF"
          options={cities}
          filterOption={createFilter(filterConfig as any)}
          component={Select}
          isDisabled={isReadOnly}
          styles={customStyles}
        />
      </FormGroup>
      {(errors as any)?.[keyName] && (
        <div className="custom-invalid-feedback">Estado inválido</div>
      )}
    </div>
  );
};
