import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

export interface CustomPaginationProps {
  currentPage: number;
  totalPages: number;
  onChangePage: (page: number) => void;
}

export const CustomPagination = ({
  totalPages,
  currentPage,
  onChangePage,
}: CustomPaginationProps) => {
  return (
    <>
      <ResponsivePagination
        className="pagination justify-content-end"
        current={currentPage}
        total={totalPages}
        onPageChange={onChangePage}
      />
    </>
  );
};
