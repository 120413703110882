import { Input, InputGroup, InputGroupText } from "reactstrap";
import { FieldProps, useFormikContext } from "formik";

import "components/form/custom-form.style.css";
import { IMaskInput } from "react-imask";
import { inputMaskFormatToReal } from "helpers/format-money";

interface CCustomInputMoneyProps extends FieldProps {
  label: string;
  leftIconClass?: string;
}

export const CustomInputMoney: React.FC<CCustomInputMoneyProps> = ({
  field,
  form,
  label,
  leftIconClass,
  ...props
}) => {
  const { name } = field;
  const errorMessage = form.touched[name] && form.errors[name];

  const { setFieldValue, errors, values } = useFormikContext();
  const onChange = (event: any) => {
    setFieldValue(name, inputMaskFormatToReal(event.target.value));
  };

  return (
    <>
      <InputGroup>
        {leftIconClass && (
          <InputGroupText
            className={`text-secondary bg-white ${
              !!errorMessage ? "is-invalid" : ""
            } }`}
          >
            <i className={leftIconClass}></i>
          </InputGroupText>
        )}
        <Input
          {...field}
          {...props}
          className={` ${leftIconClass ? "border-left-0" : ""}`}
          invalid={!!errorMessage}
          tag={IMaskInput}
          onChange={onChange}
        />
      </InputGroup>
      {!!errorMessage && (
        <div className="custom-invalid-feedback">{errorMessage}</div>
      )}
    </>
  );
};
