import { ListTenant } from "services/identity-service";
import { CustomTable } from "components/table/custom-table";

export interface SelectTenantTableProps {
  tenants: ListTenant[];
  onSelectTenant: (tenantId: string) => void;
}
export const SelectTenantTable = ({
  tenants,
  onSelectTenant,
}: SelectTenantTableProps) => {
  const columnNames = {
    // id: "ID",
    tenant_name: "Clínica/Consultório",
    user_name: "Nome do Usuário",
    user_role: "Perfil",
  };

  return (
    <>
      <CustomTable
        columnNames={columnNames}
        data={tenants}
        onSelect={onSelectTenant}
        tableType="table"
        btnLabelNotFound=""
        btnClickNotFound={() => {}}
      />
    </>
  );
};
