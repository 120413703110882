import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import {
  DashboardService,
  DownloadReportProps,
} from "services/dashboard-service";

export const actionDownloadReport = action(
  async (input: DownloadReportProps, fileName: string): Promise<any> => {
    SetIsLoadingState(true);
    try {
      const response = await DashboardService.downloadReport(input);
      const blob = new Blob([response], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
