import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { MakeScheduleProps, ScheduleService } from "services/schedule-service";

export const actionUpdateSchedule = action(
  async (id: string, input: MakeScheduleProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await ScheduleService.updateSchedule(id, input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
