import { Address } from "domain/address/entities/address";
import { FetchAddressResponse } from "services/address-service";

export class FetchAddressMapper {
  static mapToDomain(input: FetchAddressResponse): Address {
    return {
      zip_code: input.cep,
      street: input.logradouro,
      complement: input.complemento,
      neighborhood: input.bairro,
      city: input.localidade,
      state: input.uf,
      number: "",
    };
  }
}
