import { Input, InputGroup, InputGroupText } from "reactstrap";
import { FieldProps } from "formik";

import "components/form/custom-form.style.css";

interface CustomFormInputProps extends FieldProps {
  label: string;
  leftIconClass?: string;
}

export const CustomFormInput: React.FC<CustomFormInputProps> = ({
  field,
  form,
  label,
  leftIconClass,
  ...props
}) => {
  const { name } = field;
  const errorMessage = form.touched[name] && form.errors[name];

  return (
    <>
      <InputGroup>
        {leftIconClass && (
          <InputGroupText
            className={`text-secondary bg-white ${
              !!errorMessage ? "is-invalid" : ""
            } }`}
          >
            <i className={leftIconClass}></i>
          </InputGroupText>
        )}
        <Input
          {...field}
          {...props}
          className={` ${leftIconClass ? "border-left-0" : "form-control"}`}
          invalid={!!errorMessage}
        />

        {/* {rightIconClass && (
          <InputGroupText
            className="text-secondary bg-white"
            onClick={rightIconClick}
          >
            <i className={rightIconClass}></i>
          </InputGroupText>
        )} */}
      </InputGroup>
      {!!errorMessage && (
        <div className="custom-invalid-feedback">{errorMessage}</div>
      )}
    </>
  );
};
