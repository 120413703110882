import { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { actionFetchAllMedicalRecord } from "actions/medical-record/action-fetchall-medical-record";
import { PaginationMeta } from "components/table/custom-table";
import { ListMedicalRecord } from "services/medical-record-service";
import { MedicalRecordItem } from "./medical-record-item";

import "./medical-record.style.css";
import { SectionLine } from "components/common/section-line/section-line";
import { dateFormatter } from "helpers/date-formatter";
interface MedicalRecordHistoryProps {
  id: number;
}

export const MedicalRecordHistory: React.FC<MedicalRecordHistoryProps> = ({
  id,
}) => {
  const [medicalRecord, setMedicalRecord] = useState<ListMedicalRecord[]>([]);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);

  const fetchAllMedicalRecord = async () => {
    const result = await actionFetchAllMedicalRecord(id);
    setMedicalRecord(result.items);
    setMeta(result.meta);
  };

  useEffect(() => {
    (async () => {
      await fetchAllMedicalRecord();
    })();
  }, []);

  return (
    <>
      {medicalRecord.length !== 0 && (
        <div >
          {medicalRecord.map((item) => (
            <div key={`medica-record-item-${item.id}`} className="box-medical-record-history">
              <SectionLine
                title={`${dateFormatter(item.created_at)}`}
                theme="light"
              />
              <MedicalRecordItem {...item} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
