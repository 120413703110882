import { useState } from "react";

import { CustomTab } from "components/tab/custom-tab";
import { FormMyData } from "views/painel/my-data/index/tabs/my-data/form-my-data";
import { FormUpdatePassword } from "views/painel/my-data/index/tabs/update-password/form-update-password";

const MyData: React.FC = () => {
  const [activeTab, setActiveTab] = useState("0");

  const listTabs = [
    {
      label: "Meus Dados",
      disabled: false,
      element: (
        <div className="mt-4">
          <FormMyData />
        </div>
      ),
    },
    {
      label: "Alterar Senha",
      disabled: false,
      element: (
        <div className="mt-4">
          <FormUpdatePassword />
        </div>
      ),
    },
  ];

  const [tabs, setTabs] = useState(listTabs);

  return (
    <>
      <div className="main-wrapper">
        <CustomTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
};
export default MyData;
