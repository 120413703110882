export enum PrescriptionCategoryEnum {
  PRESCRIPTION = 'prescription',
  MEDICAL_CERTIFICATE = 'medical_certificate',
  EXAM = 'exam',
  MEDICAL_REFERRAL = 'medical_referral',
  MEDICAL_REPORT = 'medical_report',
}

export const PrescriptionCategoryLabels = (key: PrescriptionCategoryEnum) => {
  const labels = {
    [PrescriptionCategoryEnum.PRESCRIPTION]: 'Receita',
    [PrescriptionCategoryEnum.MEDICAL_CERTIFICATE]: 'Atestado',
    [PrescriptionCategoryEnum.EXAM]: 'Exame',
    [PrescriptionCategoryEnum.MEDICAL_REFERRAL]: 'Encaminhamento',
    [PrescriptionCategoryEnum.MEDICAL_REPORT]: 'Relatório',
  };

  return labels[key] ?? '';
};
