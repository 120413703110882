import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import {
  DashboardService,
  ListAllDashboardProps,
  ListAllDashboardResponse,
} from "services/dashboard-service";

export const actionListAllDashboard = action(
  async (input: ListAllDashboardProps): Promise<ListAllDashboardResponse> => {
    SetIsLoadingState(true);
    try {
      return await DashboardService.listAll(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
