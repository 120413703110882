import React from "react";

import "./custom-card.style.css";

interface CustomCardProps {
  title: string;
  value: string;
  icon: string;
}
export const CustomCard: React.FC<CustomCardProps> = ({
  title,
  value,
  icon,
}) => {
  return (
    <>
      <div className="custom-card">
        <div className="custom-card-body">
          <div className="row">
            <div className="col-8">
              <p className="fs-14 font-weight-600 text-black-300">{title}</p>
              <h5>{value}</h5>
            </div>

            <div className="col-3 my-auto">
              <div className="custom-card-icon">
                {/* <i className="fa fa-line-chart " aria-hidden="true"></i> */}
                <i className={icon} aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
