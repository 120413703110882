import { Anamnesis } from "services/medical-record-service";

export const MedicalRecordAnamensisItem: React.FC<Anamnesis> = (item) => {
  return (
    <>
      <div className="d-flex row">
        <div className="medical-record-section-title ">Anamnese</div>
        {item.main_complaint && (
          <div className="medical-record-history-row">
            <div className="medical-record-history-title">Queixa Principal</div>
            <div
              className="medical-record-history-value"
              dangerouslySetInnerHTML={{ __html: item.main_complaint }}
            ></div>
          </div>
        )}

        {item.current_illness_history && (
          <div className="medical-record-history-row">
            <div className="medical-record-history-title">
              Histórico de Doença Atual
            </div>
            <div
              className="medical-record-history-value"
              dangerouslySetInnerHTML={{ __html: item.current_illness_history }}
            ></div>
          </div>
        )}

        {item.medical_history && (
          <div className="medical-record-history-row">
            <div className="medical-record-history-title">
              Histórico e antecedentes
            </div>
            <div
              className="medical-record-history-value"
              dangerouslySetInnerHTML={{ __html: item.medical_history }}
            ></div>
          </div>
        )}

        {item.physical_examination && (
          <div className="medical-record-history-row">
            <div className="medical-record-history-title">Exame Físico</div>
            <div
              className="medical-record-history-value"
              dangerouslySetInnerHTML={{ __html: item.physical_examination }}
            ></div>
          </div>
        )}

        {item.diagnosis && (
          <div className="medical-record-history-row">
            <div className="medical-record-history-title">Diagnóstico</div>
            <div
              className="medical-record-history-value"
              dangerouslySetInnerHTML={{ __html: item.diagnosis }}
            ></div>
          </div>
        )}

        {item.treatment_plan && (
          <div className="medical-record-history-row">
            <div className="medical-record-history-title">Condutas</div>
            <div
              className="medical-record-history-value"
              dangerouslySetInnerHTML={{ __html: item.treatment_plan }}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};
