import { action } from "mobx";
import { IdentityService, SwitchSessionProps } from "services/identity-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionSwitchSession = action(
  async (input: SwitchSessionProps): Promise<void> => {
    SetIsLoadingState(true);
    await IdentityService.switchSession(input);
    SetIsLoadingState(false);
  }
);
