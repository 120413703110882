import { httpClient } from "config/http-client";
import { errorHandler } from "helpers/http-helper";
import { PaginationMeta } from "components/table/custom-table";
import ErrorCodesEnum from "domain/error-codes/error-code.enum";
import { ErrorCodeException } from "domain/error-codes/error-code-exception";
import ErrorCodeMessages from "domain/error-codes/error-code-message";

export interface StartPrescriptionRequest {
  patient_id: number;
}

export interface StartPrescriptionResponse {
  ModalURL: string;
}

export interface ListAllEletronicPrescriptionRequest {
  limit: number;
  page: number;
  patient_name: string;
}

export interface ListAllEletronicPrescriptionRequest {
  limit: number;
  page: number;
}

export interface ListAllEletronicPrescriptionItem {
  id: number;
  validation_code: string;
  status: number;
  is_signed: boolean;
  tags: string[];
  created_at: Date;
  patient_name: string;
  doctor_name: string;
}

export interface ListEletronicPrescriptionItemResponse {
  id: number;
  validation_code: string;
  status: number;
  is_signed: boolean;
  tags: string[];
  created_at: Date;
  patient_name: string;
  doctor_name: string;
  prescriptions: {
    medicines: {
      title: string;
      description: string;
      quantity: string;
    }[];
    manualMedicines: {
      title: string;
      description: string;
    }[];
  };
  exams: {
    title: string;
    description: string;
    laterality: string;
  }[];
  medical_certificates: {
    title: string;
    description: string;
  }[];
  medical_reports: {
    title: string;
    description: string;
  }[];
  medical_referrals: {
    title: string;
    description: string;
  }[];
  documents: {
    id: string;
    category: string;
    is_signed: boolean;
  }[];
}

export interface ListAllEletronicPrescriptionResponse {
  items: ListAllEletronicPrescriptionItem[];
  meta: PaginationMeta;
}

export class EletronicPrescriptionService {
  static async startPrescription(
    input: StartPrescriptionRequest
  ): Promise<StartPrescriptionResponse> {
    try {
      const result = await httpClient.request({
        url: "/eletronic-prescription/start",
        method: "post",
        data: input,
      });

      return result.data;
    } catch (err: any) {
      errorHandler(err, "Falha ao iniciar prescrição");

      if (err?.response?.data?.code === ErrorCodesEnum.NEEDS_UPDATE_DATA) {
        throw new ErrorCodeException(
          ErrorCodesEnum.NEEDS_UPDATE_DATA,
          ErrorCodeMessages.returnPT[ErrorCodesEnum.NEEDS_UPDATE_DATA].message
        );
      }

      throw new Error("Falha ao buscar prescrição");
    }
  }

  static async listAllPrescriptions(
    input: ListAllEletronicPrescriptionRequest
  ): Promise<ListAllEletronicPrescriptionResponse> {
    try {
      const result = await httpClient.request({
        url: "/eletronic-prescription",
        method: "get",
        params: input,
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao buscar prescrição");
      throw new Error("Falha ao buscar prescrição");
    }
  }

  static async listPrescription(
    id: number
  ): Promise<ListEletronicPrescriptionItemResponse> {
    try {
      const result = await httpClient.request({
        url: `/eletronic-prescription/${id}`,
        method: "get",
      });

      return result.data;
    } catch (err) {
      errorHandler(err, "Falha ao buscar prescrição");
      throw new Error("Falha ao buscar prescrição");
    }
  }

  static async getLinkDownloadPrescription(
    idPrescription: string,
    idDocument: string
  ): Promise<string> {
    try {
      const result = await httpClient.request({
        url: `/eletronic-prescription/link-download/${idPrescription}/${idDocument}`,
        method: "get",
      });

      return result.data.link;
    } catch (err) {
      errorHandler(err, "Falha ao realizar download da prescrição");
      throw new Error("Falha ao realizar download da prescrição");
    }
  }
}
