export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e9ecef" : "#fff",
    cursor: state.isDisabled ? "not-allowed" : "default",
    // border: "1px solid #b7c6f1",
    border: "1px solid #efeff2",
    borderRadius: "0.5rem",
    height: "2.75rem",
  }),
  singleValue: (provided: any, _state: any) => ({
    ...provided,
    color: "#212529",
  }),
};
