import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  UncontrolledDropdown,
} from "reactstrap";

import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";
import { ListScheduleButtonDropDown } from "./list-schedule-button-dropdown";

interface ListScheduleDropdownProps {
  id: number;
  currentStatus: ScheduleStatusEnum;
  onUpdateStatus: (id: number, status: ScheduleStatusEnum) => void;
}
export const ListScheduleDropdown: React.FC<ListScheduleDropdownProps> = ({
  id,
  currentStatus,
  onUpdateStatus,
}) => {
  return (
    <div style={{ width: "fit-content" }}>
      <Nav className="d-flex" navbar>
        <UncontrolledDropdown nav>
          <DropdownToggle className="pr-0" nav>
            <Media className="d-flex">
              <ListScheduleButtonDropDown
                status={currentStatus}
                hasEditButton={true}
              />
            </Media>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-arrow"
            end
            style={{ top: "50px" }}
          >
            <DropdownItem
              onClick={() => onUpdateStatus(id, ScheduleStatusEnum.SCHEDULED)}
            >
              <ListScheduleButtonDropDown
                status={ScheduleStatusEnum.SCHEDULED}
                hasEditButton={false}
              />
            </DropdownItem>
            <DropdownItem
              onClick={() => onUpdateStatus(id, ScheduleStatusEnum.CONFIRMED)}
            >
              <ListScheduleButtonDropDown
                status={ScheduleStatusEnum.CONFIRMED}
                hasEditButton={false}
              />
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                onUpdateStatus(id, ScheduleStatusEnum.WAITING_MEDICAL_ATTENTION)
              }
            >
              <ListScheduleButtonDropDown
                status={ScheduleStatusEnum.WAITING_MEDICAL_ATTENTION}
                hasEditButton={false}
              />
            </DropdownItem>
            <DropdownItem
              onClick={() =>
                onUpdateStatus(id, ScheduleStatusEnum.NOT_ATTENDED)
              }
            >
              <ListScheduleButtonDropDown
                status={ScheduleStatusEnum.NOT_ATTENDED}
                hasEditButton={false}
              />
            </DropdownItem>
            <DropdownItem
              onClick={() => onUpdateStatus(id, ScheduleStatusEnum.ATTENDED)}
            >
              <ListScheduleButtonDropDown
                status={ScheduleStatusEnum.ATTENDED}
                hasEditButton={false}
              />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </div>
  );
};
