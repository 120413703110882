export const CalendarCustomToolbar = ({ label, onNavigate, onView }: any) => {
  return (
    <div className="rbc-toolbar">
      <div className="rbc-btn-group">
        <button onClick={() => onNavigate("PREV")}>Anterior</button>
        <button onClick={() => onNavigate("TODAY")}>Hoje</button>
        <button onClick={() => onNavigate("NEXT")}>Próximo</button>
      </div>
      <span className="rbc-toolbar-label">{label}</span>

      <div className="rbc-btn-group">
        <button onClick={() => onView("week")}>Semana</button>
        <button onClick={() => onView("day")}>Dia</button>
      </div>
    </div>
  );
};
