import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";

import { actionFetchPatient } from "actions/patients/action-fetch-patient";
import { CustomTab } from "components/tab/custom-tab";
import {
  FormPatientData,
  PatientDataFormValues,
} from "views/painel/patients/common/form/form-patient-data";
import {
  FormPatientAddress,
  PatientAddressFormValues,
} from "views/painel/patients/common/form/form-patient-address";
import { dateFormatter } from "helpers/date-formatter";
import { errorMessage } from "helpers/toast";
import { UserSexEnum } from 'domain/user/entities/enums/user-sex.enum';

const ListPatient: React.FC = observer(() => {
  const navigate = useNavigate();
  let { id } = useParams();
  const [activeTab, setActiveTab] = useState("0");
  const [isLoaded, setIsLoaded] = useState(false);
  const [patientData, setPatientData] = useState<PatientDataFormValues>({
    name: "",
    document_number: "",
    email: "",
    phone: "",
    birthdate: "",
    sex: UserSexEnum.NEUTRAL,
  });

  const [patientAddress, setPatientAddress] =
    useState<PatientAddressFormValues>({
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      zip_code: "",
    });

  const onSubmitData = (values: PatientDataFormValues) => {
    setActiveTab("1");
  };

  const fetchPatient = async (id: string) => {
    try {
      const response = await actionFetchPatient(id);
      setPatientData({
        id: response.id?.toString(),
        name: response.name,
        document_number:
          response.document_number === null ? "" : response.document_number,
        email: response.email,
        phone: response.phone,
        birthdate: response.birthdate
          ? dateFormatter(response.birthdate, "yyyy-MM-dd")
          : "",
        sex: response.sex ?? UserSexEnum.NEUTRAL,
      });

      setPatientAddress({
        street: response.address?.street ?? "",
        number: response.address?.number ?? "",
        complement: response.address?.complement ?? "",
        neighborhood: response.address?.neighborhood ?? "",
        city: response.address?.city ?? "",
        state: response.address?.state ?? "",
        zip_code: response.address?.zip_code ?? "",
      });
      setIsLoaded(true);
    } catch (err) {
      navigate("/painel/pacientes");
    }
  };
  const tabs = useMemo(() => {
    return [
      {
        label: "Dados pessoais",
        disabled: false,
        element: (
          <div className="mt-4">
            <FormPatientData
              patientData={patientData}
              onSubmit={onSubmitData}
              onBack={() => navigate("/painel/pacientes")}
              isReadOnly={true}
            />
          </div>
        ),
      },
      {
        label: "Dados de endereço",
        disabled: false,
        element: (
          <div className="mt-4">
            <FormPatientAddress
              patientAddress={patientAddress}
              onSubmit={(values: PatientAddressFormValues) => {}}
              onBack={() => setActiveTab("0")}
              isReadOnly={true}
            />
          </div>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData, patientAddress]);
  useEffect(() => {
    (async () => {
      if (id) {
        await fetchPatient(id);
      } else {
        errorMessage("Falha ao obter paciente");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="main-wrapper">
        {isLoaded && (
          <CustomTab
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </div>
    </>
  );
});
export default ListPatient;
