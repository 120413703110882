import { Procedure } from "services/medical-record-service";

export interface MedicalRecordProceduresItemProps {
  procedures: Procedure[] | null;
}
export const MedicalRecordProceduresItem: React.FC<
  MedicalRecordProceduresItemProps
> = ({ procedures }) => {
  return (
    <>
      <div className="d-flex row">
        <h4 className="text-primary">Procedimentos</h4>
        {procedures?.map((procedure, index) => (
          <div key={index} className="table-card-row">
            <div className="medical-record-history-row ">
              <div className="medical-record-history-title">
                Nome do Procedimento
              </div>
              <div
                className="medical-record-history-value"
                dangerouslySetInnerHTML={{ __html: procedure.title }}
              ></div>
            </div>

            <div className="medical-record-history-row ">
              <div className="medical-record-history-title">
                Descrição do Procedimento
              </div>
              <div
                className="medical-record-history-value"
                dangerouslySetInnerHTML={{ __html: procedure.description }}
              ></div>
            </div>

            <div className="table-card-row ">
              {procedure.attachments.length > 0 && (
                <>
                  <div className="medical-record-history-title">Anexos</div>

                  <div className="table-card-value">
                    {procedure.attachments.map((attachment, index) => (
                      <div
                        className="d-flex"
                        key={`procedure-${index}-attachment-${index}`}
                      >
                        <div
                          className="g3-preview-img"
                          style={{
                            backgroundImage: `url(${attachment.thumbnail})`,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {index !== procedures.length - 1 && <div className="mb-4" />}
          </div>
        ))}
      </div>
    </>
  );
};
