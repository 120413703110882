import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";

import {
  PaymentTypeColors,
  PaymentTypeEnum,
  PaymentTypeLabels,
} from "domain/schedule/enums/payment-type.enum";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface PaymentTypeItem {
  count_of_payments: number;
  total_amount_paid: number;
}
export interface SchedulePaymentTypeDashboardProps {
  data: Record<string, PaymentTypeItem>;
}

export const SchedulePaymentTypeDashboard: React.FC<
  SchedulePaymentTypeDashboardProps
> = ({ data }) => {
  const values = Object.values(data).map((value) => value.total_amount_paid);

  const chartData = {
    labels: Object.keys(data).map((key) =>
      PaymentTypeLabels(key as unknown as PaymentTypeEnum)
    ),
    datasets: [
      {
        label: "total: ",
        data: values,
        backgroundColor: Object.keys(data).map(
          (key) => PaymentTypeColors[key]["hex"]
        ),
        borderColor: Object.keys(data).map((key) => "#FFFFFF"),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        color: "#fff",
        font: {
          size: 16,
          weight: "500",
          family: "Arial, sans-serif",
        },
        formatter: (value: number, context: any) => {
          return ``;
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
          padding: 15,
        },
      },
    },
  };

  return (
    <>
      <div style={{ maxHeight: "500px" }}>
        <Doughnut data={chartData} options={options} />
      </div>
    </>
  );
};
