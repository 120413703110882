import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { enumIterator } from "helpers/typescript/enum-iterator";
import {
  ProfessionTypeEnum,
  ProfessionTypeEnumLabels,
} from "domain/user/entities/enums/profession-type.enum";
import { customStyles } from "./helpers/react-select-styles";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};

interface SelectProfessionTypeFormProps {
  isReadOnly?: boolean;
}

export const SelectProfessionTypeForm: React.FC<
  SelectProfessionTypeFormProps
> = ({ isReadOnly }: SelectProfessionTypeFormProps) => {
  const { setFieldValue, values, errors } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue("profession", undefined);
    setFieldValue("profession", selectedOption);
  };

  const optionsProfession = enumIterator(ProfessionTypeEnum).map((key) => ({
    value: ProfessionTypeEnum[key],
    label: ProfessionTypeEnumLabels(
      ProfessionTypeEnum[key] as unknown as ProfessionTypeEnum
    ),
  }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor="email">Conselho</Label>
      <Field
        name="profession"
        id="profession"
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.profession}
        placeholder="Conselho"
        options={optionsProfession}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
        styles={customStyles}
      />
      {(errors as any)?.profession && (
        <div className="custom-invalid-feedback">Conselho inválido</div>
      )}
    </FormGroup>
  );
};
