import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  MakeAppointmentPaymentProps,
  ScheduleService,
} from "services/schedule-service";

export const actionMakeAppointmentPayment = action(
  async (id: number, input: MakeAppointmentPaymentProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await ScheduleService.makeAppointmentPayment(id, input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
