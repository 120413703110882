import React, { useState, useEffect } from "react";

interface OTPResendButtonProps {
  onSubmit: () => void;
}
const OTPResendButton: React.FC<OTPResendButtonProps> = ({
  onSubmit,
}: OTPResendButtonProps) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval = null;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handleResendClick = async () => {
    if (timer > 0) return;
    setTimer(60); // Start a 60-second countdown
    onSubmit();
  };

  return (
    <div className="text-center px-1">
      <a
        className={`btn-resend-code ${
          timer > 0 ? "text-secondary" : "text-primary"
        }`}
        onClick={handleResendClick}
      >
        {timer > 0
          ? ` Reenviar código em ${timer} segundos`
          : " Reenviar código"}
      </a>
    </div>
  );
};

export default OTPResendButton;
