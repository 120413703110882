import { dateFormatter } from "helpers/date-formatter";
import "./prescription-header-info.style.css";

interface PrescriptionHeaderInfoProps {
  patientName: string;
  doctorName: string;
  date: Date;
  validationCode: string;
}

export const PrescriptionHeaderInfo: React.FC<PrescriptionHeaderInfoProps> = ({
  patientName,
  doctorName,
  date,
  validationCode,
}) => {
  return (
    <>
      <div className="prescription-header-info">
        <div className="prescription-header-top" />
        <div className="prescription-header-content">
          <div className="prescription-content-img">
            <img
              alt="..."
              src={`https://ui-avatars.com/api/?name=${patientName}&size=40&rounded=true&color=fff&background=1632D2`}
            />
          </div>
          <div className="prescription-content-data">
            <div className="d-flex col-12 col-md-6 row">
              <div className="col-12 align-self-center">
                <b className="font-weight-500 fs-20 text-black-500 w-100">
                  {patientName}
                </b>
              </div>
              <div className="col-12 mt-1 row">
                <span className="prescription-content-label">Médico</span>
                <span className="prescription-content-text">{doctorName}</span>
              </div>
            </div>
            <div className="d-flex col-12 col-md-6 flex-wrap">
              <div className="col-12 mt-1 row">
                <span className="prescription-content-label">Código de validação</span>
                <span className="prescription-content-text">{validationCode}</span>
              </div>
              <div className="col-12 mt-1 row">
                <span className="prescription-content-label">Emissão</span>
                <span className="prescription-content-text">
                  {dateFormatter(date) || "Não informado"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
