export enum OwnerTypeEnum {
  CLINIC = 0,
  DOCTOR = 1,
}

export const OwnerTypeLabels = (ownerType: OwnerTypeEnum) => {
  const labels = {
    [OwnerTypeEnum.CLINIC]: 'Clínica',
    [OwnerTypeEnum.DOCTOR]: 'Médico',
  };

  return labels[ownerType];
};
