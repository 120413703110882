import { action } from "mobx";

import { PatientService, UpdatePatientProps } from "services/patient-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionUpdatePatient = action(
  async (id: string, input: UpdatePatientProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await PatientService.updatePatinet(id, input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
