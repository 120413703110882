import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import "react-datepicker/dist/react-datepicker.css";

export interface ModalDeleteScheduleProps {
  isOpen: boolean;
  toggle: () => void;
  onSubmit: () => void;
}

export const ModalDeleteSchedule: React.FC<ModalDeleteScheduleProps> = ({
  isOpen,
  onSubmit,
  toggle,
}: ModalDeleteScheduleProps) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle}>Excluir agendamento</ModalHeader>
      <ModalBody>
        <div className="text-center">
          <img
            alt="doctor-img"
            className='mb-4'
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADUUlEQVR4nO2ZTUgVURTHf/Zp0peVZUIgLVQKo1pIRQRiXxbt2kQQtHEbbco2JtFC2lRGRIRt2uhCc1EGfREZYZG1iygq0kg0LdK+qGziwn/iOj7fm3m+92Ye+IcDjzv/c+75z7v3zpkzMAVfKAWeAI/1OyuxB/gMOLJhYC9ZhOlAA/BXAppljsYaxIk0lgA3lfRv4CiQo2s1wC9duwcsI6JYD7xVogNAVQzOZuCDOL3ABiKGA8B3JfgAKIrDXQrcFfcncIgIYDZwydrQF4FZPvxmaK+4fleAPELCCuCREvkBHEwixj7gq2I8A1aSYVQC/UrgFbBmErHKgOeKNQTsJAPI0Un0RxNfA/JTEHc+0Oo5oqelIG4ok6XrJk349w8CO8iOZTsG+4FvCtwNFDMe24DXejZUW+O7gfe6thX/KNZcjuY2OSSNmUCjdUReBnI9R2g98M7iGOuxOL2ea4ZbJ99EyNWcrm+jcgqEIj3Y3IeWKS+8qPck6UeIa8cD5FKjHPw8bMdgC9BnJVUxAa9HnEprafV4js9qiXGXVlUMsX5QYc3Xpxzj4rCKPeNwGyiIw3XvblAk61egnNyC1OQ6DnOtUtu1RKV2poWgnOwcm5X7f7ilxpcAE4UhxPZ3c+3CgtlET4GSLBJSovqsc7IThS0kZUT3/WMO/pEnH+MbGSEvxVsbIIl18nkRwCftQi6IZ4pHvzgln/MBfNIupBwY1YuRKSoTYZWW1Kh8IyPE4Iy4b4DVTIxyqzFxmsnBSYcQU8RdtV53zwIbgQWyTcA5q1Zq8xR+Zs90ACNq4F33sefSIsR92h6zTrFYZkrxWk+1UGa9Hni5pWEIsZtzprXTbsVo19jiGPw2cVrUrDPWorHWMIUEjTEgTqE1tlxj/dkoxCSf1ULarKVVKGvJxqVVqpPKu9mHw97sycQwFWyTxW/SGFER4n7gWeiDmy/uJ5+xMyqkSzHs1tBE2CXuwygKqVWMW9aHnlgw1+6IeySKQkx58lFxTsThnRRnQC3ZyAlBbVW3j9uhVtA8mWkN3bA6ItsDxHUyLQTtkaE4NdhgEp8RnDCEGCxSm7RbVe6Ivr3XJdltd8ISkmo4U0Ky/R9xIm4J0RmBJJ0Edj+xjClMgVTgH+sh6Y7h67CPAAAAAElFTkSuQmCC"
          />

          <span className="col-12">
            <h5>Deseja excluir o agendamento?</h5>
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancelar
        </Button>
        <Button color="danger" onClick={onSubmit}>
          Excluir
        </Button>
      </ModalFooter>
    </Modal>
  );
};
