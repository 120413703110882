import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import {
  ScheduleStatusColors,
  ScheduleStatusEnum,
  ScheduleStatusLabels,
} from "domain/schedule/enums/schedule-status.enum";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export interface ScheduleStatusDashboardProps {
  data: Record<string, number>;
}

export const ScheduleStatusDashboard: React.FC<
  ScheduleStatusDashboardProps
> = ({ data }) => {
  const values = Object.values(data).map((value) => value);
  const total = values.reduce((acc, value) => acc + value, 0);

  const chartData = {
    labels: Object.keys(data).map((key) =>
      ScheduleStatusLabels(key as unknown as ScheduleStatusEnum)
    ),
    datasets: [
      {
        label: "total: ",
        data: values,

        backgroundColor: Object.keys(data).map(
          (key) =>
            ScheduleStatusColors[key as unknown as ScheduleStatusEnum]["hex"]
        ),
        borderColor: Object.keys(data).map((key) => "#FFFFFF"),

        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        color: "#fff",
        font: {
          size: 16,
          weight: "600",
          family: "Arial, sans-serif",
        },
        formatter: (value: number, context: any) => {
          // return `${value} (${((value / total) * 100).toFixed(2)}%)`;
          return `${value}`;
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          boxWidth: 20,
          padding: 15,
        },
      },
    },
  };

  return (
    <>
      <div style={{ maxHeight: "500px" }}>
        <Doughnut data={chartData} options={options} />
      </div>
    </>
  );
};
