export enum ScheduleTypeEnum {
  APPOINTMENT = 0,
  FOLLOW_UP = 1,
  EXAM = 2,
  PROCEDURE = 3,
}

export const ScheduleTypeLabels = (scheduleType: ScheduleTypeEnum) => {
  const labels = {
    [ScheduleTypeEnum.APPOINTMENT]: "Consulta",
    [ScheduleTypeEnum.FOLLOW_UP]: "Retorno",
    [ScheduleTypeEnum.EXAM]: "Exame",
    [ScheduleTypeEnum.PROCEDURE]: "Procedimento",
  };

  return labels[scheduleType];
};

export const ScheduleTypeColors = {
  [ScheduleTypeEnum.APPOINTMENT]: {
    class: "primary",
    hex: "#1632D2",
    rgba: "rgba(22, 50, 210, 1)",
  },
  [ScheduleTypeEnum.FOLLOW_UP]: {
    class: "info",
    hex: "#17a2b8",
    rgba: "rgba(23, 162, 184, 1)",
  },
  [ScheduleTypeEnum.EXAM]: {
    class: "secondary",
    hex: "#6c757d",
    rgba: "rgba(108, 117, 125, 1)",
  },
  [ScheduleTypeEnum.PROCEDURE]: {
    class: "success",
    hex: "#198754",
    rgba: "rgba(25, 135, 84, 1)",
  },
};

export const ScheduleTypeBackgroundColors = {
  [ScheduleTypeEnum.APPOINTMENT]: {
    hex: "#f4f6fe",
  },
  [ScheduleTypeEnum.FOLLOW_UP]: {
    hex: "#e3f7fa",
  },
  [ScheduleTypeEnum.EXAM]: {
    hex: "#ebedef",
  },
  [ScheduleTypeEnum.PROCEDURE]: {
    hex: "#e6f9f3",
  },
};