interface DefaultFooterProps {
  style: string;
}
const DefaultFooter: React.FC<DefaultFooterProps> = ({ style }) => {
  return (
    <>
      <footer className={style}>
        Todos os diretitos reservados &copy; {new Date().getFullYear()} G3MED |{" "}
        <a href="/auth/termos-uso" target="_blank" className={style}>
          <b>Termos de uso</b>
        </a>{" "}
        &{" "}
        <a href="/auth/politica-privacidade" target="_blank" className={style}>
          <b>Política de Privacidade</b>
        </a>
      </footer>
    </>
  );
};

export default DefaultFooter;
