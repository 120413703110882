import { action } from "mobx";
import {
  IdentityService,
  RegisterTenantProps,
} from "services/identity-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionRegisterTenant = action(
  async (input: RegisterTenantProps): Promise<boolean> => {
    SetIsLoadingState(true);
    const result = await IdentityService.registerTenant(input);
    SetIsLoadingState(false);

    return result;
  }
);
