import { TabContent, TabPane } from "reactstrap";
import "./custom-tab.style.css";

export interface CustomTabProps {
  tabs: {
    label: string;
    disabled: boolean;
    element: JSX.Element;
  }[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

export const CustomTab: React.FC<CustomTabProps> = ({
  tabs,
  activeTab,
  setActiveTab,
}: CustomTabProps) => {
  return (
    <>
      <div className="custom-tab">
        <div className="custom-tab-header">
          {tabs.map((tab, index) => (
            <div
              key={index}
              onClick={() => !tab.disabled && setActiveTab(index.toString())}
              className={`custom-tab-item ${
                activeTab === index.toString() ? "active" : ""
              } ${tab.disabled ? "opacity-50" : ""}`}
            >
              {tab.label}
            </div>
          ))}
        </div>
        <hr className="m-0"/>

        <div className="custom-tab-content">
          <TabContent activeTab={activeTab}>
            {tabs.map((tab, index) => (
              <TabPane key={index} tabId={index.toString()}>
                {tab.element}
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    </>
  );
};
