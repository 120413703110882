import { useNavigate } from "react-router-dom";
import { CustomTable, PaginationMeta } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { ListPrescriptionItemTable } from "./prescription.container";

export interface ListPrescriptionsTableProps {
  prescriptions: ListPrescriptionItemTable[];
  meta: PaginationMeta | null;
  onSelectPrescription: (id: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const ListPrescriptionsTable = ({
  prescriptions,
  onSelectPrescription,
  onChangePage,
  tableType,
  setTableType,
  meta,
}: ListPrescriptionsTableProps) => {
  const navigate = useNavigate();
  const columnNames = {
    patient_name: "Nome Paciente",
    tags: "Tipos de prescrição",
    is_signed: "Assinado Digitalmente",
    created_at: "Emissão",
  };

  return (
    <>
      {prescriptions !== null ? (
        <>
          <CustomTable
            columnNames={columnNames}
            data={prescriptions}
            onSelect={onSelectPrescription}
            tableType={tableType}
            setTableType={setTableType}
            btnLabelNotFound="+ Iniciar Prescrição"
            btnClickNotFound={() => navigate("/painel/pacientes")}
          />
          <CustomPagination
            {...Object.assign({}, meta)}
            onChangePage={onChangePage}
          />{" "}
        </>
      ) : (
        <> </>
      )}
    </>
  );
};
