import { action } from "mobx";
import { PatientDataFormValues } from "views/painel/patients/common/form/form-patient-data";
import { PatientAddressFormValues } from "views/painel/patients/common/form/form-patient-address";
import { CreatePatientState } from "../create-patient-state";

export const SetCreatePatientData = action(
  ({
    name,
    phone,
    birthdate,
    email,
    sex,
    document_number,
  }: PatientDataFormValues) => {
    CreatePatientState.data = {
      name,
      phone,
      birthdate,
      email,
      sex,
      document_number,
    };
  }
);

export const SetCreatePatientAddress = action(
  ({
    street,
    number,
    complement,
    neighborhood,
    city,
    state,
    zip_code,
  }: PatientAddressFormValues) => {
    CreatePatientState.address = {
      street,
      number,
      complement,
      neighborhood,
      city,
      state,
      zip_code,
    };
  }
);
