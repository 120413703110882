import { action } from "mobx";

import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { MeService, UpdateDataProps } from "services/me-service";

export const actionUpdateMeData = action(
  async (input: UpdateDataProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await MeService.updateData(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
