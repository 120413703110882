import React from "react";
import { FormGroup, Label } from "reactstrap";
import AsyncSelect from "react-select/async";
import { debounce } from "lodash";
import { actionFetchAllDoctors } from "actions/users/action-fetchall-doctors";
import { customStyles } from "components/form/input/helpers/react-select-styles";

export interface ScheduleSelectDoctorProps {
  value: number;
  label: string;
}

interface SelectDoctorFormProps {
  selectedDoctor?: any;
  setSelectedDoctor?: any;
  isReadOnly?: boolean;
  label?: string;
}
export const ScheduleSelectDoctor: React.FC<SelectDoctorFormProps> = ({
  selectedDoctor,
  setSelectedDoctor,
  isReadOnly,
  label,
}: SelectDoctorFormProps) => {
  const loadDoctors = debounce(async (inputValue: string, callback: any) => {
    try {
      const response = await actionFetchAllDoctors({
        name: inputValue,
        limit: 10,
        page: 1,
      });

      const options = response.items.map((user: any) => ({
        value: user.id,
        label: user.name,
      }));

      callback(options);
    } catch (error) {
      console.error("Error fetching users:", error);
      callback([]);
    }
  }, 700);

  return (
    <>
      <FormGroup className="col-12 px-0">
        <Label htmlFor="name">
          {label ? label : `Deseja visualizar agenda de um médico ?`}
        </Label>
        <AsyncSelect
          cacheOptions
          defaultOptions
          name="doctor"
          id="doctor"
          isClearable
          className="border-0"
          placeholder="Selecione um médico"
          value={selectedDoctor}
          loadOptions={loadDoctors as any}
          onChange={setSelectedDoctor}
          isDisabled={isReadOnly}
          styles={customStyles}
        />
      </FormGroup>
    </>
  );
};
