import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { enumIterator } from "helpers/typescript/enum-iterator";
import {
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { customStyles } from "./helpers/react-select-styles";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectScheduleTypeFormProps {
  isReadOnly?: boolean;
}
export const SelectScheduleTypeForm: React.FC<SelectScheduleTypeFormProps> = ({
  isReadOnly,
}: SelectScheduleTypeFormProps) => {
  const { setFieldValue, errors, values } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue("type", undefined);
    setFieldValue("type", selectedOption);
  };

  const optionsScheduleType = enumIterator(ScheduleTypeEnum).map((key) => ({
    value: ScheduleTypeEnum[key],
    label: ScheduleTypeLabels(
      ScheduleTypeEnum[key] as unknown as ScheduleTypeEnum
    ),
  }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor="email">Tipo de agendamento</Label>
      <Field
        name="type"
        id="type"
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.type}
        placeholder="Tipo de agendamento"
        options={optionsScheduleType}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
        styles={customStyles}
      />
      {(errors as any)?.type && (
        <div className="custom-invalid-feedback">
          Tipo de agendamento inválido
        </div>
      )}
    </FormGroup>
  );
};
