import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  CreatePatientProps,
  CreatePatientResponse,
  PatientService,
} from "services/patient-service";

export const actionCreatePatient = action(
  async (input: CreatePatientProps): Promise<CreatePatientResponse> => {
    SetIsLoadingState(true);
    try {
      const result = await PatientService.createPatient(input);
      return result;
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
