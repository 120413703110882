import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  EletronicPrescriptionService,
  ListAllEletronicPrescriptionRequest,
  ListAllEletronicPrescriptionResponse,
} from "services/eletronic-prescription-service";

export const actionListAllPrescriptions = action(
  async (
    input: ListAllEletronicPrescriptionRequest
  ): Promise<ListAllEletronicPrescriptionResponse> => {
    SetIsLoadingState(true);
    try {
      return await EletronicPrescriptionService.listAllPrescriptions(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
