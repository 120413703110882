import { useEffect, useState } from "react";

import { FormTenantData } from "./form/form-tenant-data";
import { actionListTenantData } from "actions/tenant/action-list-tenant-data";

export const TenantDataTab: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({
    name: "",
    logo: "",
    phone: "",
    email: "",
    zip_code: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    state: "",
    city: "",
  });

  const getServiceLocation = async () => {
    const response = await actionListTenantData();

    setData({
      name: response.name,
      logo: response.logo,
      email: response.email,
      phone: response.phone,
      zip_code: response.address.zip_code,
      street: response.address.street,
      number: response.address.number,
      complement: response.address.complement,
      neighborhood: response.address.neighborhood,
      state: response.address.state,
      city: response.address.city,
    });
    setIsLoaded(true);
  };

  useEffect(() => {
    (async () => {
      await getServiceLocation();
    })();
  }, []);

  return <>{isLoaded && <FormTenantData data={data} />}</>;
};
