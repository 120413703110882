import { action } from "mobx";

import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  MedicalRecordService,
  UploadAttachmentResponse,
} from "services/medical-record-service";

export const actionUploadFile = action(
  async (input: any): Promise<UploadAttachmentResponse> => {
    SetIsLoadingState(true);
    try {
      return await MedicalRecordService.upload(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
