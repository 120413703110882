import { action } from "mobx";

import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { ListTenantDataResponse, TenantService } from "services/tenant-service";

export const actionListTenantData = action(
  async (): Promise<ListTenantDataResponse> => {
    SetIsLoadingState(true);
    try {
      return await TenantService.listTenantData();
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
