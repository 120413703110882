import { format } from "date-fns";
import { ScheduleEnumsIcons } from "../common/schedule-enums-view";
import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";
export const CustomCalendarEvent = (event: any) => {
  const formattedStartTime = format(event.event.start, "HH:mm");
  const formattedEndTime = format(event.event.end, "HH:mm");

  return (
    <>
      <div className="my-1">
        <span>
          <i
            className={
              ScheduleEnumsIcons[event.event.status as ScheduleStatusEnum]
            }
            aria-hidden="true"
          />
        </span>
        <span>  </span>
        {`${formattedStartTime} - ${formattedEndTime}`}
      </div>
      <div className="my-2">{event.event?.patient?.name}</div>
    </>
  );
};
