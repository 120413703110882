import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

import { Address } from "domain/address/entities/address";
import { AddressService } from "services/address-service";

export const actionFetchAddress = action(
  async (cep: string): Promise<Address> => {
    SetIsLoadingState(true);
    try {
      const result = await AddressService.fetchAddressByCEP(cep);
      return result;
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
