import { observer } from "mobx-react";
import _ from "lodash";

import { useNavigate } from "react-router-dom";
import {
  FormReceptionistData,
  ReceptionistDataProps,
} from "views/painel/employees/common/receptionist/form/form-receptionist-data";
import { actionCreateReceptionist } from "actions/users/action-create-receptionist";
import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { successMessage } from "helpers/toast";

export const CreateReceptionist: React.FC = observer(() => {
  const navigate = useNavigate();

  const receptionistData: ReceptionistDataProps = {
    name: "",
    document_number: "",
    email: "",
    phone: "",
    status: UserStatusEnum.ACTIVE,
  };

  const onSubmitData = async (values: ReceptionistDataProps) => {
    try {
      const input = {
        ...values,
        document_number:
          values?.document_number?.replace(/[^0-9]/g, "") ?? null,
      };
      await actionCreateReceptionist(input);
      successMessage("Recepcionista criada com sucesso");
      navigate(`/painel/colaboradores`);
    } catch {}
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          <FormReceptionistData
            receptionistData={receptionistData}
            onSubmit={onSubmitData}
            onCancel={() => navigate(`/painel/colaboradores`)}
          />
        </div>
      </div>
    </>
  );
});
