import { FieldProps } from "formik";
import React, { useState } from "react";
import { InputGroup, Input, InputGroupText } from "reactstrap";

interface PasswordInputProps extends FieldProps {
  label: string;
  leftIconClass?: string;
}

export const CustomPasswordInput: React.FC<PasswordInputProps> = ({
  field,
  form,
  label,
  leftIconClass,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const { name } = field;
  const errorMessage = form.touched[name] && form.errors[name];

  return (
    <>
      <InputGroup>
        {leftIconClass && (
          <InputGroupText
            className={`text-secondary bg-white ${
              !!errorMessage ? "is-invalid" : ""
            } }`}
          >
            <i className={leftIconClass}></i>
          </InputGroupText>
        )}
        <Input
          type={showPassword ? "text" : "password"}
          {...field}
          {...props}
          className={`border-right-0 ${leftIconClass ? "border-left-0" : ""}`}
          invalid={!!errorMessage}
        />
        <InputGroupText
          className={`text-secondary bg-white ${
            !!errorMessage ? "is-invalid" : ""
          } }`}
          onClick={toggleShowPassword}
        >
          <i
            className={`text-primary fas ${
              showPassword ? "fa-eye-slash" : "fa-eye"
            }`}
          ></i>
        </InputGroupText>
      </InputGroup>
      {!!errorMessage && (
        <div className="custom-invalid-feedback">{errorMessage}</div>
      )}
    </>
  );
};
