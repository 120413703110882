import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  EletronicPrescriptionService,
  ListEletronicPrescriptionItemResponse,
} from "services/eletronic-prescription-service";

export const actionListPrescription = action(
  async (id: number): Promise<ListEletronicPrescriptionItemResponse> => {
    SetIsLoadingState(true);
    try {
      return await EletronicPrescriptionService.listPrescription(id);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
