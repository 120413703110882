import { FormGroup, Input, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import { observer } from "mobx-react";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";

import medicalRecordAnamnesisStore from "state/medical-record/medical-record-anamnesis-store";
import QuillEditor from "components/form/input/quill-editor-input";

interface AnamnesisFormProps {
  data?: any;
  onSubmit?: (values: any) => void;
}

export const AnamnesisForm: React.FC<AnamnesisFormProps> = observer(
  ({ data, onSubmit }) => {
    const validationSchema = Yup.object().shape({});

    return (
      <>
        <Formik
          initialValues={medicalRecordAnamnesisStore.getFormData()}
          validationSchema={validationSchema}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ handleChange }) => (
            <Form>
              <FormGroup className="col-12 px-0 mt-4">
                <Label htmlFor="main_complaint">Queixa principal</Label>
                <Field
                  name="main_complaint"
                  id="main_complaint"
                  placeholder="Digite Queixa principal"
                  theme="snow"
                  component={QuillEditor}
                  onChange={(value: string) => {
                    medicalRecordAnamnesisStore.setFormData(
                      "main_complaint",
                      value
                    );
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 px-0 mt-4">
                <Label htmlFor="current_illness_history">
                  História da doença atual
                </Label>
                <Field
                  name="current_illness_history"
                  id="current_illness_history"
                  placeholder="Digite História da doença atual"
                  theme="snow"
                  component={QuillEditor}
                  onChange={(value: string) => {
                    medicalRecordAnamnesisStore.setFormData(
                      "current_illness_history",
                      value
                    );
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 px-0 mt-4">
                <Label htmlFor="medical_history">
                  Histórico e antecedentes
                </Label>
                <Field
                  name="medical_history"
                  id="medical_history"
                  placeholder="Digite  Histórico e antecedentes"
                  theme="snow"
                  component={QuillEditor}
                  onChange={(value: string) => {
                    medicalRecordAnamnesisStore.setFormData(
                      "medical_history",
                      value
                    );
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 px-0 mt-4">
                <Label htmlFor="physical_examination">Exame físico</Label>
                <Field
                  name="physical_examination"
                  id="physical_examination"
                  placeholder="Digite Exame físico"
                  theme="snow"
                  component={QuillEditor}
                  onChange={(value: string) => {
                    medicalRecordAnamnesisStore.setFormData(
                      "physical_examination",
                      value
                    );
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 px-0 mt-4">
                <Label htmlFor="diagnosis">Diagnóstico</Label>
                <Field
                  name="diagnosis"
                  id="diagnosis"
                  placeholder="Digite Diagnóstico"
                  theme="snow"
                  component={QuillEditor}
                  onChange={(value: string) => {
                    medicalRecordAnamnesisStore.setFormData("diagnosis", value);
                  }}
                />
              </FormGroup>

              <FormGroup className="col-12 px-0 mt-4">
                <Label htmlFor="treatment_plan">Condutas</Label>
                <Field
                  name="treatment_plan"
                  id="treatment_plan"
                  placeholder="Digite as Condutas"
                  theme="snow"
                  component={QuillEditor}
                  onChange={(value: string) => {
                    medicalRecordAnamnesisStore.setFormData(
                      "treatment_plan",
                      value
                    );
                  }}
                />
              </FormGroup>
            </Form>
          )}
        </Formik>
      </>
    );
  }
);
