import React from "react";
import { FieldProps } from "formik";
import ReactQuill from "react-quill";

interface QuillEditorProps extends FieldProps {
  placeholder: string;
  theme: string;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ field, form, ...props }) => {
  return (
    <ReactQuill
      value={field.value}
      onChange={(value) => form.setFieldValue(field.name, value)}
      {...props}
    />
  );
};

export default QuillEditor;
