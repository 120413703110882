import { makeAutoObservable } from "mobx";
import { UserSexEnum } from 'domain/user/entities/enums/user-sex.enum';

export class CreatePatientStore {
  patientData = {
    name: "",
    phone: "",
    birthdate: "",
    email: "",
    sex: UserSexEnum.NEUTRAL,
    document_number: "",
  };

  patientAddress = {
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    zip_code: "",
  };

  constructor() {
    makeAutoObservable(this);
  }
}
