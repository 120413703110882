import { useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import { ListDoctorsTable } from "views/painel/employees/index/list-doctors/list-doctors-table";
import { actionFetchAllDoctors } from "actions/users/action-fetchall-doctors";
import { dateFormatter } from "helpers/date-formatter";
import { PaginationMeta } from "components/table/custom-table";

export interface ListDoctorItemTable {
  id: string;
  document_number: any;
  name: any;
  created_at: any;
  actions: any;
}

export const ListAllDoctors: React.FC = () => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();

  const [doctors, setDoctors] = useState<ListDoctorItemTable[]>([]);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [inputSearch, setInputSearch] = useState("");
  const [tableType, setTableType] = useState<"card" | "table">("table");

  const redirectToEditDoctor = (id: string) => {
    navigate(`/painel/medico/editar/${id}`);
  };

  const redirectToViewDoctor = (id: string) => {
    navigate(`/painel/medico/${id}`);
  };

  const doctorItemActions = (id: string) => {
    return (
      <>
        <div className="d-flex flex-wrap">
          <div
            className="mx-2"
            onClick={() => redirectToEditDoctor(id)}
            id={`btn-edit-${id}`}
          >
            <i className="fas fa-pen-to-square text-warning fa-lg"></i>
          </div>
          <div className="mx-2" onClick={() => redirectToViewDoctor(id)}>
            <i className="fa-solid fa-eye text-primary "></i>
          </div>
        </div>
      </>
    );
  };
  const doctorItemDocumentNumber = (documentNumber: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToViewDoctor(id)}>
        <span>{documentNumber}</span>
      </div>
    );
  };

  const doctorItemName = (name: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToViewDoctor(id)}>
        <span>{name}</span>
      </div>
    );
  };

  const doctorCreatedAt = (createdAt: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToViewDoctor(id)}>
        <span>{dateFormatter(new Date(createdAt), "dd/MM/yyyy hh:mm")}</span>
      </div>
    );
  };

  const fetchDoctors = async (page?: number, input?: string) => {
    const result = await actionFetchAllDoctors({
      limit: PAGE_SIZE,
      page: page || currentPage,
      name: input || inputSearch,
    });

    const items = result.items.map((item) => ({
      id: item.id,
      document_number: doctorItemDocumentNumber(item.document_number, item.id),
      name: doctorItemName(item.name, item.id),
      created_at: doctorCreatedAt(item.created_at, item.id),
      actions: doctorItemActions(item.id),
    }));

    setDoctors(items);
    setMeta(result.meta);
  };
  useEffect(() => {
    (async () => {
      await fetchDoctors();
    })();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
    await fetchDoctors(1, inputSearch);
  };

  const onSelectDoctor = (_doctorId: string) => {};

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchDoctors(page);
  };

  return (
    <>
      <Row>
        <Col sm="12" md="6">
          <Form
            className="d-flex col-12 col-md-5 justify-content-start my-4"
            onSubmit={onSubmit}
          >
            <InputGroup>
              <Input
                type="text"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                className="border-right-0"
                placeholder="Buscar por nome"
              />
              <InputGroupText className="bg-white">
                <button type="submit" className="border-0 bg-white">
                  <i
                    className="fa fa-search text-primary fs-20"
                    aria-hidden="true"
                  ></i>
                </button>
              </InputGroupText>
            </InputGroup>
          </Form>
        </Col>

        <Col
          sm="12"
          md="6"
          className="d-flex align-self-center justify-content-end"
        >
          <Button
            color="primary"
            onClick={() => navigate("/painel/medico/criar")}
          >
            + Criar Médico
          </Button>
        </Col>
      </Row>

      <ListDoctorsTable
        doctors={doctors}
        meta={meta}
        onSelectDoctor={onSelectDoctor}
        onChangePage={onChangePage}
        tableType={tableType}
        setTableType={setTableType}
      />
    </>
  );
};
