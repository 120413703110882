import { PrescriptionCategoryEnum } from "domain/eletronic-prescription/entities/enums/prescription-category.enum";
import { ListEletronicPrescriptionItemResponse } from "services/eletronic-prescription-service";
import { actionGetLinkDownloadPrescription } from "actions/eletronic-prescription/action-get-link-download-prescription";
import { handleDownloadFile } from "../../components/handle-download-file";

type PrescriptionTabProps = Pick<
  ListEletronicPrescriptionItemResponse,
  "id" | "prescriptions" | "documents"
>;

export const PrescriptionTab: React.FC<PrescriptionTabProps> = ({
  id,
  prescriptions,
  documents,
}) => {
  const prescriptionDocuments = documents.filter(
    (document) => document.category === PrescriptionCategoryEnum.PRESCRIPTION
  );

  const handleDownload = async (idDocument: string) => {
    try {
      const url = await actionGetLinkDownloadPrescription(
        String(id),
        idDocument
      );

      handleDownloadFile(url, "Receita.pdf");
    } catch (error) {}
  };

  return (
    <div>
      {prescriptions.medicines.map((medicine, index) => (
        <div className="my-3" key={index}>
          <div className="font-weight-500 text-primary fs-17 my-1">
            {medicine.title}
          </div>
          <div className="text-black-700 font-weight-500 my-1">
            {medicine.description}
          </div>
          <div className="text-black-500 font-weight-400">
            {medicine.quantity}
          </div>
        </div>
      ))}

      {prescriptions.manualMedicines.map((medicine, index) => (
        <div key={index}>
          <h1>{medicine.title}</h1>
          <p>{medicine.description}</p>
        </div>
      ))}

      {prescriptionDocuments.map((document, index) => (
        <div key={index}>
          <div
            className="d-flex flex-wrap align-items-center py-2"
            style={{ backgroundColor: "#f5f7fe" }}
          >
            <div>
              <img
                src="https://app.g3med.com/pdf-64.png"
                height="45px"
                alt="pdf"
              />
            </div>
            <div className="font-weight-500 text-black-600 mx-3">
              Receita.pdf
            </div>
            <div
              className="btn-secondary px-3 py-1 rounded-050"
              onClick={() => handleDownload(document.id)}
            >
              <i className="fa fa-download" aria-hidden="true"></i> Download
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
