import React from "react";
import * as Yup from "yup";
import { FormGroup, Label } from "reactstrap";
import ReactQuill from "react-quill";
import { SectionLine } from "components/common/section-line/section-line";
import { AttachmentTypeEnum } from "domain/attachments/entities/enums/attachment-type";
import { InputFileUpload } from "components/form/input/input-file-upload/input-file-upload";
import { Field, Formik, Form } from "formik";
import QuillEditor from "components/form/input/quill-editor-input";
import { observer } from "mobx-react";
import medicalRecordComplementaryExamsStore from "state/medical-record/medical-record-complementary-exams-store";

export const ComplementaryExamsForm: React.FC = observer(() => {
  const handleAddExam = () => {
    medicalRecordComplementaryExamsStore.addExam();
  };

  const handleExamChange = (
    index: number,
    field: "title" | "description",
    value: string
  ) => {
    medicalRecordComplementaryExamsStore.setExamInfo(index, field, value);
  };

  const handleRemoveExam = (index: number) => {
    medicalRecordComplementaryExamsStore.removeExam(index);
  };

  const handleFileUpload = (index: number, files: any) => {
    medicalRecordComplementaryExamsStore.setExamFiles(index, files);
  };

  const AddExame = () => {
    return (
      <div className="d-flex justify-content-end mt-3">
        <span
          className="text-primary fs-17 font-weight-400"
          onClick={handleAddExam}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-plus-circle mr-2" /> Adicionar exame
        </span>
      </div>
    );
  };

  const RemoveExam: React.FC<{ index: number }> = ({ index }) => {
    return (
      <div className="d-flex justify-content-end mt-3">
        <span
          className="text-danger fs-17 font-weight-400"
          onClick={() => handleRemoveExam(index)}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-plus-circle mr-2" /> Remover exame
        </span>
      </div>
    );
  };
  const validationSchema = Yup.object().shape({});
  const handleSubmit = (values: any) => {};

  return (
    <>
      <Formik
        initialValues={medicalRecordComplementaryExamsStore.getFormData()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleChange }) => (
          <Form>
            <div className="exam-item-list">
              {medicalRecordComplementaryExamsStore
                .getFormData()
                .map((exam, index) => (
                  <>
                    <RemoveExam index={index} />
                    <div key={index} className="exam-item-form">
                      <FormGroup className="col-12 px-0">
                        <Label htmlFor={`name_${index}`}>Título do Exame</Label>
                        <Field
                          name="title"
                          placeholder="Digite título do exame"
                          theme="snow"
                          value={exam.title}
                          component={QuillEditor}
                          onChange={(value: string) => {
                            handleExamChange(index, "title", value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="col-12 px-0 mt-4">
                        <Label htmlFor={`description_${index}`}>
                          Descrição
                        </Label>
                        <ReactQuill
                          id={`description_${index}`}
                          placeholder="Digite descrição do exame"
                          value={exam.description}
                          onChange={(value) =>
                            handleExamChange(index, "description", value)
                          }
                        />
                      </FormGroup>
                      <InputFileUpload
                        uploadedFiles={exam.attachments}
                        setUploadedFiles={(files) =>
                          handleFileUpload(index, files)
                        }
                        type={AttachmentTypeEnum.MEDICAL_RECORD_EXAM}
                      />
                    </div>

                    <div className="pt-3">
                      <SectionLine title="Exames adicionados" />
                    </div>
                  </>
                ))}
              <AddExame />
            </div>
          </Form>
        )}
      </Formik>
      {/* Workaround to Work */}
      <div className="d-none">
        {JSON.stringify(medicalRecordComplementaryExamsStore)}
      </div>
    </>
  );
});
