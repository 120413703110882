import React from "react";
import { routes, RouteProps } from "routes";
import { Route, Routes, Navigate } from "react-router-dom";
import { Container } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";

import "./auth-layout.style.css";
import LoadingSpinner from "components/spinner/loading-spinner";
import { SpinnerState } from "state/spinner/spinner-state";
import DefaultFooter from "components/footers/default-footer/default-footer";

const AuthLayout: React.FC = observer((props) => {
  const { isLoading } = SpinnerState;
  const mainContent = React.useRef(null);

  const getRoutes = (routes: RouteProps[]) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={<prop.component />} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div
        className="d-flex min-vh-100 bg-primary-secondary justify-content-center"
        ref={mainContent}
      >
        {isLoading ? <LoadingSpinner /> : <></>}
        <ToastContainer />
        <Container className="col-md-12 col mx-0 p-0 row justify-content-center align-self-center main-content">
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
          </Routes>
          <Container fluid>
          <DefaultFooter style="my-4 text-center fs-14 text-white" />
        </Container>
        </Container>
   
      </div>
    </>
  );
});

export default AuthLayout;
