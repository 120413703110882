import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import { errorMessage, successMessage } from "helpers/toast";
import {
  DoctorDataProps,
  FormDoctorData,
} from "views/painel/employees/common/doctor/form/form-doctor-data";
import { actionFetchDoctor } from "actions/users/action-fetch-doctor";
import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";
import { actionUpdateDoctor } from "actions/users/action-update-doctor";
import { onlyNumbers } from "helpers/only-numbers";

export const UpdateDoctor: React.FC = () => {
  const navigate = useNavigate();
  let { id } = useParams();

  const [isLoaded, setIsLoaded] = useState(false);

  const [doctorData, setDoctorData] = useState<DoctorDataProps>({
    id: undefined,
    email: "",
    name: "",
    document_number: "",
    phone: "",
    status: UserStatusEnum.ACTIVE,
    profession: ProfessionTypeEnum.DOCTOR,
    register_number: [
      {
        register_number: "",
        register_number_uf: "",
        is_selected: true,
      },
    ],
  });

  const fetchDoctorData = async () => {
    if (id) {
      const response = await actionFetchDoctor(id);
      setDoctorData({
        ...response,
        document_number: response?.document_number?.toString() ?? "",
      });
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetchDoctorData();
      } catch {
        navigate(`/painel/colaboradores`);
      }
    })();
  }, []);

  const onSubmitData = async (values: DoctorDataProps) => {
    try {
      if (id) {
        const input = {
          ...values,
          document_number: onlyNumbers(values?.document_number ?? "") ?? null,
          status: (values.status ?? UserStatusEnum.ACTIVE) as UserStatusEnum,
        };
        await actionUpdateDoctor(id, input);
        successMessage("Médico atualizado com sucesso");
        navigate(`/painel/colaboradores`);
      } else {
        errorMessage("Falha ao obter o id do colaborador");
      }
    } catch {}
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          {isLoaded && (
            <FormDoctorData
              doctorData={doctorData}
              onSubmit={onSubmitData}
              onCancel={() => navigate(`/painel/colaboradores`)}
            />
          )}
        </div>
      </div>
    </>
  );
};
