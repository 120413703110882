import * as Yup from "yup";
import { Field, Form, Formik, useFormikContext } from "formik";
import { Button, FormGroup, Label, Row } from "reactstrap";

import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { SetCreatePatientAddress } from "state/patient/create/actions/set-create-patient";
import { actionFetchAddress } from "actions/address/action-fetch-address";
import { getCityByUf } from "helpers/cities-list";
import { SelectUfForm } from "components/form/input/select-uf-form";

export interface FormPatientDataProps {
  patientAddress: PatientAddressFormProps;
  onSubmit: (patientAddress: PatientAddressFormProps) => void;
  onBack: () => void;
  isReadOnly?: boolean;
}

export interface PatientAddressFormProps {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string;
}
interface StateFormValue {
  value: string;
  label: string;
}

export interface PatientAddressFormValues {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string | StateFormValue;
  zip_code: string;
}

export const FormPatientAddress: React.FC<FormPatientDataProps> = ({
  patientAddress,
  onBack,
  onSubmit,
  isReadOnly,
}) => {
  const validationSchema = Yup.object().shape({});

  const initialValues: PatientAddressFormValues = {
    street: patientAddress.street,
    number: patientAddress.number,
    complement: patientAddress.complement,
    neighborhood: patientAddress.neighborhood,
    city: patientAddress.city,
    state: patientAddress.state ? getCityByUf(patientAddress.state) ?? "" : "",
    zip_code: patientAddress.zip_code,
  };

  const handleSubmit = async (values: PatientAddressFormValues) => {
    SetCreatePatientAddress(values);
    return onSubmit({
      ...values,
      state: values.state ? (values.state as StateFormValue)?.value : "",
    });
  };

  const ZipCodeForm: React.FC = () => {
    const { setFieldValue } = useFormikContext<PatientAddressFormValues>();

    const handleZipCodeChange = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const zipCode = e.target.value.replace(/\D/g, "");

      setFieldValue("zip_code", zipCode);

      if (zipCode.length === 8) {
        try {
          const result = await actionFetchAddress(zipCode);
          const state = getCityByUf(result.state);

          setFieldValue("street", result.street);
          setFieldValue("neighborhood", result.neighborhood);
          setFieldValue("city", result.city);
          setFieldValue("state", state);
        } catch {}
      }
    };

    return (
      <Field
        id="zip_code"
        name="zip_code"
        type="text"
        placeholder="00000-000"
        mask="00000-000"
        onChange={handleZipCodeChange}
        component={CustomMaskInput}
        disabled={isReadOnly}
      />
    );
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                <Label htmlFor="name">CEP</Label>
                <ZipCodeForm />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 px-0 ps-md-2">
                <Label htmlFor="street">Logradouro</Label>
                <Field
                  name="street"
                  id="street"
                  placeholder="Rua"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                <Label htmlFor="complement">Complemento</Label>
                <Field
                  name="complement"
                  id="complement"
                  placeholder="Complemento"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 px-0 ps-md-2">
                <Label htmlFor="email">Número</Label>
                <Field
                  name="number"
                  id="number"
                  placeholder="Número"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-3 px-0 pe-md-2">
                <SelectUfForm keyName="state" isReadOnly={isReadOnly} />
              </FormGroup>
              <FormGroup className="col-12 col-md-3 px-0 pe-md-2 ps-md-2">
                <Label htmlFor="city">Cidade</Label>
                <Field
                  name="city"
                  id="city"
                  placeholder="Cidade"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 px-0 ps-md-2">
                <Label htmlFor="email">Bairro</Label>
                <Field
                  name="neighborhood"
                  id="neighborhood"
                  placeholder="Bairro"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>

            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <Row className="d-flex col-12 col-md-3 mx-0">
                <Button
                  color="secondary"
                  className="form-control"
                  onClick={onBack}
                >
                  Voltar
                </Button>
              </Row>
              {!isReadOnly && (
                <Row className="d-flex col-12 col-md-3 px-0 mx-0">
                  <Button
                    type="submit"
                    color="primary"
                    className="form-control"
                  >
                    Salvar
                  </Button>
                </Row>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
