import { observer } from "mobx-react";
import { useState } from "react";
import _ from "lodash";

import { CustomTab } from "components/tab/custom-tab";
import { ListAllDoctors } from "views/painel/employees/index/list-doctors/list-doctors";
import { ListAllReceptionists } from "views/painel/employees/index/list-receptionists/list-receptionists";

const Employees: React.FC = observer(() => {
  const [activeTab, setActiveTab] = useState("0");

  const listTabs = [
    {
      label: "Médicos",
      disabled: false,
      element: <ListAllDoctors />,
    },
    {
      label: "Recepcionistas",
      disabled: false,
      element: <ListAllReceptionists />,
    },
  ];
  // if (
  //   UserIdentity.hasRole([
  //     UserRoleEnum.ADMIN,
  //     UserRoleEnum.CLINIC_MANAGER,
  //     UserRoleEnum.OWNER_DOCTOR,
  //     UserRoleEnum.SUPERADMIN,
  //   ])
  // ) {
  //   listTabs.push({
  //     label: "Administradores",
  //     disabled: false,
  //     element: <ListAdmins />,
  //   });
  // }
  const [tabs, setTabs] = useState(listTabs);

  return (
    <>
      <div className="main-wrapper">
        <CustomTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
});
export default Employees;
