import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { CustomPasswordInput } from "components/form/input/custom-password-input";
import { CustomFormInput } from "components/form/input/custom-form-input";
import { CustomMaskInput } from "components/form/input/custom-mask-input";
import { OwnerTypeEnum } from "domain/tenants/entities/enums/owner-type.enum";
import { RegisterFormValues } from "views/auth/register/register";
import { isPhoneValid } from "helpers/phone-validator";
import { isPasswordValid } from "helpers/password-validator";
import { validateCPF } from "validators/validate-cpf";
import { SelectProfessionTypeForm } from "components/form/input/select-profession-type-form";
import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";

interface RegisterPFFormValues {
  tenant_name: string;
  user_name: string;
  document_number: string;
  phone: string;
  email: string;
  password: string;
  confirm_password: string;
  owner_type: OwnerTypeEnum;
  profession: {
    value: ProfessionTypeEnum;
    label: string;
  };
}

const validationSchema = Yup.object().shape({
  tenant_name: Yup.string().required("Nome do consultório é obrigatório"),
  user_name: Yup.string().required("Nome do médico é obrigatório"),
  document_number: Yup.string().test(
    "custom-validation",
    "CPF inválido",
    function (value) {
      return validateCPF(value ?? "");
    }
  ),
  phone: Yup.string().test("phone", "Telefone inválido", (value) => {
    return isPhoneValid(value ?? "");
  }),
  email: Yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
  password: Yup.string().test(
    "custom-validation",
    "Senha fraca",
    function (value) {
      return isPasswordValid(value ?? "");
    }
  ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "As senhas precisam ser iguais")
    .min(8, "Senha deve ter no mínimo 8 caracteres")
    .required("Confirmação de senha é obrigatória"),
  profession: Yup.object().test(
    "custom-validation-profession",
    "Conselho inválido",
    function (value: any) {
      return value?.value !== null && value?.value !== undefined;
    }
  ),
});

interface RegisterPFProps {
  onSubmit: (values: RegisterFormValues) => void;
}

const RegisterPF: React.FC<RegisterPFProps> = ({
  onSubmit,
}: RegisterPFProps) => {
  const initialValues: RegisterPFFormValues = {
    tenant_name: "",
    user_name: "",
    document_number: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
    profession: {
      value: null as unknown as ProfessionTypeEnum,
      label: "Selecione o conselho",
    },
    owner_type: OwnerTypeEnum.DOCTOR,
  };

  const handleSubmit = (
    values: RegisterPFFormValues,
    { setSubmitting }: any
  ) => {
    onSubmit({
      ...values,
      phone: `${values.phone.replace(/\D/g, "")}`,
      profession: values.profession.value,
    });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form>
          <Row className="d-flex col-12 mx-0">
            <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
              <Label htmlFor="tenant_name">Nome do Consultório</Label>
              <Field
                name="tenant_name"
                id="tenant_name"
                placeholder="Nome do Consultório"
                type="text"
                leftIconClass="fas fa-building text-primary"
                component={CustomFormInput}
              />
            </FormGroup>

            <FormGroup className="col-12 col-md-6 px-0 ps-md-2">
              <Label htmlFor="user_name">Nome do Médico</Label>
              <Field
                name="user_name"
                id="user_name"
                label="Nome do Médico"
                placeholder="Nome do Médico"
                type="text"
                leftIconClass="fas fa-user text-primary"
                component={CustomFormInput}
              />
            </FormGroup>
          </Row>

          <Row className="d-flex col-12 mx-0">
            <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
              <Label htmlFor="document_number">CPF</Label>
              <Field
                name="document_number"
                id="document_number"
                label="CPF"
                placeholder="Digite seu CPF"
                mask="000.000.000-00"
                type="text"
                leftIconClass="fas fa-id-card text-primary"
                component={CustomMaskInput}
              />
            </FormGroup>
            <FormGroup className="col-12 col-md-6 px-0 ps-md-2">
              <Label htmlFor="email">E-mail</Label>
              <Field
                name="email"
                id="email"
                label="E-mail"
                placeholder="Digite seu e-mail"
                type="email"
                leftIconClass="fas fa-envelope text-primary"
                component={CustomFormInput}
              />
            </FormGroup>
          </Row>

          <Row className="d-flex col-12 mx-0">
            <FormGroup className="col-12 col-md-6 px-0 pe-md-2 ">
              <Label htmlFor="email">Telefone</Label>
              <Field
                name="phone"
                id="phone"
                label="Telefone"
                placeholder="(00) 00000-0000"
                type="text"
                mask="(00) 00000-0000"
                leftIconClass="fas fa-phone text-primary"
                component={CustomMaskInput}
              />
            </FormGroup>
            <FormGroup className="col-12 col-md-6 px-0 ps-md-2">
              <SelectProfessionTypeForm />
            </FormGroup>
          </Row>
          <Row className="d-flex col-12 mx-0 d-flex flex-wrap">
            <div className="d-flex col-12 col-md-6 fs-14 flex-wrap align-items-center">
              <ul>
                <li className="fw-bold">Requisitos da senha:</li>
                <li>No mínimo 8 caracteres</li>
                <li>Pelo menos 1 caractere maiúsculo</li>
                <li>Pelo menos 1 caractere minúsculo</li>
                <li>Pelo menos 1 número</li>
              </ul>
            </div>

            <div className="d-flex col-12 col-md-6 px-0 flex-wrap">
              <FormGroup className="col-12 px-0 ps-md-2">
                <Label htmlFor="password">Senha</Label>
                <Field
                  name="password"
                  id="password"
                  label="Senha"
                  placeholder="Digite senha"
                  leftIconClass="fas fa-lock text-primary"
                  component={CustomPasswordInput}
                />
              </FormGroup>

              <FormGroup className="col-12 ps-md-2">
                <Label htmlFor="confirm_password">Confirmar Senha</Label>
                <Field
                  name="confirm_password"
                  id="confirm_password"
                  label="Confirmar Senha"
                  placeholder="Confirmar senha"
                  leftIconClass="fas fa-lock text-primary"
                  component={CustomPasswordInput}
                />
              </FormGroup>
            </div>
          </Row>

          <Row className="d-flex col-12 mx-0 mt-2">
            <Button type="submit" color="primary" className="form-control">
              Cadastrar
            </Button>
          </Row>

          <Row className="text-center  mt-4 px-1">
            <Col sm="12" className="d-flex justify-content-end">
              <a href="/auth/login" className="text-primary fs-17">
                <i className="fas fa-user-doctor"></i> Já possui conta ? Faça
                login
              </a>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterPF;
