import { useNavigate } from "react-router-dom";

import { UserStatusEnum } from "domain/user/entities/enums/user-status.enum";
import {
  DoctorDataProps,
  FormDoctorData,
} from "views/painel/employees/common/doctor/form/form-doctor-data";
import { ProfessionTypeEnum } from "domain/user/entities/enums/profession-type.enum";
import { actionCreateDoctor } from "actions/users/action-create-doctor";
import { successMessage } from "helpers/toast";

export const CreateDoctor: React.FC = () => {
  const navigate = useNavigate();

  const doctorData: DoctorDataProps = {
    email: "",
    name: "",
    document_number: "",
    phone: "",
    status: UserStatusEnum.ACTIVE,
    profession: ProfessionTypeEnum.DOCTOR,
    register_number: [
      {
        register_number: "",
        register_number_uf: "",
        is_selected: true,
      },
    ],
  };

  const onSubmitData = async (values: DoctorDataProps) => {
    try {
      const input = {
        ...values,
        document_number:
          values?.document_number?.replace(/[^0-9]/g, "") ?? null,
        status: values.status,
        profession: values.profession,
        register_number: values.register_number,
      };
      await actionCreateDoctor(input);
      successMessage("Médico criado com sucesso");
      navigate(`/painel/colaboradores`);
    } catch {}
  };

  return (
    <>
      <div className="main-wrapper">
        <div className="mt-3">
          <FormDoctorData
            doctorData={doctorData}
            onSubmit={onSubmitData}
            onCancel={() => navigate(`/painel/colaboradores`)}
          />
        </div>
      </div>
    </>
  );
};
