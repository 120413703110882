import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListPatientsProps,
  ListPatientsResponse,
  PatientService,
} from "services/patient-service";

export const actionFetchAllPatients = action(
  async (input: ListPatientsProps): Promise<ListPatientsResponse> => {
    SetIsLoadingState(true);
    const result = await PatientService.fetchAllPatients(input);
    SetIsLoadingState(false);
    return result;
  }
);
