import { Attachment } from "services/medical-record-service";

export interface MedicalRecordAttachmentsItemProps {
  attachments: Attachment[] | null;
}
export const MedicalRecordAttachmentsItem: React.FC<
  MedicalRecordAttachmentsItemProps
> = ({ attachments }) => {
  return (
    <>
      <div className="d-flex row">
        <h4 className="text-primary flex-wrap">Anexos</h4>
        <br />
        <div className="d-flex flex-wrap">
          {attachments?.map((attachment, index) => (
            <div key={`attachment-${index}`} className="d-flex ">
              <div
                className="g3-preview-img"
                style={{
                  backgroundImage: `url(${attachment.thumbnail})`,
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
