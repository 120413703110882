import { makeAutoObservable } from "mobx";

interface FormData {
  title: string;
  description: string;
  attachments: [];
}

type FormDataExamInfo = Omit<FormData, "attachments">;

class MedicalRecordComplementaryExamsStore {
  formData: FormData[] = [
    {
      title: "",
      description: "",
      attachments: [],
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  addExam() {
    this.formData.push({
      title: "",
      description: "",
      attachments: [],
    });
  }

  removeExam(index: number) {
    this.formData.splice(index, 1);
  }

  setExamInfo(index: number, field: keyof FormDataExamInfo, value: string) {
    this.formData[index][field] = value;
  }

  setExamFiles(index: number, files: []) {
    this.formData[index].attachments = files;
  }

  getFormData(): FormData[] {
    return this.formData;
  }

  isEmpty(): boolean {
    for (const exam of this.formData) {
      if (exam.title || exam.description || exam.attachments.length) {
        return false;
      }
    }
    return true;
  }

  cleanData(): void {
    this.formData = [
      {
        title: "",
        description: "",
        attachments: [],
      },
    ];
  }
}

const medicalRecordComplementaryExamsStore =
  new MedicalRecordComplementaryExamsStore();

export default medicalRecordComplementaryExamsStore;
