import React from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend
);

interface EvolutionScheduleDashboardProps {
  data: Record<string, number>;
}

export const EvolutionScheduleDashboard: React.FC<
  EvolutionScheduleDashboardProps
> = ({ data }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return data.formattedValue;
          },
        },
      },
      datalabels: {
        formatter: function (value: any) {
          return "";
        },
      },
    },
    scales: {
      x: {
        display: true,
      },
      y: {
        display: true,
      },
    },
  };

  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "teste",
        data: Object.values(data),
        borderWidth: 2,
        borderColor: "#1632D2",
        tension: 0.2,
      },
    ],
  };

  return (
    <>
      <Line data={chartData} options={options} />
    </>
  );
};



