import { action } from "mobx";

import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListMedicalRecordResponse,
  MedicalRecordService,
} from "services/medical-record-service";

export const actionFetchAllMedicalRecord = action(
  async (patientId: number): Promise<ListMedicalRecordResponse> => {
    SetIsLoadingState(true);
    const result = await MedicalRecordService.fetchAllMedicalRecord(patientId);
    SetIsLoadingState(false);
    return result;
  }
);
