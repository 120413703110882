export const dateFormatter = (
  date: any,
  format = "dd/MM/yyyy hh:mm"
): string => {
  if (!date) return "";
  var dt = typeof date === "string" ? new Date(date) : date;

  if (!(dt instanceof Date) || isNaN(dt.getTime())) {
    console.error(`A data ${dt} não é uma data válida.`);
    return "";
  }
  const o = {
    "M+": dt.getMonth() + 1, // month
    "d+": dt.getDate(), // day
    "h+": dt.getHours(), // hour
    "m+": dt.getMinutes(), // minute
    "s+": dt.getSeconds(), // second
    "q+": Math.floor((dt.getMonth() + 3) / 3), // quarter
    S: dt.getMilliseconds(), // millisecond
  };
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (dt.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    let v = o[k];
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? v : `00${o[k]}`.substr(("" + v).length)
      );
    }
  }
  return format;
};

export const getStartAndEndOfWeek = (date: string) => {
  // Copy the input date to avoid modifying it
  const startDate = new Date(date);
  const endDate = new Date(date);

  // Get the day of the week (0: Sunday, 1: Monday, ..., 6: Saturday)
  const dayOfWeek = startDate.getDay();

  // Calculate the difference between the input date's day of the week and Sunday (0)
  const diff = startDate.getDate() - dayOfWeek;

  // Set the start date to the previous Sunday
  startDate.setDate(diff);

  // Set the end date to the next Saturday
  endDate.setDate(diff + 6);

  // Return the start and end dates as an object
  return { startOfWeek: startDate, endOfWeek: endDate };
};

export const calculateAgeFromBirthdate = (
  date: Date | null | undefined
): string => {
  if (!date) return "";

  const birthDate = typeof date === "string" ? new Date(date) : date;
  const today = new Date();

  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();
  let days = today.getDate() - birthDate.getDate();

  if (months < 0) {
    years--;
    months += 12;
  }

  if (days < 0) {
    months--;
    const lastMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    ).getDate();
    days += lastMonth;
  }

  return `${years} anos, ${months} meses e ${days} dias`;
};

//@FIXME fix the timezone
//Get automaticalyy the timezone from the user
export const utcToSaoPaulo = (date: string) => {
  // return date.replace("Z", "-03:00")
  return date;
};
