import { useNavigate } from "react-router-dom";
import { CustomTable, PaginationMeta } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { ListDoctorItemTable } from "./list-doctors";

export interface ListDoctorsTableProps {
  doctors: ListDoctorItemTable[];
  meta: PaginationMeta | null;
  onSelectDoctor: (doctorId: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const ListDoctorsTable = ({
  doctors,
  onSelectDoctor,
  onChangePage,
  tableType,
  setTableType,
  meta,
}: ListDoctorsTableProps) => {
  const navigate = useNavigate();
  const columnNames = {
    document_number: "CPF",
    name: "Nome",
    created_at: "Criado em",
    actions: "Ações",
  };

  return (
    <>
      {doctors !== null ? (
        <>
          <CustomTable
            columnNames={columnNames}
            data={doctors}
            onSelect={onSelectDoctor}
            tableType={tableType}
            setTableType={setTableType}
            btnLabelNotFound="+ Criar Médico"
            btnClickNotFound={() => navigate("/painel/medico/criar")}
          />
          <CustomPagination
            {...Object.assign({}, meta)}
            onChangePage={onChangePage}
          />{" "}
        </>
      ) : (
        <> </>
      )}
    </>
  );
};
