import axios from "axios";
import { Address } from "domain/address/entities/address";
import { FetchAddressMapper } from "./mappers/address/fetch-address-mapper";

export interface FetchAddressResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
}

export class AddressService {
  static async fetchAddressByCEP(cep: string): Promise<Address> {
    try {
      const result = (await axios.get(`https://viacep.com.br/ws/${cep}/json`))
        .data;
        
      if (result.erro) {
        throw new Error("Endereço não encontrado");
      }

      return FetchAddressMapper.mapToDomain(result);
    } catch (err) {
      throw new Error("Endereço não encontrado");
    }
  }
}
