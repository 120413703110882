export enum ProfessionTypeEnum {
  DOCTOR = 0,
  DENTIST = 1,
}

export const ProfessionTypeEnumLabels = (type: ProfessionTypeEnum) => {
  const labels = {
    [ProfessionTypeEnum.DOCTOR]: "Medicina",
    [ProfessionTypeEnum.DENTIST]: "Odontologia",
  };

  return labels[type];
};
