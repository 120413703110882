import { useEffect, useState } from "react";
import _ from "lodash";

import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import { ListReceptionistsTable } from "views/painel/employees/index/list-receptionists/list-receptionists-table";
import { ListReceptionistsResponse } from "services/user-service";
import { actionFetchAllReceptionists } from "actions/users/action-fetchall-receptionists";
import { dateFormatter } from "helpers/date-formatter";

export const ListAllReceptionists: React.FC = () => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();

  const [receptionists, setReceptionists] =
    useState<ListReceptionistsResponse | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputSearch, setInputSearch] = useState("");
  const [tableType, setTableType] = useState<"card" | "table">("table");

  const fetchReceptionists = async (page?: number, input?: string) => {
    const result = await actionFetchAllReceptionists({
      limit: PAGE_SIZE,
      page: page || currentPage,
      name: input || inputSearch,
    });

    const items = result.items.map((item) => ({
      ...item,
      created_at: dateFormatter(new Date(item.created_at), "dd/MM/yyyy hh:mm"),
    }));
    setReceptionists({ items, meta: result.meta });
  };
  useEffect(() => {
    (async () => {
      await fetchReceptionists();
    })();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
    await fetchReceptionists(1, inputSearch);
  };

  const onSelectReceptionist = (receptionistId: string) => {
    navigate(`/painel/recepcionista/editar/${receptionistId}`);
  };

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchReceptionists(page);
  };

  return (
    <>
      <Row>
        <Col sm="12" md="6">
          <Form
            className="d-flex col-12 col-md-5 justify-content-start my-4"
            onSubmit={onSubmit}
          >
            <InputGroup>
              <Input
                type="text"
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
                className="border-right-0"
                placeholder="Buscar por nome"
              />
              <InputGroupText className="bg-white">
                <button type="submit" className="border-0 bg-white">
                  <i
                    className="fa fa-search text-primary fs-20"
                    aria-hidden="true"
                  ></i>
                </button>
              </InputGroupText>
            </InputGroup>
          </Form>
        </Col>

        <Col
          sm="12"
          md="6"
          className="d-flex align-self-center justify-content-end"
        >
          <Button
            color="primary"
            onClick={() => navigate("/painel/recepcionista/criar")}
          >
            + Criar Recepcionista
          </Button>
        </Col>
      </Row>

      <ListReceptionistsTable
        receptionists={receptionists}
        onSelectReceptionist={onSelectReceptionist}
        onChangePage={onChangePage}
        tableType={tableType}
        setTableType={setTableType}
      />
    </>
  );
};
