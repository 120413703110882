import { action } from "mobx";

import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { TenantService, UpdateTenantDataProps } from "services/tenant-service";

export const actionUpdateTenantData = action(
  async (input: UpdateTenantDataProps): Promise<void> => {
    SetIsLoadingState(true);
    try {
      await TenantService.updateTenantData(input);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
