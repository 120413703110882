import { useEffect, useState } from "react";

import { ListPatientsTable } from "./list-patients-table";
import { actionFetchAllPatients } from "actions/patients/action-fetchall-patients";
import {
  Button,
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Tooltip,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "helpers/date-formatter";
import { maskedPhoneNumber } from "helpers/phone-formatter";
import { PaginationMeta } from "components/table/custom-table";
import UserIdentity from 'domain/user/entities/user-identity';
import { UserRoleEnum } from 'domain/user/entities/enums/user-role.enum';
import { errorMessage } from 'helpers/toast';

export interface ListPatientItemTable {
  id: string;
  name: any;
  birthdate: any;
  phone: any;
  action: any;
}

const Patients: React.FC = () => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();

  const [patients, setPatients] = useState<ListPatientItemTable[]>([]);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [inputSearch, setInputSearch] = useState("");
  const [tableType, setTableType] = useState<"card" | "table">("table");

  const [tooltipOpenEdit, setTooltipOpenEdit] = useState(false);

  const redirectToMedicalRecord = (id: string) => {
    if (!UserIdentity.hasRole([UserRoleEnum.OWNER_DOCTOR, UserRoleEnum.DOCTOR])) {
      errorMessage("Você não possui permissão para acessar o prontuário");
      return;
    }
    navigate(`/painel/prontuario/${id}`);
  };

  const redirectToEditPatient = (id: string) => {
    navigate(`/painel/paciente/editar/${id}`);
  };

  const redirectToViewPatient = (id: string) => {
    navigate(`/painel/paciente/${id}`);
  };

  const patientItemActions = (id: string) => {
    return (
      <>
        <div className="d-flex flex-wrap">
          <div
            className="mx-2"
            onClick={() => redirectToEditPatient(id)}
            id={`btn-edit-${id}`}
          >
            <i className="fas fa-pen-to-square text-warning fa-lg"></i>
            <Tooltip
              isOpen={tooltipOpenEdit}
              target={`btn-edit-${id}`}
              toggle={() => setTooltipOpenEdit(!tooltipOpenEdit)}
            >
              Editar paciente
            </Tooltip>
          </div>
          <div className="mx-2" onClick={() => redirectToViewPatient(id)}>
            <i className="fa-solid fa-eye text-primary "></i>
          </div>
        </div>
      </>
    );
  };
  const patientItemName = (name: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToMedicalRecord(id)}>
        <span>{name}</span>
      </div>
    );
  };

  const patientItemPhone = (phone: string, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToMedicalRecord(id)}>
        {!_.isEmpty(phone) ? (
          <span>{maskedPhoneNumber(phone)}</span>
        ) : (
          <span className="fs-14">Não informado</span>
        )}
      </div>
    );
  };

  const patientItemBirthdate = (birthdate: string | null, id: string) => {
    return (
      <div className="w-100" onClick={() => redirectToMedicalRecord(id)}>
        {!_.isEmpty(birthdate) ? (
          <span>{dateFormatter(birthdate, "dd/MM/yyyy")}</span>
        ) : (
          <span className="fs-14">Não informado</span>
        )}
      </div>
    );
  };
  
  const fetchPatients = async (page?: number, input?: string) => {
    const result = await actionFetchAllPatients({
      limit: PAGE_SIZE,
      page: page || currentPage,
      document_number: input || inputSearch,
      name: input || inputSearch,
    });

    const items = result.items.map((item): ListPatientItemTable => {
      return {
        id: item.id,
        name: patientItemName(item.name, item.id),
        birthdate: patientItemBirthdate(item.birthdate, item.id),
        phone: patientItemPhone(item.phone, item.id),
        action: patientItemActions(item.id),
      };
    });
    setPatients(items);
    setMeta(result.meta);
  };

  useEffect(() => {
    (async () => {
      await fetchPatients();
    })();
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setCurrentPage(1);
    await fetchPatients(1, inputSearch);
  };

  const onSelectPatient = (_patientId: string) => {};

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchPatients(page);
  };

  return (
    <>
      <div className="main-wrapper">
        <Row className="mb-3">
          <Col sm="12" md="6">
            <Form
              className="d-flex col-12 col-md-5 justify-content-start my-4"
              onSubmit={onSubmit}
            >
              <InputGroup>
                <Input
                  type="text"
                  value={inputSearch}
                  onChange={(e) => setInputSearch(e.target.value)}
                  className="border-right-0"
                  placeholder="Buscar por nome ou CPF"
                />
                <InputGroupText className="bg-white">
                  <button type="submit" className="border-0 bg-white">
                    <i
                      className="fa fa-search text-primary fs-20"
                      aria-hidden="true"
                    ></i>
                  </button>
                </InputGroupText>
              </InputGroup>
            </Form>
          </Col>

          <Col
            sm="12"
            md="6"
            className="d-flex align-self-center justify-content-end"
          >
            <Button
              color="primary"
              onClick={() => navigate("/painel/pacientes/criar")}
            >
              + Criar Paciente
            </Button>
          </Col>
        </Row>

        <ListPatientsTable
          patients={patients}
          meta={meta}
          onSelectPatient={onSelectPatient}
          onChangePage={onChangePage}
          tableType={tableType}
          setTableType={setTableType}
        />
      </div>
    </>
  );
};

export default Patients;
