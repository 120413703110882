export enum ScheduleOriginContactEnum {
  INTERNET = 1,
  HEALTH_INSURANCE = 2,
  RECOMMENDATION = 3,
  SOCIAL_MEDIA = 4,
  OTHERS = 5,
}

export const ScheduleOriginContactLabels = (
  scheduleType: ScheduleOriginContactEnum
) => {
  const labels = {
    [ScheduleOriginContactEnum.INTERNET]: "Internet",
    [ScheduleOriginContactEnum.HEALTH_INSURANCE]: "Plano de saúde",
    [ScheduleOriginContactEnum.RECOMMENDATION]: "Recomendação",
    [ScheduleOriginContactEnum.SOCIAL_MEDIA]: "Redes sociais",
    [ScheduleOriginContactEnum.OTHERS]: "Outros",
  };

  return labels[scheduleType];
};

export const ScheduleOriginContactColors = {
  [ScheduleOriginContactEnum.INTERNET]: {
    hex: "#518BD8",
    rgba: "rgba(81, 139, 216, 1)",
  },
  [ScheduleOriginContactEnum.HEALTH_INSURANCE]: {
    hex: "#62CDBB",
    rgba: "rgba(98, 205, 187, 1)",
  },
  [ScheduleOriginContactEnum.RECOMMENDATION]: {
    hex: "#DFDFDF",
    rgba: "rgba(223, 223, 223, 1)",
  },
  [ScheduleOriginContactEnum.SOCIAL_MEDIA]: {
    hex: "#434F87",
    rgba: "rgba(67, 79, 135, 1)",
  },
  [ScheduleOriginContactEnum.OTHERS]: {
    hex: "#E67078",
    rgba: "rgba(230, 112, 120, 1)",
  },
};
