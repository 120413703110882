import { Input, InputGroup, InputGroupText } from "reactstrap";
export interface CustomInputSearchClearableProps {
  inputSearch: string;
  setInputSearch: (value: string) => void;
  onClearInput?: () => void;
  placeholder?: string;
}

export const CustomInputSearchClearable = ({
  inputSearch,
  setInputSearch,
  onClearInput,
  placeholder,
}: CustomInputSearchClearableProps) => {
  return (
    <>
      <InputGroup>
        <Input
          type="text"
          value={inputSearch}
          placeholder={placeholder}
          onChange={(e) => setInputSearch(e.target.value)}
          className="border-right-0"
        />
        <InputGroupText
          className={`text-secondary bg-white `}
          onClick={onClearInput}
        >
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            className="css-tj5bde-Svg"
          >
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
          </svg>
        </InputGroupText>
        <InputGroupText className="bg-white">
          <button type="submit" className="border-0 bg-white">
            <i
              className="fa fa-search text-primary fs-20"
              aria-hidden="true"
            ></i>
          </button>
        </InputGroupText>
      </InputGroup>
    </>
  );
};
