import { makeAutoObservable } from "mobx";

interface FormData {
  title: string;
  description: string;
  attachments: [];
}

type FormDataProcedureInfo = Omit<FormData, "attachments">;

class MedicalRecordProceduresStore {
  formData: FormData[] = [
    {
      title: "",
      description: "",
      attachments: [],
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  addProcedure() {
    this.formData.push({
      title: "",
      description: "",
      attachments: [],
    });
  }

  removeProcedure(index: number) {
    this.formData.splice(index, 1);
  }

  setProcedureInfo(
    index: number,
    field: keyof FormDataProcedureInfo,
    value: string
  ) {
    this.formData[index][field] = value;
  }

  setProcedureFiles(index: number, attachments: []) {
    this.formData[index].attachments = attachments;
  }

  getFormData(): FormData[] {
    return this.formData;
  }

  isEmpty(): boolean {
    for (const exam of this.formData) {
      if (exam.title || exam.description || exam.attachments.length) {
        return false;
      }
    }
    return true;
  }

  cleanData(): void {
    this.formData = [
      {
        title: "",
        description: "",
        attachments: [],
      },
    ];
  }
}

const medicalRecordProceduresStore = new MedicalRecordProceduresStore();

export default medicalRecordProceduresStore;
