import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListDoctorsProps,
  ListDoctorsResponse,
  UserService,
} from "services/user-service";

export const actionFetchAllDoctors = action(
  async (input: ListDoctorsProps): Promise<ListDoctorsResponse> => {
    SetIsLoadingState(true);
    try {
      return UserService.fetchAllDoctors(input);
    } catch (error) {
      return {
        items: [],
        meta: {},
      } as unknown as ListDoctorsResponse;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
