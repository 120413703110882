import { ListEletronicPrescriptionItemResponse } from "services/eletronic-prescription-service";
import { PrescriptionCategoryEnum } from "domain/eletronic-prescription/entities/enums/prescription-category.enum";
import { actionGetLinkDownloadPrescription } from "actions/eletronic-prescription/action-get-link-download-prescription";
import { handleDownloadFile } from "views/painel/prescription/components/handle-download-file";

type ExamTabProps = Pick<
  ListEletronicPrescriptionItemResponse,
  "id" | "exams" | "documents"
>;

export const ExamTab: React.FC<ExamTabProps> = ({ id, exams, documents }) => {
  const prescriptionDocuments = documents.filter(
    (document) => document.category === PrescriptionCategoryEnum.EXAM
  );

  const handleDownload = async (idDocument: string) => {
    try {
      const url = await actionGetLinkDownloadPrescription(
        String(id),
        idDocument
      );

      handleDownloadFile(url, "Exame.pdf");
    } catch (error) {}
  };

  return (
    <div>
      {exams.map((exam, index) => (
        <div className="my-3" key={index}>
          <div className="font-weight-500 text-primary fs-17 my-1">
            {exam.title}
          </div>
          <div className="text-black-700 font-weight-500 my-1">
            {exam.description}
          </div>
          <div className="text-black-500 font-weight-400">
            {exam.laterality}
          </div>
        </div>
      ))}

      {prescriptionDocuments.map((document, index) => (
        <div key={index}>
          <div
            className="d-flex flex-wrap align-items-center py-2"
            style={{ backgroundColor: "#f5f7fe" }}
          >
            <div>
              <img
                src="https://app.g3med.com/pdf-64.png"
                height="45px"
                alt="pdf"
              />
            </div>
            <div className="font-weight-500 text-black-600 mx-3">Exame.pdf</div>
            <div
              className="btn-secondary px-3 py-1 rounded-050"
              onClick={() => handleDownload(document.id)}
            >
              <i className="fa fa-download" aria-hidden="true"></i> Download
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
