import { Button, Row } from "reactstrap";

interface MedicalRecordActionsProps {
  onStartAppointment: () => void;
  onStartPrescription: () => void;
}

export const MedicalRecordActions: React.FC<MedicalRecordActionsProps> = ({
  onStartAppointment,
  onStartPrescription,
}) => {
  return (
    <>
      <Row className="d-flex col-12 mx-0 p-0">
        <div className="col-12 col-md-6 p-0 pe-md-2">
          <Button
            color="primary"
            className="form-control"
            onClick={onStartAppointment}
          >
            Iniciar atendimento
          </Button>
        </div>
        <div className="col-12 col-md-6 p-0 ps-md-2">
          <Button
            color="primary"
            className="form-control"
            onClick={onStartPrescription}
          >
            Realizar prescrição
          </Button>
        </div>
      </Row>
    </>
  );
};
