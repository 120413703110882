import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";

export const ScheduleEnumsIcons = {
  [ScheduleStatusEnum.SCHEDULED]: "fas fa-calendar",
  [ScheduleStatusEnum.ATTENDED]: "fas fa-check",
  [ScheduleStatusEnum.WAITING_MEDICAL_ATTENTION]: "fas fa-clock",
  [ScheduleStatusEnum.NOT_ATTENDED]: "fas fa-ban",
  [ScheduleStatusEnum.CONFIRMED]: "fas fa-check-circle",
};

export const ScheduleEnumsColors = {
  [ScheduleStatusEnum.SCHEDULED]: "muted",
  [ScheduleStatusEnum.ATTENDED]: "success",
  [ScheduleStatusEnum.WAITING_MEDICAL_ATTENTION]: "warning",
  [ScheduleStatusEnum.NOT_ATTENDED]: "danger",
  [ScheduleStatusEnum.CONFIRMED]: "info",
};
