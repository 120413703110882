import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import {
  ListReceptionistsProps,
  ListReceptionistsResponse,
  UserService,
} from "services/user-service";

export const actionFetchAllReceptionists = action(
  async (input: ListReceptionistsProps): Promise<ListReceptionistsResponse> => {
    SetIsLoadingState(true);
    try {
      return await UserService.fetchAllReceptionists(input);
    } catch (error) {
      return {
        items: [],
        meta: {},
      } as unknown as ListReceptionistsResponse;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
