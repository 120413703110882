import { forIn } from "lodash";

export const enumIterator = (enumObject: any): number[] => {
  const keys: any = [];
  forIn(enumObject, (value, key) => {
    if (isNaN(Number(key))) keys.push(key);
  });

  return keys;
};
