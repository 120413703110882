export const documentMask = (document: string): string => {
  if (!document || !document.length) return "";
  const isCpf = document.length === 11;
  const mask = isCpf ? "###.###.###-##" : "##.###.###/####-##";
  let maskedDocument = "";
  let j = 0;

  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === "#") {
      maskedDocument += document[j];
      j++;
    } else {
      maskedDocument += mask[i];
    }
  }

  return maskedDocument;
};
