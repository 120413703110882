import "./section-line.css";

interface SectionLineProps {
  title: string;
  theme?: "dark" | "light";
}
export const SectionLine: React.FC<SectionLineProps> = ({
  title,
  theme = "dark",
}) => {
  return (
    <div className={`line-section-${theme}`}>
      <span className={`line-text-${theme}`}>{title}</span>
    </div>
  );
};
