import { useState } from "react";
import { ListEletronicPrescriptionItemResponse } from "services/eletronic-prescription-service";
import { CustomTab } from "components/tab/custom-tab";
import { PrescriptionTab } from "./prescription-tab";
import { ExamTab } from "./exam-tab";
import { MedicalCertificateTab } from "./medical-certificate-tab";
import { MedicalReportTab } from "./medical-report-tab";
import { MedicalReferralTab } from "./medical-referral-tab";

export const ListPrescriptionTabs: React.FC<
  ListEletronicPrescriptionItemResponse
> = (data: ListEletronicPrescriptionItemResponse) => {
  const [activeTab, setActiveTab] = useState("0");

  const listTabs = [];

  if (
    data?.prescriptions?.medicines?.length > 0 ||
    data?.prescriptions?.manualMedicines?.length > 0
  ) {
    listTabs.push({
      label: "Receita",
      disabled: false,
      element: (
        <div className="mt-4">
          <PrescriptionTab
            id={data.id}
            prescriptions={data.prescriptions}
            documents={data.documents}
          />
        </div>
      ),
    });
  }

  if (data?.exams?.length > 0) {
    listTabs.push({
      label: "Exames",
      disabled: false,
      element: (
        <div className="mt-4">
          <ExamTab id={data.id} exams={data.exams} documents={data.documents} />
        </div>
      ),
    });
  }

  if (data?.medical_certificates?.length > 0) {
    listTabs.push({
      label: "Atestados",
      disabled: false,
      element: (
        <div className="mt-4">
          <MedicalCertificateTab
            id={data.id}
            medical_certificates={data.medical_certificates}
            documents={data.documents}
          />
        </div>
      ),
    });
  }

  if (data?.medical_reports?.length > 0) {
    listTabs.push({
      label: "Relatórios",
      disabled: false,
      element: (
        <div className="mt-4">
          <MedicalReportTab
            id={data.id}
            medical_reports={data.medical_reports}
            documents={data.documents}
          />
        </div>
      ),
    });
  }

  if (data?.medical_referrals?.length > 0) {
    listTabs.push({
      label: "Encaminhamentos",
      disabled: false,
      element: (
        <div className="mt-4">
          <MedicalReferralTab
            id={data.id}
            medical_referrals={data.medical_referrals}
            documents={data.documents}
          />
        </div>
      ),
    });
  }

  const [tabs, setTabs] = useState(listTabs);

  //AJUSTAR NO BACKEND O ENUM DA CATEGORY, AO INVÉS DE UTILIZAR RECEITA, EXAME (IGUAL BEM DA MEVO), CRIAR O MAPPER PARA O DOMÍNIO
  // E SALVAR NO BANCO UTILIZANDO ENUM, CRIAR UM TRANSLANTE NO ENUM PARA PRESCRIPTION -> RECEITA, EXAM -> EXAME, MEDICAL_CERTIFICATE -> ATESTADO, MEDICAL_REPORT -> LAUDO, MEDICAL_REFERRAL -> ENCAMINHAMENTO
  //AJUSTAR NO BACKEND E FRONTEND
  return (
    <>
      <div className="wrapper-box-white mt-4">
        <CustomTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
};
