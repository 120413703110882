import ErrorCodesEnum from "./error-code.enum";

interface ErrorCodeMessage {
  code: number;
  message: string;
}

type ErrorCodeMessagesResponse = {
  [key in ErrorCodesEnum]: ErrorCodeMessage;
};

class ErrorCodeMessages {
  public static returnPT: ErrorCodeMessagesResponse = {
    [ErrorCodesEnum.NEEDS_UPDATE_DATA]: {
      code: ErrorCodesEnum.NEEDS_UPDATE_DATA,
      message: "Médico não possui número de registro. Atualize o cadastro",
    },
  };
}

export default ErrorCodeMessages;
