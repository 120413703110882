import { action } from "mobx";
import { IdentityService } from "services/identity-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionValidateOtp = action(
  async (email: string, otp: string): Promise<void> => {
    SetIsLoadingState(true);
    try {
      return await IdentityService.validateOTP(email, otp);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
