import "react-quill/dist/quill.snow.css";

import { InputFileUpload } from "components/form/input/input-file-upload/input-file-upload";
import { AttachmentTypeEnum } from "domain/attachments/entities/enums/attachment-type";
import { observer } from "mobx-react";
import medicalRecordAttachmentsStore from "state/medical-record/medical-record-attachments-store";

export const AttachmentsForm: React.FC = observer(() => {
  return (
    <>
      <InputFileUpload
        uploadedFiles={medicalRecordAttachmentsStore.getFormData().attachments}
        setUploadedFiles={(files) =>
          medicalRecordAttachmentsStore.setExamFiles(files as any)
        }
        type={AttachmentTypeEnum.MEDICAL_RECORD_ATTACHMENT}
      />
    </>
  );
});
