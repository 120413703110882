import { FormGroup, Label } from "reactstrap";
import { Field, Formik, Form } from "formik";
import { observer } from "mobx-react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import React from "react";

import { SectionLine } from "components/common/section-line/section-line";
import { AttachmentTypeEnum } from "domain/attachments/entities/enums/attachment-type";
import { InputFileUpload } from "components/form/input/input-file-upload/input-file-upload";
import QuillEditor from "components/form/input/quill-editor-input";
import medicalRecordProceduresStore from "state/medical-record/medical-record-procedures-store";

export const ProcedureForm: React.FC = observer(() => {
  const handleAddProcedure = () => {
    medicalRecordProceduresStore.addProcedure();
  };

  const handleProcedureChange = (
    index: number,
    field: "title" | "description",
    value: string
  ) => {
    medicalRecordProceduresStore.setProcedureInfo(index, field, value);
  };

  const handleRemoveProcedure = (index: number) => {
    medicalRecordProceduresStore.removeProcedure(index);
  };

  const handleFileUpload = (index: number, files: any) => {
    medicalRecordProceduresStore.setProcedureFiles(index, files);
  };

  const AddProcedure = () => {
    return (
      <div className="d-flex justify-content-end mt-3">
        <span
          className="text-primary fs-17 font-weight-400"
          onClick={handleAddProcedure}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-plus-circle mr-2" /> Adicionar procedimento
        </span>
      </div>
    );
  };

  const RemoveProcedure: React.FC<{ index: number }> = ({ index }) => {
    return (
      <div className="d-flex justify-content-end mt-3">
        <span
          className="text-danger fs-17 font-weight-400"
          onClick={() => handleRemoveProcedure(index)}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-plus-circle mr-2" /> Remover procedimento
        </span>
      </div>
    );
  };
  const validationSchema = Yup.object().shape({});
  const handleSubmit = (values: any) => {};

  return (
    <>
      <Formik
        initialValues={medicalRecordProceduresStore.getFormData()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ handleChange }) => (
          <Form>
            <div className="procedure-item-list">
              {medicalRecordProceduresStore
                .getFormData()
                .map((procedure, index) => (
                  <>
                    <RemoveProcedure index={index} />
                    <div key={index} className="procedure-item-form">
                      <FormGroup className="col-12 px-0">
                        <Label htmlFor={`name_${index}`}>
                          Título do Procedimento
                        </Label>
                        <Field
                          name="title"
                          placeholder="Digite título do procedimento"
                          theme="snow"
                          value={procedure.title}
                          component={QuillEditor}
                          onChange={(value: string) => {
                            handleProcedureChange(index, "title", value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup className="col-12 px-0 mt-4">
                        <Label htmlFor={`description_${index}`}>
                          Descrição
                        </Label>
                        <ReactQuill
                          id={`description_${index}`}
                          placeholder="Digite descrição do procedimento"
                          value={procedure.description}
                          onChange={(value) =>
                            handleProcedureChange(index, "description", value)
                          }
                        />
                      </FormGroup>
                      <InputFileUpload
                        uploadedFiles={procedure.attachments}
                        setUploadedFiles={(files) =>
                          handleFileUpload(index, files)
                        }
                        type={AttachmentTypeEnum.MEDICAL_RECORD_PROCEDURE}
                      />
                    </div>

                    <div className="pt-3">
                      <SectionLine title="Procedimentos adicionados" />
                    </div>
                  </>
                ))}
              <AddProcedure />
            </div>
          </Form>
        )}
      </Formik>
      {/* Workaround to Work */}
      <div className="d-none">
        {JSON.stringify(medicalRecordProceduresStore)}
      </div>
    </>
  );
});
