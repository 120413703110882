export enum ScheduleStatusEnum {
  SCHEDULED = 0,
  CONFIRMED = 1,
  WAITING_MEDICAL_ATTENTION = 2,
  NOT_ATTENDED = 3,
  ATTENDED = 4,
}

export const ScheduleStatusLabels = (scheduleStatus: ScheduleStatusEnum) => {
  const labels = {
    [ScheduleStatusEnum.SCHEDULED]: "Agendado",
    [ScheduleStatusEnum.CONFIRMED]: "Confirmado",
    [ScheduleStatusEnum.WAITING_MEDICAL_ATTENTION]: "Aguardando atendimento",
    [ScheduleStatusEnum.NOT_ATTENDED]: "Não compareceu",
    [ScheduleStatusEnum.ATTENDED]: "Atendimento realizado",
  };

  return labels[scheduleStatus];
};

export const ScheduleStatusColors = {
  [ScheduleStatusEnum.SCHEDULED]: {
    class: "muted",
    hex: "#6c757d",
    rgba: "rgba(108, 117, 125, 1)",
  },
  [ScheduleStatusEnum.CONFIRMED]: {
    class: "info",
    hex: "#0DCAF0",
    rgba: "rgba(13, 202, 240, 1)",
  },
  [ScheduleStatusEnum.WAITING_MEDICAL_ATTENTION]: {
    class: "warning",
    hex: "#FFC107",
    rgba: "rrgba(255, 193, 7, 1)",
  },
  [ScheduleStatusEnum.NOT_ATTENDED]: {
    class: "danger",
    // hex: "#DC3545",
    // rgba: "rgba(220, 53, 69, 1)",
    hex: "#E67078",
    rgba: "rgba(230, 112, 120, 1)",
  },
  [ScheduleStatusEnum.ATTENDED]: {
    class: "success",
    hex: "#198C54",
    rgba: "rgba(25, 135, 84, 1)",
  },
};
