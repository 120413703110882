import { jwtDecode } from "jwt-decode";

import { TokenTypeEnum } from "domain/auth/entities/enums/token-type.enum";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import { getLocalAccessToken } from "config/http-client";

interface UserIdentityData {
  tenant_id: number | null;
  tenant_name: string;
  token_type: TokenTypeEnum | null;
  user_id: number | null;
  user_role_id: number | null;
  user_name: string;
  user_role: UserRoleEnum | null;
}

class UserIdentity {
  static getRole(): UserRoleEnum | null {
    return this.getData().user_role;
  }

  static hasRole(roles: UserRoleEnum[]): boolean {
    return (
      this.getData().user_role !== null &&
      roles.includes(this.getData().user_role as UserRoleEnum)
    );
  }

  static getData(): UserIdentityData {
    const accessToken = getLocalAccessToken();

    if (!accessToken)
      return {
        tenant_id: null,
        tenant_name: "",
        token_type: null,
        user_id: null,
        user_role_id: null,
        user_name: "",
        user_role: null,
      };

    const decoded: any = jwtDecode(accessToken);

    return {
      tenant_id: decoded?.payload?.tenant_id ?? null,
      tenant_name: decoded?.payload?.tenant_name ?? "",
      token_type: decoded?.payload?.token_type ?? null,
      user_id: decoded?.payload?.user_id ?? null,
      user_role_id: decoded?.payload?.user_role_id ?? null,
      user_name: decoded?.payload?.user_name ?? "",
      user_role: decoded?.payload?.user_role ?? null,
    };
  }

  static getName(): string {
    return this.getData().user_name;
  }
  // static getHomePath() {
  //   const roles = EmployeeAuthorization.getRoles();
  //   return roles.includes(RoleEnum.ADMIN) ? "/admin/index" : "/admin/pedidos";
  // }
}

export default UserIdentity;
