import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaginationMeta } from "components/table/custom-table";
import {
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { actionListAllPrescriptions } from "actions/eletronic-prescription/action-list-all-prescription";
import { dateFormatter } from "helpers/date-formatter";
import { ListPrescriptionsTable } from "./list-prescriptions-table";
import { PrescriptionBadgeComponent } from "../components/prescription-badge.component";
import { PrescriptionCategoryEnum, PrescriptionCategoryLabels } from 'domain/eletronic-prescription/entities/enums/prescription-category.enum';

export interface ListPrescriptionItemTable {
  id: number;
  patient_name: any;
  created_at: any;
  tags: any;
  is_signed: any;
}

export const PrescriptionContainer = () => {
  const PAGE_SIZE = 20;
  const navigate = useNavigate();

  const [prescriptions, setPrescriptions] = useState<
    ListPrescriptionItemTable[]
  >([]);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);
  const [tableType, setTableType] = useState<"card" | "table">("table");

  const [currentPage, setCurrentPage] = useState(1);
  const [inputSearch, setInputSearch] = useState("");

  const redirectToViewPrescription = (id: number): void => {
    navigate(`/painel/prescricao/${id}`);
  };

  const prescriptionItemName = (name: string, id: number) => {
    return (
      <div className="w-100" onClick={() => redirectToViewPrescription(id)}>
        <span>{name}</span>
      </div>
    );
  };

  const prescriptionItemTags = (tags: string[], id: number) => {
    return (
      <div className="w-100" onClick={() => redirectToViewPrescription(id)}>
        <>
          {tags.map((tag: string, index) => (
            <span className="mx-1">
              <PrescriptionBadgeComponent
                data={{ name: PrescriptionCategoryLabels(tag as PrescriptionCategoryEnum), bgColor: "badge-bg-primary" }}
                key={index}
              />
            </span>
          ))}
        </>
      </div>
    );
  };

  const prescriptionItemIsDigitalSignature = (
    isSigned: boolean,
    id: number
  ) => {
    return (
      <div className="w-100" onClick={() => redirectToViewPrescription(id)}>
        {isSigned == true ? (
          <>
            <PrescriptionBadgeComponent
              data={{ name: "Assinado", bgColor: "badge-bg-success" }}
            />
          </>
        ) : (
          <>
            {" "}
            <PrescriptionBadgeComponent
              data={{ name: "Não assinado", bgColor: "badge-bg-danger" }}
            />
          </>
        )}
      </div>
    );
  };

  const prescriptionItemCreatedAt = (createdAt: Date, id: number) => {
    return (
      <div className="w-100" onClick={() => redirectToViewPrescription(id)}>
        <span>{dateFormatter(new Date(createdAt), "dd/MM/yyyy hh:mm")}</span>
      </div>
    );
  };

  const fetchAll = async (page?: number, input?: string) => {
    const result = await actionListAllPrescriptions({
      limit: PAGE_SIZE,
      page: page || currentPage,
      patient_name: input || inputSearch,
    });

    const items = result.items.map((item): ListPrescriptionItemTable => {
      return {
        id: item.id,
        patient_name: prescriptionItemName(item.patient_name, item.id),
        tags: prescriptionItemTags(item.tags, item.id),
        is_signed: prescriptionItemIsDigitalSignature(item.is_signed, item.id),
        created_at: prescriptionItemCreatedAt(item.created_at, item.id),
      };
    });
    setPrescriptions(items);
    setMeta(result.meta);
    window.scrollTo(0, 0);
  };

  const onChangePage = async (page: number) => {
    setCurrentPage(page);
    await fetchAll(page);
  };
  useEffect(() => {
    (async () => {
      await fetchAll();
    })();
  }, []);
  const onSubmit = () => {};
  return (
    <>
      <div className="main-wrapper" >
        <Row className="mb-3">
          <Col sm="12" md="6">
            <Form
              className="d-flex col-12 col-md-7 justify-content-start my-4"
              onSubmit={onSubmit}
            >
              <InputGroup>
                <Input
                  type="text"
                  value={inputSearch}
                  onChange={(e) => setInputSearch(e.target.value)}
                  className="border-right-0"
                  placeholder="Buscar paciente por nome"
                />
                <InputGroupText className="bg-white">
                  <button type="submit" className="border-0 bg-white">
                    <i
                      className="fa fa-search text-primary fs-20"
                      aria-hidden="true"
                    ></i>
                  </button>
                </InputGroupText>
              </InputGroup>
            </Form>
          </Col>
        </Row>

        <ListPrescriptionsTable
          prescriptions={prescriptions}
          meta={meta}
          onSelectPrescription={(id: string) =>
            redirectToViewPrescription(Number(id))
          }
          onChangePage={onChangePage}
          tableType={tableType}
          setTableType={setTableType}
        />
      </div>
    </>
  );
};
