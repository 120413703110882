import { action } from "mobx";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";
import { ListDoctorResponse, UserService } from "services/user-service";

export const actionFetchDoctor = action(
  async (id: string): Promise<ListDoctorResponse> => {
    SetIsLoadingState(true);
    try {
      return await UserService.fetchDoctor(id);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
