import React from "react";
import { Row, Col } from "reactstrap";

const Privacy: React.FC = () => {
  return (
    <>
      <Row className="d-flex px-0 col-12 d-flex align-items-center row flex-column">
        <img
          className="mx-auto mb-5 col-12"
          src="../../logo-white.png"
          alt="Logo G3MED"
          style={{ width: "150px" }}
        />

        <Col
          sm="12"
          md="10"
          className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
        >
          <div>
            <h2 className="text-primary text-center mb-5">
              Política de Privacidade
            </h2>
            <div>
              <div>
                <p>
                  A presente&nbsp;<strong>Política de Privacidade</strong> tem
                  por finalidade demonstrar o compromisso da G3med
                  <strong>
                    {" "}
                    presente Política de Privacidade tem por finalidade
                    demonstrar o compromisso da CLEUBER BIANGO TEIXEIRA
                    COLSULTORIA EM TECNOLOGIA DA INFORMAÇÃO LTDA., pessoa
                    jurídica de direito privado, inscrita no CNPJ sob o nº
                    45.781.073/0001-04, com sede na cidade de GOIÂNIA, estado de
                    GOIÁS, na rua H, nº 148, Set Progresso, CEP 74580-580,&nbsp;
                    titular das plataformas, aplicativos e sites G3MED
                    DESENVOLVIMENTO DE SOFTWARE LTDA, com a privacidade e a
                    proteção dos dados pessoais coletados de seus USUÁRIOS,
                    estabelecendo as regras sobre o tratamento dos dados dentro
                    do escopo dos serviços e funcionalidades do G3MED, de acordo
                    com as leis em vigor, com transparência e clareza junto ao
                    USUÁRIO e ao mercado em geral.
                  </strong>
                </p>
                <p>
                  Como condição para acesso e uso das funcionalidades exclusivas
                  da G3MED, o USUÁRIO declara que fez a leitura completa e
                  atenta da presente Política de Privacidade, estando plenamente
                  ciente, conferindo, assim, sua livre e expressa concordância
                  com os termos aqui estipulados, autorizando a obtenção dos
                  dados aqui mencionados, bem como sua utilização para os fins
                  abaixo especificados. Caso não esteja de acordo com estas
                  diretivas, o USUÁRIO deverá descontinuar o seu acesso.G3MED,
                  pessoa jurídica de direito privado, titular das plataformas,
                  aplicativos e sites <strong>G3MED</strong>, doravante
                  denominada <strong>“G3MED”</strong>, com a privacidade e a
                  proteção dos dados pessoais coletados de seus&nbsp;
                  <strong>USUÁRIOS</strong>, estabelecendo as regras sobre o
                  tratamento dos dados dentro do escopo dos serviços e
                  funcionalidades do <strong>G3MED</strong>, de acordo com as
                  leis em vigor, com transparência e clareza junto ao&nbsp;
                  <strong>USUÁRIO</strong>&nbsp;e ao mercado em geral.
                </p>
                <p>
                  Como condição para acesso e uso das funcionalidades exclusivas
                  da <strong>G3MED</strong>, o&nbsp;<strong>USUÁRIO</strong>
                  &nbsp;declara que fez a leitura completa e atenta da
                  presente&nbsp;<strong>Política de Privacidade</strong>,
                  estando plenamente ciente, conferindo, assim, sua livre e
                  expressa concordância com os termos aqui estipulados,
                  autorizando a obtenção dos dados aqui mencionados, bem como
                  sua utilização para os fins abaixo especificados. Caso não
                  esteja de acordo com estas diretivas, o&nbsp;
                  <strong>USUÁRIO</strong>&nbsp;deverá descontinuar o seu
                  acesso.
                </p>
                <h3>1.Definições</h3>
                <p>
                  1.1. Para os fins deste documento, devem se considerar as
                  seguintes definições e descrições para seu melhor
                  entendimento:
                </p>
                <p>
                  <strong>COOKIES:&nbsp;</strong>pequenos arquivos de computador
                  ou pacotes de dados enviados por um site da Internet para o
                  navegador do usuário, quando o&nbsp;<strong>USUÁRIO</strong>
                  &nbsp;visita o site.
                </p>
                <p>
                  <strong>IP:</strong>&nbsp;Abreviatura de&nbsp;
                  <em>Internet Protocol</em>. É conjunto alfanumérico que
                  identifica os dispositivos dos&nbsp;<strong>USUÁRIOS</strong>
                  &nbsp;na Internet.
                </p>
                <p>
                  <strong>USUÁRIO:</strong> Qualquer pessoa física que acessa
                  e/ou utiliza as funcionalidades e/ou serviços da{" "}
                  <strong>G3MED</strong>.
                </p>
                <h3>
                  <strong>
                    2. Coleta e Uso de Dados e Registro de Atividades
                  </strong>
                </h3>
                <p>
                  2.1. Os dados coletados a partir da submissão voluntária
                  pelo&nbsp;<strong>USUÁRIO&nbsp;</strong>ou automaticamente
                  atendem ao princípio da necessidade e podem incluir:
                </p>
                <h4>
                  <strong>Usuário Paciente</strong>
                </h4>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          <strong>Categoria&nbsp;</strong>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <strong>Dados</strong>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <strong>Finalidade&nbsp;</strong>
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Dados obrigatórios</strong>
                        </p>
                      </td>
                      <td>
                        <p>Nome, email, N.º de celular</p>
                      </td>
                      <td>
                        <p>Autenticação, identificação, prescrição</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Dados facultativos</strong>
                        </p>
                      </td>
                      <td>
                        <p>CPF, endereço, gênero, foto</p>
                      </td>
                      <td>
                        <p>Identificação, prescrição</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Dados de saúde</strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          Prescrições de medicamentos, exames, vacinas,
                          diagnósticos, alergias, doenças e outras condições de
                          saúde relacionadas
                        </p>
                      </td>
                      <td>
                        <p>Consulta de Receitas Médicas</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h4>
                  <strong>Usuário Profissional da Saúde</strong>
                </h4>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          <strong>Categoria&nbsp;</strong>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <strong>Dados&nbsp;</strong>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <strong>Finalidade&nbsp;</strong>
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Cadastrais obrigatórios</strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          Nome, email, telefone, função, sexo, especialidade do
                          profissional de saúde e CRF
                        </p>
                      </td>
                      <td rowspan="2">
                        <p>
                          Identificação e autenticação do Usuário na plataforma
                        </p>
                        <p>Tratamento de saúde&nbsp;</p>
                        <p>
                          Realização de consultas presenciais ou à distância
                        </p>
                        <p>Emissão de Receitas Médicas</p>
                        <p>
                          Receber comunicados ou atualizações sobre a G3MED e
                          seus produtos
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Cadastrais facultativos</strong>
                        </p>
                      </td>
                      <td>
                        <p>CPF, telefone, endereço</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Dados de saúde, imagem e biometria</strong>
                        </p>
                      </td>
                      <td>
                        <p>
                          Características biológicas, tratamentos em andamento,
                          medicamentos usados, doenças e outras condições de
                          saúde relacionadas ou não a fatores genéticos
                          (prontuário)
                        </p>
                        <p>Imagem e Voz</p>
                      </td>
                      <td>
                        <p>Tratamento de saúde&nbsp;</p>
                        <p>
                          Realização de consultas presenciais ou à distância
                          (teleconsulta)
                        </p>
                        <p>Emissão de Receitas Médicas</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Pagamento</strong>
                        </p>
                      </td>
                      <td>
                        <p>Dados do cartão de crédito</p>
                      </td>
                      <td>
                        <p>
                          Contratação de módulos de serviços na plataforma G3MED
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Registros eletrônicos</strong>
                        </p>
                      </td>
                      <td>
                        <p>IP, Cookies, registros de atividades</p>
                      </td>
                      <td>
                        <p>
                          Registro de acesso para fins de cumprimento de
                          obrigação legal
                        </p>
                        <p>Analytics no site G3MED</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Estatísticos (anonimizados)</strong>
                        </p>
                      </td>
                      <td>
                        <p>Dados de uso da plataforma</p>
                        <p>
                          (<em>não são considerados dados pessoais)</em>
                        </p>
                      </td>
                      <td>
                        <p>Analytics de uso da plataforma G3MED</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <strong>&nbsp;</strong>
                  <strong>&nbsp;</strong>
                </p>
                <h4>
                  <strong>Usuário Recepcionista</strong>
                </h4>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          <strong>Categoria&nbsp;</strong>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <strong>Dados</strong>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <strong>Finalidade&nbsp;</strong>
                        </h4>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Cadastrais obrigatórios</strong>
                        </p>
                      </td>
                      <td>
                        <p>Nome, email, sexo</p>
                      </td>
                      <td>
                        <p>
                          Identificação e autenticação do Usuário na plataforma
                        </p>
                        <p>
                          Receber comunicados ou atualizações sobre a G3MED e
                          seus produtos
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  2.2. Todos os dados pessoais poderão ser utilizados como meio
                  probatório em casos de atos ilícitos ou contrários a
                  esta&nbsp;<strong>Política de Privacidade</strong> ou qualquer
                  outro documento legal disponibilizado pela{" "}
                  <strong>G3MED</strong>, bem como para cumprimento de ordem
                  judicial ou de requisição administrativa.
                </p>
                <p>
                  2.2.1. Cabe ao&nbsp;<strong>USUÁRIO</strong>&nbsp;configurar o
                  seu dispositivo móvel caso deseje bloquear a coleta de cookies
                  ou outros dados.&nbsp;
                  <strong>
                    Nesta hipótese, algumas funcionalidades da ICLINIC poderão
                    ser limitadas.
                  </strong>
                </p>
                <p>
                  2.3. A <strong>G3MED</strong>&nbsp;não é responsável pela
                  precisão, veracidade ou falta delas nas informações prestadas
                  pelo&nbsp;<strong>USUÁRIO</strong>&nbsp;ou pela sua
                  desatualização, sendo de responsabilidade do&nbsp;
                  <strong>USUÁRIO&nbsp;</strong>prestá-las com exatidão e
                  atualizá-las sempre que necessário.
                </p>
                <p>
                  2.4. A base de dados formada por meio da coleta de dados na{" "}
                  <strong>G3MED</strong> é de propriedade e responsabilidade da{" "}
                  <strong>G3MED</strong>, sendo que seu uso, acesso e
                  compartilhamento, quando necessários, serão feitos dentro dos
                  limites e propósitos dos negócios da <strong>G3MED</strong>
                  &nbsp;e descritos nesta&nbsp;
                  <strong>Política de Privacidade</strong>&nbsp;e&nbsp;
                  <strong>Termos de Uso</strong>.
                </p>
                <p>
                  2.4.1. O&nbsp;<strong>USUÁRIO</strong>&nbsp;é corresponsável
                  pelo sigilo de seus dados pessoais. O compartilhamento de
                  senhas e dados de acesso viola esta&nbsp;
                  <strong>Política de Privacidade</strong>&nbsp;e os&nbsp;
                  <strong>Termos de Uso&nbsp;</strong>da <strong>G3MED</strong>.
                </p>
                <p>
                  2.5. Internamente, os dados dos&nbsp;<strong>USUÁRIOS</strong>{" "}
                  serão acessados somente por profissionais devidamente
                  autorizados pela <strong>G3MED</strong>, respeitando os
                  princípios de proporcionalidade, necessidade e relevância para
                  os objetivos da <strong>G3MED</strong>, além do compromisso de
                  confidencialidade e preservação da privacidade nos termos
                  desta&nbsp;<strong>Política de Privacidade</strong>.
                </p>
                <h3>
                  3. Armazenamento, Transferência Internacional, Retenção e
                  Eliminação dos Dados
                </h3>
                <p>
                  3.1. Os dados coletados são armazenados em nuvem (
                  <em>cloud computing</em>) da Amazon com servidores localizados
                  no Brasil e Estados Unidos.
                </p>
                <p>
                  3.1.1. A transferência internacional será realizada somente
                  para esses agentes e finalidades descritas nesta Política de
                  Privacidade, os quais aplicam as melhores práticas
                  internacionais de proteção de dados pessoais e garantem
                  proporcionar grau de proteção de dados pessoais adequado ao
                  previsto na Lei nº 13.709/2018 (Lei Geral de Proteção de Dados
                  Pessoais – LGPD), conforme exigência do art. 33, inciso I da
                  lei.
                </p>
                <p>
                  3.2. Todos os dados coletados pela <strong>G3MED</strong> são
                  armazenados em ambiente seguro, monitorado, com controle de
                  falha e backup distribuído. Todavia a <strong>G3MED</strong>{" "}
                  em sua condição de prestador de Software, como Serviço não
                  contempla em suas atribuições o controle das estações que
                  utilizam o Software, a responsabilização será do
                  usuário/cliente na condição de controlador de dados pessoais
                  de seus clientes/pacientes. A <strong>G3MED</strong>&nbsp;se
                  exime de quaisquer responsabilidades por eventuais danos e/ou
                  prejuízos decorrentes de falhas, vírus ou invasões do banco de
                  dados proveniente de acesso indevido por displicência do
                  usuário/cliente, salvo nos casos em que incorrer em dolo ou
                  culpa.
                </p>
                <p>
                  3.3. Para fins de auditoria, segurança, controle de fraudes,
                  preservação de direitos e cumprimento de obrigação legal, a{" "}
                  <strong>G3MED</strong>&nbsp;poderá permanecer com o histórico
                  de registros de acesso dos&nbsp;<strong>USUÁRIOS</strong>
                  &nbsp;por prazo mínimo de 6 (seis) meses. A guarda dos dados
                  relacionados ao prontuário do paciente é de responsabilidade
                  integral da clínica, conforme a legislação específica do
                  setor.
                </p>
                <p>
                  3.3.1. Ao término do contrato entre&nbsp;
                  <strong>USUÁRIO&nbsp;</strong>e <strong>G3MED</strong>,
                  o&nbsp;<strong>USUÁRIO&nbsp;</strong>Administrador da Conta é
                  integralmente responsável por realizar a exportação de todos
                  os dados inseridos em sua conta na plataforma{" "}
                  <strong>G3MED</strong>&nbsp;dentro do prazo de 60 (sessenta)
                  dias a contar do término da assinatura.
                </p>
                <p>
                  3.3.2. Caso haja solicitação de eliminação dos dados
                  pelo&nbsp;<strong>USUÁRIO</strong>, essa eliminação somente
                  poderá ocorrer se já não houver mais finalidade de uso nem
                  obrigação legal, regulatória ou judicial que justifique sua
                  retenção.&nbsp;
                </p>
                <p>
                  3.3.3. Os dados deverão ser retidos enquanto durar sua
                  finalidade de uso ou obrigação legal, regulatória ou judicial
                  que justifique sua retenção. Ao término da finalidade de uso e
                  o prazo de retenção obrigatória, os dados poderão ser
                  eliminados com uso de métodos de descarte seguro, ou
                  utilizados de forma anonimizada para fins estatísticos.
                </p>
                <h3>4. Compartilhamento e Exportação dos Dados</h3>
                <p>
                  4.1. Os dados coletados e as atividades registradas poderão
                  ser compartilhados:
                </p>
                <ol>
                  <li aria-level="1">
                    i) Com autoridades judiciais, administrativas ou
                    governamentais competentes, sempre que houver requisição
                    legal das autoridades ou ordem judicial;
                  </li>
                  <li aria-level="1">
                    ii) De forma automática em caso de movimentações
                    societárias, como fusão, aquisição ou incorporação, e
                  </li>
                  <li aria-level="1">
                    iii) De forma automática, com os provedores dos serviços
                    contratados pela <strong>G3MED</strong>
                    <strong>&nbsp;</strong>para viabilizar o site e plataforma{" "}
                    <strong>G3MED</strong>
                    <strong>&nbsp;</strong>juntamente com todas as suas
                    funcionalidades e serviços disponibilizados;
                  </li>
                  <li aria-level="1">
                    iv) Com as pessoas jurídicas integrantes do Grupo Afya,
                    quando necessário para a regular prestação de serviços e/ou
                    fornecimento de produtos pelo Grupo Afya;
                  </li>
                  <li aria-level="1">
                    v) Nossos parceiros comerciais e prestadores de serviços,
                    quando necessário para fins de viabilizar os serviços
                    prestados pelo Grupo Afya, como, por exemplo, prestadores de
                    serviços de (i) licenciamento de softwares educacionais,
                    (ii) infraestrutura e tecnologia necessária ao desempenho
                    operacional das Plataformas e demais ferramentas
                    educacionais utilizadas pelas Unidades de Ensino, (iii)
                    computação em nuvem, (iv) pesquisa, análise e avaliações
                    institucionais e pedagógicas, (v) soluções para processos
                    seletivos, (vi) auditorias externas, (viii) plataformas de
                    marketing e publicidade; (ix) serviços financeiros, (x)
                    serviços de cobrança, (xi) recrutamento e seleção, (xii)
                    organização de eventos, feiras e workshops.
                  </li>
                </ol>
                <p>
                  4.2. Todos os terceiros contratados para a finalidade do item
                  “iii” acima obrigam-se a tratar os dados com confidencialidade
                  e somente para a finalidade contratada, assegurando o
                  cumprimento legal em matéria de privacidade e proteção de
                  dados e utilizando, em todo o tempo, as melhores práticas de
                  segurança da informação.
                </p>
                <p>
                  4.3. Poderá, ainda, haver compartilhamento prontuários entre
                  os profissionais de saúde de uma mesma clínica, a depender das
                  regras estabelecidas pela clínica para acesso aos dados e
                  prestação dos serviços de cuidado em saúde.
                </p>
                <p>
                  4.4. Os dados de prontuários somente poderão ser exportados
                  mediante autorização do&nbsp;<strong>USUÁRIO&nbsp;</strong>
                  Administrador da Conta da clínica na plataforma, o qual será
                  integralmente responsável pela legitimação da operação.
                </p>
                <h3>
                  <strong>5. Consentimento</strong>
                </h3>
                <div className="hs-callout-type-tip" data-hs-callout-type="tip">
                  <p>
                    Neste tópico, você verificará os aspectos do seu
                    consentimento coletado pela iClinic, bem como a forma de
                    alterar suas permissões.
                  </p>
                </div>
                <p>
                  5.1. O consentimento fornecido pelo&nbsp;
                  <strong>USUÁRIO</strong>&nbsp;é coletado de forma livre,
                  informada, destacada, específica e legítima.
                </p>
                <p>
                  5.2. O&nbsp;<strong>USUÁRIO</strong> poderá alterar suas
                  concessões de consentimento, conceder novas permissões ou
                  retirar seu consentimento para as permissões atuais por meio
                  dos canais de atendimento da <strong>G3MED</strong>
                  <strong>&nbsp;</strong>disponibilizados no site e na
                  plataforma, sendo avisado sobre as consequências que a
                  retirada de consentimento poderá lhe causar.
                </p>
                <h3>
                  <strong>
                    6. Exibição, Retificação, Portabilidade, Limitação, Oposição
                    e Eliminação de Dados
                  </strong>
                </h3>
                <div className="hs-callout-type-tip" data-hs-callout-type="tip">
                  <p>
                    Aqui, você poderá entender melhor como exercer seus direitos
                    previstos pela Lei Geral de Proteção de Dados Pessoais
                    (LGPD), e a condição para a portabilidade dos prontuários
                    dos pacientes.
                  </p>
                </div>
                <p>
                  6.1. O&nbsp;<strong>USUÁRIO</strong> poderá ter acesso aos
                  seus dados (exibição) e realizar a retificação de seus dados
                  pessoais por meio do ambiente logado na plataforma{" "}
                  <strong>G3MED</strong> ou pelos canais de atendimento
                  disponibilizados pela <strong>G3MED</strong>.
                </p>
                <p>
                  6.2. Pelo canal de atendimento da<strong> G3MED</strong>,
                  o&nbsp;<strong>USUÁRIO</strong> poderá também requerer: (i) a
                  limitação do uso de seus dados pessoais; (ii) manifestar sua
                  oposição ao uso de seus dados pessoais ou (iii) solicitar a
                  eliminação de seus dados pessoais coletados pela{" "}
                  <strong>G3MED</strong>, desde que a eventual relação
                  contratual entre o&nbsp;<strong>USUÁRIO</strong> e a{" "}
                  <strong>G3MED</strong>&nbsp;tenha terminado, não haja nenhuma
                  finalidade de uso que legitime o tratamento e tenha decorrido
                  o prazo legal mínimo relacionado à retenção dos dados,
                  conforme tópico 3.3 desta Política de Privacidade.
                </p>
                <p>
                  6.3. Tendo em vista o papel de Operador que a{" "}
                  <strong>G3MED</strong>
                  <strong>&nbsp;</strong>desempenha em relação aos dados de
                  pacientes, a portabilidade dos dados de prontuários médicos
                  deverá ser solicitada pelo próprio paciente ao profissional de
                  saúde que o atende ou à clínica em que realize tratamento de
                  saúde, responsável pela guarda dos dados dos prontuários.
                </p>
                <h3>
                  <strong>7. Segurança</strong>
                </h3>
                <div className="hs-callout-type-tip" data-hs-callout-type="tip">
                  <p>
                    Quais são as medidas implementadas para proteger seus dados
                    pessoais.
                  </p>
                </div>
                <p>
                  7.1. A <strong>G3MED</strong>, trata os dados pessoais de
                  acordo com as melhores práticas de segurança da informação e,
                  em especial, os armazena na nuvem da Amazon, que aplica as
                  mais avançadas técnicas de segurança da informação disponíveis
                  no mercado, sendo certificada e recertificada no atendimento a
                  todos os requisitos de segurança determinados pela ISO 27018,
                  o código de melhores práticas internacionais para proteção de
                  dados pessoais na nuvem (informações e certificado disponíveis
                  em:&nbsp;
                  <a href="https://aws.amazon.com/compliance/iso-27018-faqs/">
                    https://aws.amazon.com/compliance/iso-27018-faqs/
                  </a>
                  , acesso em 27.07.2020).
                </p>
                <p>
                  7.2. A própria <strong>G3MED</strong>&nbsp;também aplica
                  medidas técnicas e administrativas aptas a proteger os dados
                  pessoais de acessos não autorizados e de situações acidentais
                  ou ilícitas de destruição, perda, alteração, comunicação ou
                  difusão, observados a estrutura, a escala e o volume de suas
                  operações, bem como a sensibilidade dos dados tratados e a
                  probabilidade e a gravidade dos danos para os titulares dos
                  dados. Dentre tais medidas, destacam-se o uso de técnicas de
                  SALT para a codificação da senha, backup, segregação dos dados
                  por organização e controle de acesso aos dados com base na
                  necessidade e permissão concedida pelo&nbsp;
                  <strong>USUÁRIO</strong>.
                </p>
                <h3>8. Disposições Gerais</h3>
                <div className="hs-callout-type-tip" data-hs-callout-type="tip">
                  <p>
                    Aqui, você encontrará algumas informações gerais, como, por
                    exemplo, o contato do Data Protection Officer (DPO) da
                    iClinic e que, se uma cláusula for julgada inválida, as
                    demais automaticamente permanecem válidas, sem necessidade
                    de nova comunicação.
                  </p>
                </div>
                <p>
                  8.1. A <strong>G3MED</strong>&nbsp;não utiliza nenhum tipo de
                  decisão automatizada que impacte o&nbsp;
                  <strong>USUÁRIO</strong>.
                </p>
                <p>
                  8.2. A <strong>G3MED</strong>&nbsp;reserva a si o direito de
                  alterar o teor desta&nbsp;
                  <strong>Política de Privacidade</strong>&nbsp;a qualquer
                  momento, conforme a finalidade ou necessidade, tal qual para
                  adequação e conformidade legal de disposição de lei ou norma
                  que tenha força jurídica equivalente, cabendo ao&nbsp;
                  <strong>USUÁRIO</strong> verificá-la sempre que efetuar o
                  acesso à <strong>G3MED</strong>.
                </p>
                <p>
                  8.2.1. Ocorrendo atualizações neste documento e que
                  necessariamente demandem nova coleta de consentimento, a{" "}
                  <strong>G3MED</strong>&nbsp;notificará o&nbsp;
                  <strong>USUÁRIO</strong>&nbsp;pelos meios de contato por ele
                  fornecidos.
                </p>
                <p>
                  8.3. Em caso de qualquer dúvida com relação às disposições
                  constantes nesta Política de Privacidade ou necessidade de
                  contato com o Encarregado pelo Tratamento de Dados Pessoais
                  (Data Protection Officer – DPO da ICLINIC) o USUÁRIO poderá
                  entrar em contato pelo Canal de Atendimento ao Titular pelo
                  site&nbsp;
                  <a href="https://g3med.com/protecaodedados" rel="noopener">
                    https://g3med.com/protecaodedados/
                  </a>
                  , sendo que as respostas ao contato serão enviadas entre
                  segunda e sexta-feira, no horário comercial, exceto em
                  feriados.
                </p>
                <p>
                  8.4. Caso empresas terceirizadas realizem o tratamento de
                  quaisquer dados coletados pela <strong>G3MED</strong>, deverão
                  respeitar as condições aqui estipuladas e as melhores práticas
                  de segurança da informação, obrigatoriamente.
                </p>
                <p>
                  8.5. Caso alguma disposição desta&nbsp;
                  <strong>Política de Privacidade</strong>&nbsp;seja considerada
                  ilegal ou ilegítima por autoridade da localidade em que
                  o&nbsp;<strong>USUÁRIO</strong>&nbsp;resida, as demais
                  condições permanecerão em pleno vigor e produção de efeitos.
                </p>
                <p>
                  8.6. O&nbsp;<strong>USUÁRIO</strong> reconhece que toda
                  comunicação realizada qualquer forma eletrônica é válida como
                  prova documental, sendo eficaz e suficiente para a divulgação
                  de qualquer assunto a que se refira aos serviços prestados
                  pela <strong>G3MED</strong>, bem como às condições de sua
                  prestação ou a qualquer outro assunto nele abordado,
                  ressalvadas as disposições expressamente diversas previstas
                  nesta Política de Privacidade.
                </p>
                <h3>9. Lei Aplicável e Jurisdição</h3>
                <p>
                  9.1. O presente documento será regido e interpretado segundo a
                  legislação brasileira, no idioma português, sendo eleito o
                  foro do domicílio da Plataforma, no caso{" "}
                  <strong>Goiânia-Go</strong>&nbsp;para dirimir qualquer litígio
                  ou controvérsia envolvendo o presente documento, salvo
                  ressalva específica de competência pessoal, territorial ou
                  funcional pela legislação aplicável.
                </p>{" "}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Privacy;
