import { useNavigate } from "react-router-dom";

import { CustomTable, PaginationMeta } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { ListPatientItemTable } from "./patients";

export interface ListPatientsTableProps {
  patients: ListPatientItemTable[] | null;
  meta: PaginationMeta | null;
  onSelectPatient: (patientId: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const ListPatientsTable = ({
  patients,
  meta,
  onSelectPatient,
  onChangePage,
  tableType,
  setTableType,
}: ListPatientsTableProps) => {
  const navigate = useNavigate();

  const columnNames = {
    name: "Nome",
    phone: "Telefone",
    birthdate: "Data nascimento",
    action: "Ações",
  };

  return (
    <>
      <CustomTable
        columnNames={columnNames}
        data={patients}
        onSelect={onSelectPatient}
        tableType={tableType}
        setTableType={setTableType}
        btnLabelNotFound="+ Criar Paciente"
        btnClickNotFound={() => navigate("/painel/pacientes/criar")}
      />
      <CustomPagination
        {...Object.assign({}, meta)}
        onChangePage={onChangePage}
      />{" "}
    </>
  );
};
