import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { inputMaskFormatToReal } from "helpers/format-money";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface EvolutionRenevueDashboardProps {
  data: Record<string, number>;
}

export const EvolutionRenevueDashboard: React.FC<
  EvolutionRenevueDashboardProps
> = ({ data }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (data) {
            return "R$ " + data.formattedValue;
          },
        },
      },
      datalabels: {
        formatter: function (value: any) {
          // return inputMaskFormatToReal(String(value));
          return '';
        },
        color: "white",
        font: {
          weight: "bold" as const,
          size: 14,
          family: "poppins",
        },
      },
    },
  };
  // The following colors will be used sequentially for the chart bars
  const backgroundColors = ["#1632D2"];
  const chartData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "teste",
        data: Object.values(data),
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Bar data={chartData} options={options} />
    </>
  );
};
