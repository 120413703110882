import axios from "axios";
import config from "./config";
import { v4 as uuidv4 } from "uuid";

export const removeLocalAccessToken = () => {
  window.localStorage.removeItem("accessToken");
};
export const setLocalAccessToken = (token) => {
  window.localStorage.setItem("accessToken", token);
};
export const getLocalAccessToken = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  return accessToken;
};


export const getCorrelationId = () => {
  let correlationId = window.localStorage.getItem("correlationId");
  if (!correlationId) {
    correlationId = uuidv4();
    window.localStorage.setItem("correlationId", correlationId);
  }
  return correlationId;
};

export const httpClient = axios.create({
  baseURL: config().BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
    "x-correlation-id": getCorrelationId(),
    "x-api-key": config().API_KEY,
  },
});

httpClient.interceptors.request.use(
  function (config) {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.config.url !== "/auth/login" && error.response.status === 401) {
      window.localStorage.removeItem("correlationId");
      window.localStorage.removeItem("accessToken");
      return (window.location.href = "/auth/login");
    }
    return Promise.reject(error);
  }
);
