import React from "react";
import {
  ScheduleStatusColors,
  ScheduleStatusEnum,
  ScheduleStatusLabels,
} from "domain/schedule/enums/schedule-status.enum";
import { ScheduleEnumsIcons } from "../common/schedule-enums-view";

interface ListScheduleButtonDropDownProps {
  status: ScheduleStatusEnum;
  hasEditButton: boolean;
}

export const ListScheduleButtonDropDown: React.FC<
  ListScheduleButtonDropDownProps
> = ({ status, hasEditButton }: ListScheduleButtonDropDownProps) => {
  return (
    <>
      <div
        className={`dropdown-menu-button text-${ScheduleStatusColors[status]["class"]} font-weight-500`}
      >
        <span>
          <i className={ScheduleEnumsIcons[status]} aria-hidden="true" />
        </span>
        <span> </span>
        {ScheduleStatusLabels(status)}
        <span> </span>
        {hasEditButton && (
          <span>
            <i className="fa fa-edit" aria-hidden="true"></i>
          </span>
        )}
      </div>
    </>
  );
};
