import { useEffect, useMemo, useState } from "react";
import { actionGetMyData } from "actions/me/action-get-my-data";
import {
  ListAdminResponse,
  ListClinicManagerResponse,
  ListDoctorResponse,
  ListOwnerDoctorResponse,
  ListReceptionistResponse,
  ListSuperadminResponse,
} from "services/me-service";
import { FormMyDataClinicManager } from "./form/form-my-data-clinic-manager";
import { FormMyDataReceptionist } from "./form/form-my-data-receptionist";
import { FormMyDataOwnerDoctor } from "./form/form-my-data-owner-doctor";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import { actionUpdateMeData } from "actions/me/action-update-me-data";
import { FormMyDataSuperadmin } from "./form/form-my-data-superadmin";
import { FormMyDataDoctor } from "./form/form-my-data-doctor";
import { FormMyDataAdmin } from "./form/form-my-data-admin";
import { successMessage } from "helpers/toast";

export const FormMyData = () => {
  const [userRole, setUserRole] = useState<UserRoleEnum>();

  const [superAdminData, setSuperAdminData] =
    useState<ListSuperadminResponse>();
  const [adminData, setAdminData] = useState<ListAdminResponse>();
  const [clinicManagerData, setClinicManagerData] =
    useState<ListClinicManagerResponse>();
  const [doctorData, setDoctorData] = useState<ListDoctorResponse>();
  const [ownerDoctorData, setOwnerDoctorData] =
    useState<ListOwnerDoctorResponse>();
  const [receptionistData, setReceptionistData] =
    useState<ListReceptionistResponse>();

  const getMyData = async () => {
    try {
      const response = await actionGetMyData();
      response.document_number =
        response.document_number === null ? "" : response.document_number;

      if (response.role === UserRoleEnum.SUPERADMIN) {
        setSuperAdminData(response);
        setUserRole(UserRoleEnum.SUPERADMIN);
      } else if (response.role === UserRoleEnum.ADMIN) {
        setAdminData(response);
        setUserRole(UserRoleEnum.ADMIN);
      } else if (response.role === UserRoleEnum.CLINIC_MANAGER) {
        setClinicManagerData(response);
        setUserRole(UserRoleEnum.CLINIC_MANAGER);
      } else if (response.role === UserRoleEnum.RECEPTIONIST) {
        setReceptionistData(response);
        setUserRole(UserRoleEnum.RECEPTIONIST);
      } else if (response.role === UserRoleEnum.DOCTOR) {
        setDoctorData(response as ListDoctorResponse);
        setUserRole(UserRoleEnum.DOCTOR);
      } else if (response.role === UserRoleEnum.OWNER_DOCTOR) {
        setOwnerDoctorData(response as ListOwnerDoctorResponse);
        setUserRole(UserRoleEnum.OWNER_DOCTOR);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    (async () => {
      await getMyData();
    })();
  }, []);

  const onSubmit = async (input: any): Promise<void> => {
    try {
      await actionUpdateMeData(input);
      successMessage("Dados atualizados com sucesso");
    } catch {}
  };

  const isSuperadmin = useMemo(
    () => userRole === UserRoleEnum.SUPERADMIN && superAdminData !== undefined,
    [userRole, superAdminData]
  );
  const isAdmin = useMemo(
    () => userRole === UserRoleEnum.ADMIN && adminData !== undefined,
    [userRole, adminData]
  );
  const isClinicManager = useMemo(
    () =>
      userRole === UserRoleEnum.CLINIC_MANAGER &&
      clinicManagerData !== undefined,
    [userRole, clinicManagerData]
  );
  const isReceptionist = useMemo(
    () =>
      userRole === UserRoleEnum.RECEPTIONIST && receptionistData !== undefined,
    [userRole, receptionistData]
  );
  const isDoctor = useMemo(
    () => userRole === UserRoleEnum.DOCTOR && doctorData !== undefined,
    [userRole, doctorData]
  );
  const isOwnerDoctor = useMemo(
    () =>
      userRole === UserRoleEnum.OWNER_DOCTOR && ownerDoctorData !== undefined,
    [userRole, ownerDoctorData]
  );

  return (
    <>
      {isSuperadmin && (
        <FormMyDataSuperadmin
          data={superAdminData as ListSuperadminResponse}
          onSubmit={onSubmit}
        />
      )}
      {isAdmin && (
        <FormMyDataAdmin
          data={adminData as ListAdminResponse}
          onSubmit={onSubmit}
        />
      )}
      {isClinicManager && (
        <FormMyDataClinicManager
          data={clinicManagerData as ListClinicManagerResponse}
          onSubmit={onSubmit}
        />
      )}
      {isReceptionist && (
        <FormMyDataReceptionist
          data={receptionistData as ListReceptionistResponse}
          onSubmit={onSubmit}
        />
      )}
      {isSuperadmin && (
        <FormMyDataSuperadmin
          data={superAdminData as ListSuperadminResponse}
          onSubmit={onSubmit}
        />
      )}
      {isDoctor && (
        <FormMyDataDoctor
          data={doctorData as ListDoctorResponse}
          onSubmit={onSubmit}
        />
      )}
      {isOwnerDoctor && (
        <FormMyDataOwnerDoctor
          data={ownerDoctorData as ListOwnerDoctorResponse}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
