import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { enumIterator } from "helpers/typescript/enum-iterator";
import { customStyles } from "./helpers/react-select-styles";
import {
  PaymentTypeEnum,
  PaymentTypeLabels,
} from "domain/schedule/enums/payment-type.enum";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectPaymentTypeFormProps {
  isReadOnly?: boolean;
}
export const SelectPaymentTypeForm: React.FC<SelectPaymentTypeFormProps> = ({
  isReadOnly,
}: SelectPaymentTypeFormProps) => {
  const { setFieldValue, errors, values } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue("payment_type", undefined);
    setFieldValue("payment_type", selectedOption);
  };

  const optionsPaymentType = enumIterator(PaymentTypeEnum).map((key) => ({
    value: PaymentTypeEnum[key],
    label: PaymentTypeLabels(
      PaymentTypeEnum[key] as unknown as PaymentTypeEnum
    ),
  }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor="email">Método de pagamento</Label>
      <Field
        name="payment_type"
        id="payment_type"
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.payment_type}
        placeholder="Tipo de agendamento"
        options={optionsPaymentType}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
        styles={customStyles}
      />
      {(errors as any)?.payment_type && (
        <div className="custom-invalid-feedback">
          Método de pagamento inválido
        </div>
      )}
    </FormGroup>
  );
};
