import Select, { createFilter } from "react-select";
import { Field, useFormikContext } from "formik";
import { FormGroup, Label } from "reactstrap";

import { enumIterator } from "helpers/typescript/enum-iterator";
import { customStyles } from "./helpers/react-select-styles";
import {
  ScheduleOriginContactEnum,
  ScheduleOriginContactLabels,
} from "domain/schedule/enums/schedule-origin-contact.enum";

const filterConfig = {
  ignoreAccents: true,
  ignoreCase: true,
  matchFrom: "any",
  stringify: (option: any) => option.label,
  trim: true,
};
interface SelectScheduleOriginContactFormProps {
  isReadOnly?: boolean;
}
export const SelectScheduleOriginContactForm: React.FC<
  SelectScheduleOriginContactFormProps
> = ({ isReadOnly }: SelectScheduleOriginContactFormProps) => {
  const { setFieldValue, errors, values } = useFormikContext();
  const onChange = (selectedOption: any) => {
    if (!selectedOption) setFieldValue("origin_contact", undefined);
    setFieldValue("origin_contact", selectedOption);
  };

  const optionsScheduleOriginContact = enumIterator(
    ScheduleOriginContactEnum
  ).map((key) => ({
    value: ScheduleOriginContactEnum[key],
    label: ScheduleOriginContactLabels(
      ScheduleOriginContactEnum[key] as unknown as ScheduleOriginContactEnum
    ),
  }));

  return (
    <FormGroup className="col-12 px-0">
      <Label htmlFor="origin_contact">Origem do contato</Label>
      <Field
        name="origin_contact"
        id="origin_contact"
        isClearable
        isSearchable
        onChange={onChange}
        defaultValue={(values as any)?.origin_contact}
        placeholder="Origem do contato"
        options={optionsScheduleOriginContact}
        filterOption={createFilter(filterConfig as any)}
        component={Select}
        isDisabled={isReadOnly}
        styles={customStyles}
      />
      {/* {(errors as any)?.type && (
        <div className="custom-invalid-feedback">
          Tipo de agendamento inválido
        </div>
      )} */}
    </FormGroup>
  );
};
