export enum UserRoleEnum {
  SUPERADMIN = 0,
  CLINIC_MANAGER = 1,
  ADMIN = 2,
  DOCTOR = 3,
  OWNER_DOCTOR = 4,
  PATIENT = 5,
  RECEPTIONIST = 6,
  RAW_USER = 7,
}

export const UserRoleEnumLabels = (role: UserRoleEnum) => {
  const labels = {
    [UserRoleEnum.SUPERADMIN]: 'Super Admin',
    [UserRoleEnum.CLINIC_MANAGER]: 'Gestor da clínica',
    [UserRoleEnum.ADMIN]: 'Admin',
    [UserRoleEnum.DOCTOR]: 'Médico',
    [UserRoleEnum.OWNER_DOCTOR]: 'Médico',
    [UserRoleEnum.PATIENT]: 'Paciente',
    [UserRoleEnum.RECEPTIONIST]: 'Recepcionista',
    [UserRoleEnum.RAW_USER]: 'Usuário',
  };

  return labels[role];
};
