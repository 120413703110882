import { CustomTable } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { ListReceptionistsResponse } from "services/user-service";
import { useNavigate } from "react-router-dom";

export interface ListReceptionistsTableProps {
  receptionists: ListReceptionistsResponse | null;
  onSelectReceptionist: (receptionistId: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const ListReceptionistsTable = ({
  receptionists,
  onSelectReceptionist,
  onChangePage,
  tableType,
  setTableType,
}: ListReceptionistsTableProps) => {
  const navigate = useNavigate();
  const columnNames = {
    document_number: "CPF",
    name: "Nome",
    created_at: "Criado em",
  };

  return (
    <>
      {receptionists !== null ? (
        <>
          <CustomTable
            columnNames={columnNames}
            data={receptionists.items}
            onSelect={onSelectReceptionist}
            tableType={tableType}
            setTableType={setTableType}
            btnLabelNotFound="+ Criar Recepcionista"
            btnClickNotFound={() => navigate("/painel/recepcionista/criar")}
            
          />
          <CustomPagination
            {...Object.assign({}, receptionists.meta)}
            onChangePage={onChangePage}
          />{" "}
        </>
      ) : (
        <> </>
      )}
    </>
  );
};
