import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import { Button, FormGroup, Label, Row } from "reactstrap";
import { CustomMaskInput } from "components/form/input/custom-mask-input";

export const MedicalCareTab = () => {
  const weekdays = [
    "Domingo",
    "Segunda",
    "Terça",
    "Quarta",
    "Quinta",
    "Sexta",
    "Sábado",
  ];

  const validationSchema = Yup.object().shape({});
  const initialValues = {
    tenant_name: "",
    start: "07:00",
    end: "18:00",
    lunch_start: "12:00",
    lunch_end: "14:00",
  };
  const onSubmit = (input: any) => {
    console.log(input);
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div>
              <span className="h4-g3">HORÁRIO ATENDIMENTO</span>
              <Row className="mt-2">
                <FormGroup className="col-12">
                  <h6>Dias de atendimento</h6>
                  <div className="d-flex col-12 flex-wrap">
                    {weekdays.map((day) => (
                      <div key={day} className="col-12 col-sm-auto my-2">
                        <div className="form-check mx-sm-2 d-sm-flex">
                          <Field
                            type="checkbox"
                            name="weekdays"
                            value={day}
                            className="form-check-input"
                            id={day}
                          />
                          <label
                            className="form-check-label mx-1"
                            htmlFor={day}
                          >
                            {day}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </FormGroup>

                <div className="col-12 d-flex flex-wrap">
                  <div className="col-12 col-md-6 d-flex flex-wrap">
                    <h6 className="col-12">Horário de atendimento</h6>
                    <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                      <Label htmlFor="start">Início</Label>
                      <Field
                        name="start"
                        id="start"
                        placeholder="Horário de início"
                        type="text"
                        mask="00:00"
                        component={CustomMaskInput}
                      />
                    </FormGroup>

                    <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                      <Label htmlFor="end">Fim</Label>
                      <Field
                        name="end"
                        id="end"
                        placeholder="Horário de fim"
                        type="text"
                        mask="00:00"
                        component={CustomMaskInput}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-wrap">
                    <h6 className="col-12">Horário de atendimento</h6>
                    <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                      <Label htmlFor="lunch_start">Início</Label>
                      <Field
                        name="lunch_start"
                        id="lunch_start"
                        placeholder="Horário de início"
                        type="text"
                        mask="00:00"
                        component={CustomMaskInput}
                      />
                    </FormGroup>

                    <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                      <Label htmlFor="lunch_end">Fim</Label>
                      <Field
                        name="lunch_end"
                        id="lunch_end"
                        placeholder="Horário de fim"
                        type="text"
                        mask="00:00"
                        component={CustomMaskInput}
                      />
                    </FormGroup>
                  </div>
                </div>
              </Row>
            </div>

            {/* <hr />
            <span className="h4-g3">PREÇOS DOS ATENDIMENTOS</span>
            <div className="col-12 d-flex flex-wrap">
              <div className="d-flex col-12 col-md-6 flex-wrap">
                <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                  <Label htmlFor="lunch_start">Consulta (R$)</Label>
                  <Field
                    name="lunch_start"
                    id="lunch_start"
                    placeholder="Horário de início"
                    type="text"
                    mask="00:00"
                    component={CustomMaskInput}
                  />
                </FormGroup>

                <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                  <Label htmlFor="lunch_end">Retorno (R$)</Label>
                  <Field
                    name="lunch_end"
                    id="lunch_end"
                    placeholder="Horário de fim"
                    type="text"
                    mask="00:00"
                    component={CustomMaskInput}
                  />
                </FormGroup>
              </div>

              <div className="d-flex col-12 col-md-6 flex-wrap">
                <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                  <Label htmlFor="lunch_start">Procedimento (R$)</Label>
                  <Field
                    name="lunch_start"
                    id="lunch_start"
                    placeholder="Horário de início"
                    type="text"
                    mask="00:00"
                    component={CustomMaskInput}
                  />
                </FormGroup>
                <FormGroup className="col-12 col-md-6 px-0 pe-md-2">
                  <Label htmlFor="lunch_end">Exame (R$)</Label>
                  <Field
                    name="lunch_end"
                    id="lunch_end"
                    placeholder="Horário de fim"
                    type="text"
                    mask="00:00"
                    component={CustomMaskInput}
                  />
                </FormGroup>
              </div>
            </div> */}

            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <div className="col-12 col-md-3">
                <Button type="submit" color="primary" className="form-control">
                  Salvar
                </Button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
