import "react-big-calendar/lib/css/react-big-calendar.css";

import {
  Calendar,
  SlotInfo,
  Views,
  dateFnsLocalizer,
} from "react-big-calendar";
import { Form, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import startOfWeek from "date-fns/startOfWeek";
import { useEffect, useState } from "react";
import ptBR from "date-fns/locale/pt-BR";
import getDay from "date-fns/getDay";
import format from "date-fns/format";
import parse from "date-fns/parse";

import { CustomCalendarEvent } from "views/painel/schedule/index/custom-calendar-event";
import { actionListAllSchedule } from "actions/schedule/action-list-all-schedule";
import { ListSchedule } from "views/painel/schedule/list-schedule/list-schedule";
import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";
import { actionUpdateSchedule } from "actions/schedule/action-update-schedule";
import { actionMakeSchedule } from "actions/schedule/action-make-schedule";
import {
  MakeAppointmentPaymentProps,
  MakeScheduleProps,
} from "services/schedule-service";
import { errorMessage } from "helpers/toast";
import {
  MakeScheduleContainer,
  MakeScheduleDataProps,
} from "views/painel/schedule/common/make-schedule-container";
import {
  ScheduleTypeBackgroundColors,
  ScheduleTypeColors,
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import {
  dateFormatter,
  getStartAndEndOfWeek,
  utcToSaoPaulo,
} from "helpers/date-formatter";
import { ScheduleOriginContactEnum } from "domain/schedule/enums/schedule-origin-contact.enum";
import { CalendarCustomToolbar } from "./calendar-custom-toolbar";
import {
  ScheduleSelectDoctor,
  ScheduleSelectDoctorProps,
} from "./schedule-select-doctor";
import { CustomInputSearchClearable } from "components/form/input/custom-input-search-clearable";
import UserIdentity from "domain/user/entities/user-identity";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import { labelMaskFormatToReal } from "helpers/format-money";
import { PaymentTypeEnum } from "domain/schedule/enums/payment-type.enum";

import "./schedule.style.css";

const locales = {
  "pt-BR": ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface IntervalDate {
  start: Date;
  end: Date;
}

export interface ScheduleDataProps {
  id: number;
  title: string;
  doctor: {
    id: number;
    name: string;
  };
  patient: {
    id: number;
    name: string;
    phone: string;
  };
  start: Date;
  end: Date;
  status: ScheduleStatusEnum;
  type: ScheduleTypeEnum;
  origin_contact: ScheduleOriginContactEnum | null;
  observation: string;
  amount: string;
  payment_type?: PaymentTypeEnum;
  amount_paid?: string;
  paid_at?: string;
}

const eventStyleGetter = (
  event: any,
  _start: any,
  _end: any,
  _isSelected: boolean
) => {
  var color = ScheduleTypeColors[event.type as ScheduleTypeEnum]["hex"];
  var backgroundColor =
    ScheduleTypeBackgroundColors[event.type as ScheduleTypeEnum]["hex"];
  var style = {
    color: color,
    border: `1px solid ${color}`,
    backgroundColor: backgroundColor,
    borderRadius: "7px",
    fontSize: "13px",
    fontWeight: 500,
  };
  return {
    style: style,
  };
};

const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

const Schedule: React.FC = () => {
  const [modalCreate, setModalCreate] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalEventSelected, setModalEventSelected] = useState(false);
  const [intervalDate, setIntervalDate] = useState<IntervalDate>();
  const [slots, setSlots] = useState<ScheduleDataProps[]>([]);
  const [eventSelected, setEventSelected] = useState<ScheduleDataProps>();
  const [appointmentData, setAppointmentData] = useState<MakeScheduleDataProps>(
    {} as {} as MakeScheduleDataProps
  );
  const [selectedDoctor, setSelectedDoctor] =
    useState<ScheduleSelectDoctorProps | null>();

  const [isSubmitSearchPatient, setIsSubmitSearchPatient] = useState(false);
  const [inputSearchPatient, setInputSearchPatient] = useState("");

  const toggleCreate = () => setModalCreate(!modalCreate);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const toggleEventSelected = () => setModalEventSelected(!modalEventSelected);

  const getAppointments = async (
    start: string,
    end: string,
    doctor_id?: string,
    patient_name?: string
  ) => {
    try {
      const response = await actionListAllSchedule({
        start,
        end,
        limit: 150,
        page: 1,
        ...(doctor_id && { doctor_id }),
        ...(patient_name && { patient_name }),
      });

      const mapped = response.items.map((item) => ({
        ...item,
        start: new Date(utcToSaoPaulo(item.start)),
        end: new Date(utcToSaoPaulo(item.end)),
        title: `${ScheduleTypeLabels(item.type)} - ${item.patient.name}`,
        amount: labelMaskFormatToReal(item.amount ? String(item.amount) : "0"),
        amount_paid: labelMaskFormatToReal(
          item.amount_paid ? String(item.amount_paid) : "0"
        ),
        payment_type: item.payment_type,
      }));

      setSlots(mapped);
    } catch {}
  };
  useEffect(() => {
    (async () => {
      if (!intervalDate && !selectedDoctor) return;
      await getAppointments(
        dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
        dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
        selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
        isSubmitSearchPatient ? inputSearchPatient : undefined
      );
    })();
  }, [intervalDate, selectedDoctor, isSubmitSearchPatient]);

  useEffect(() => {
    const result = getStartAndEndOfWeek(new Date().toDateString());
    setIntervalDate({
      start: result.startOfWeek,
      end: result.endOfWeek,
    });
  }, []);

  const onCreateEvent = (slot: SlotInfo) => {
    toggleCreate();
    setAppointmentData({
      type: ScheduleTypeEnum.APPOINTMENT,
      start: slot.start,
      end: slot.end,
      observation: "",
      origin_contact: null,
      doctor: {
        id: undefined,
        name: "Selecione um médico",
      },
      patient: {
        id: undefined,
        name: "Selecione um paciente",
        phone: "",
      },
      amount: "R$ 0,00",
    });
  };

  const onSelectEvent = (event: any) => {
    setAppointmentData({
      id: event.id,
      type: event.type,
      start: event.start,
      end: event.end,
      origin_contact: event.origin_contact,
      observation: event.observation,
      doctor: event.doctor,
      patient: event.patient,
      amount: event.amount,
    });
    setModalEventSelected(true);
    setEventSelected(event);
  };
  const onRangeChange = (range: any) => {
    const start = new Date(range[0]);
    const end = new Date(range[range.length - 1]);
    setIntervalDate({
      start,
      end,
    });
  };

  const onCreateEventSubmit = async (input: MakeScheduleProps) => {
    await actionMakeSchedule(input);
    onNextSubmit();
  };

  const onEditEventSubmit = async (input: MakeScheduleProps) => {
    if (appointmentData.id) {
      await actionUpdateSchedule(appointmentData.id.toString(), input);
      onNextSubmit();
      return;
    }
    errorMessage("Agendamento não encontrado");
  };

  const onUpdateStatus = async (status: ScheduleStatusEnum) => {
    setEventSelected((prev) => ({ ...prev, status } as ScheduleDataProps));

    if (!intervalDate) return;
    await getAppointments(
      dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
      dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
      selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
      isSubmitSearchPatient ? inputSearchPatient : undefined
    );
  };

  const onMakePayment = async (input: MakeAppointmentPaymentProps) => {
    const { amount_paid, payment_type, paid_at } = input;
    setEventSelected(
      (prev) =>
        ({
          ...prev,
          amount_paid: labelMaskFormatToReal(String(amount_paid)),
          payment_type,
          paid_at: new Date(`${paid_at}T03:00:00Z`).toISOString(), //Becausae timezone GMT(-3)
        } as ScheduleDataProps)
    );
    if (!intervalDate) return;
    await getAppointments(
      dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
      dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
      selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
      isSubmitSearchPatient ? inputSearchPatient : undefined
    );
  };
  const onNextSubmit = () => {
    setModalCreate(false);
    setModalEventSelected(false);
    setModalEdit(false);
    getAppointments(
      dateFormatter(intervalDate?.start, "yyyy-MM-dd"),
      dateFormatter(intervalDate?.end, "yyyy-MM-dd"),
      selectedDoctor?.value ? String(selectedDoctor?.value) : undefined,
      isSubmitSearchPatient ? inputSearchPatient : undefined
    );
  };

  const onSearchPatient = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitSearchPatient(true);
  };

  const onClearInput = () => {
    if (!inputSearchPatient || !isSubmitSearchPatient) return;
    setIsSubmitSearchPatient(false);
    setInputSearchPatient("");
  };

  const isDoctor = (): Boolean => UserIdentity.hasRole([UserRoleEnum.DOCTOR]);

  return (
    <div className="main-wrapper">
      <span className="h4-g3">Filtros de pesquisa</span>

      <div className="col-12 d-flex flex-wrap mb-3">
        {!isDoctor() && (
          <div className="col-12 col-md-6 px-2">
            <ScheduleSelectDoctor
              selectedDoctor={selectedDoctor}
              setSelectedDoctor={setSelectedDoctor}
            />
          </div>
        )}

        <div className="col-12 col-md-6 px-2">
          <Form onSubmit={onSearchPatient}>
            <Label>Deseja buscar um paciente ?</Label>
            <CustomInputSearchClearable
              placeholder="Pesquisar paciente por nome"
              inputSearch={inputSearchPatient}
              setInputSearch={setInputSearchPatient}
              onClearInput={onClearInput}
            />
          </Form>
        </div>
      </div>

      <Calendar
        culture="pt-BR"
        localizer={localizer}
        events={slots}
        startAccessor="start"
        // min={new Date(0, 0, 0, 7, 0, 0)}
        // max={new Date(0, 0, 0, 22, 0, 0)}
        step={15}
        timeslots={1}
        endAccessor="end"
        views={[Views.WEEK, Views.DAY]}
        popup={true}
        selectable={true}
        style={{ minHeight: "100vh" }}
        defaultView="week"
        onRangeChange={onRangeChange}
        messages={{
          next: "Próximo",
          previous: "Anterior",
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
        }}
        onSelectSlot={onCreateEvent}
        onSelectEvent={onSelectEvent}
        eventPropGetter={eventStyleGetter}
        components={{
          event: CustomCalendarEvent,
          toolbar: (props) => <CalendarCustomToolbar {...props} />,
        }}
        formats={formats}
      />

      <Modal size="lg" isOpen={modalCreate} toggle={toggleCreate}>
        <ModalHeader toggle={toggleCreate}>Realizar agendamento</ModalHeader>
        <ModalBody>
          <MakeScheduleContainer
            onCancel={toggleCreate}
            onSubmit={onCreateEventSubmit}
            appointmentData={appointmentData}
          />
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar agendamento</ModalHeader>
        <ModalBody>
          <MakeScheduleContainer
            onCancel={toggleEdit}
            onSubmit={onEditEventSubmit}
            appointmentData={appointmentData}
          />
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalEventSelected} toggle={toggleEventSelected}>
        <ModalHeader toggle={toggleEventSelected}>
          Detalhes do agendamento
        </ModalHeader>
        <ModalBody>
          {eventSelected && (
            <ListSchedule
              data={{ ...eventSelected }}
              onEdit={() => {
                setModalCreate(false);
                setModalEventSelected(false);
                setModalEdit(true);
              }}
              onCancel={() => {
                setModalCreate(false);
                setModalEventSelected(false);
                setModalEdit(false);
              }}
              updateStatus={onUpdateStatus}
              makePayment={onMakePayment}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Schedule;
