import { action } from "mobx";
import {
  ForgotPasswordResponse,
  IdentityService,
} from "services/identity-service";
import { SetIsLoadingState } from "state/spinner/actions/set-is-loading";

export const actionForgotPassword = action(
  async (login: string): Promise<ForgotPasswordResponse> => {
    SetIsLoadingState(true);
    try {
      return await IdentityService.forgotPassword(login);
    } catch (error) {
      throw error;
    } finally {
      SetIsLoadingState(false);
    }
  }
);
