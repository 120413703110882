import { useEffect, useState } from "react";
import { IdentityService, ListTenant } from "services/identity-service";
import { SelectTenantTable } from "./select-tenant-table";
import { actionSwitchSession } from "actions/identity/action-switch-session";
import { Col } from "reactstrap";

const SelectTenant: React.FC = () => {
  const [tenants, setTenants] = useState<ListTenant[]>([]);

  const fetchTenants = async () => {
    const result = await IdentityService.fetchTenants();
    setTenants(result);
  };

  useEffect(() => {
    fetchTenants();
  }, []);

  const onSelectTenant = async (tenantId: string) => {
    await actionSwitchSession({ to: tenantId });
    window.location.href = "/painel";
  };

  return (
    <>
      <Col
        sm="12"
        md="8"
        className="row d-flex rounded-1 p-3 p-md-5 bg-white justify-content-center"
      >
        <h3 className="text-center text-primary">Olá,</h3>
        <h3 className="text-center text-primary">Qual clínica deseja atender?</h3>

        {tenants && (
          <SelectTenantTable
            tenants={tenants}
            onSelectTenant={onSelectTenant}
          />
        )}
      </Col>
    </>
  );
};

export default SelectTenant;
